import { TeamMember } from "../../types/mkt/TeamMember";

export const emptyTeamMember: TeamMember = {
  id: "",
  order: 0,
  image: "",
  firstName: "",
  lastName: "",
  text: "",
  publish: false,
};
