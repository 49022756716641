import { Box, Grid } from "@mui/material";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Service } from "../../../../../types/mkt/Service";
import { ServiceCard } from "../ServiceCard/ServiceCard";

import "./ServiceGrid.css";

interface ServicesGridProps {
  services: Service[];
  editAction: any;
  publishAction: any;
  deleteAction: any;
}

export const ServiceGrid: FC<ServicesGridProps> = ({
  services,
  editAction,
  publishAction,
  deleteAction,
}) => {
  return (
    <Box className="ServiceGrid-container">
      {services.map((service, index) => (
        <div className={`ServiceGrid-link`} key={index}>
          <ServiceCard
            service={service}
            editAction={editAction}
            publishAction={publishAction}
            deleteAction={deleteAction}
          />
        </div>
      ))}
    </Box>
  );
};
