import * as yup from "yup";

export const testimonialValidationSchema = yup.object({
  image: yup.string().required("Image is required"),
  text: yup.string().required("Text is required"),
  name: yup.string().required("Name is required").max(30, "Name must be at most 30 characters"),
  position: yup
    .string()
    .required("Position is required")
    .max(100, "Role must be at most 100 characters"),
});
