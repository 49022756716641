import React, { FC, useEffect, useRef, useState } from "react";
import { PageCreate } from "../../../components/internal/Global/Pages/PageCreate";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../stores/store";
import { useFormik } from "formik";
import {
  createCurriculumVitae,
  getCurriculumVitae,
  resetState,
  setEdited,
  setError,
} from "../../../stores/internal/CV.store";
import { cvValidationSchema } from "../../../utils/internal/cv.utils";
import { CVComponent } from "../../../components/internal/CV/CV";
import { Loading } from "../../../components/Loading/loading";
import "./CVPage.css";
import { CV } from "../../../types/internal/cv/CV";

export const CVPage: FC = () => {
  const dispatch = useTypedDispatch();
  const { email, token } = useTypedSelector((state: RootState) => state.auth);
  const { edited, error, loading, curriculumVitae } = useTypedSelector(
    (state: RootState) => state.CV
  );
  const [reload, setReload] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);

  const switchTabAction = (index: number) => {
    setActiveTab(index);
  };

  useEffect(() => {
    if (!reload && token) {
      dispatch(resetState());
      dispatch(getCurriculumVitae({ userEmail: email }));
    }
    setReload(true);
  }, []);

  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const defineMessageTimeDuration = (header: string, value: boolean) => {
    if (value) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(
        () => dispatch(header === "edited" ? setEdited(false) : setError(false)),
        2000
      );
    }
  };

  useEffect(() => {
    defineMessageTimeDuration("edited", edited);
  }, [edited]);

  useEffect(() => {
    defineMessageTimeDuration("error", error);
  }, [error]);

  const formik = useFormik({
    initialValues: curriculumVitae,
    onSubmit: () => {},
    validationSchema: cvValidationSchema,
  });

  const createAction = async () => {
    const cvRequestBody: CV = prepareRequestBody();
    formik.errors = await formik.validateForm(cvRequestBody);
    if (Object.keys(formik.errors).length > 0 || !token) {
      return;
    }
    dispatch(createCurriculumVitae({ cv: cvRequestBody, userEmail: email }));
  };

  const prepareRequestBody = () => {
    var cv: CV = JSON.parse(JSON.stringify(formik.values)) as typeof formik.values;
    cv.experience.map((experience, index) => {
      if (cv.experience[index].endDate == null) {
        cv.experience[index].endDate = undefined;
      }
    });
    return cv;
  };

  return (
    <PageCreate
      pageContent={{
        pageTitle: "Edit CV",
        previousPage: `/`,
        action: createAction,
        type: "Edit",
        tabs: ["Personal Info", "Tech Stack", "Experience", "Education and Certs", "Languages"],
        switchTabAction,
      }}
      sidebar={null}
    >
      <>
        <div style={{ position: "relative" }}>
          <div className="CVPage-main">
            {loading ? <Loading /> : <CVComponent activeTab={activeTab} formik={formik} />}
          </div>
          <div className="CV-message-container">
            {edited && <div className="CV-message-success"> Success </div>}
            {error && <div className="CV-message-fail"> Failed </div>}
          </div>
        </div>
      </>
    </PageCreate>
  );
};
