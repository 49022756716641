import { IconButton, Tooltip } from "@mui/material";
import React, { FC } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

interface InputProps {
  onCancelButtonTouch: () => void;
  onConfirmButtonTouch: () => void;
}

export const ConfirmAndCancelButtons: FC<InputProps> = ({
  onCancelButtonTouch,
  onConfirmButtonTouch,
}) => {
  return (
    <div style={{ display: "flex" }}>
      <Tooltip title="Cancel" color="error">
        <IconButton onClick={onCancelButtonTouch}>
          <CancelIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Confirm" sx={{ marginLeft: "1em", color: "#00b088" }}>
        <IconButton onClick={onConfirmButtonTouch}>
          <CheckCircleIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};
