import React, { FC, useEffect, useState } from "react";
import { HtmlInput } from "../../../../Inputs/HtmlInput";
import { UploadImage } from "../../../../ImageInputs/UploadImage/UploadImage";

import "./CaseStudyCreateEditSolution.css";
import { ImageInputSquare } from "../../../../ImageInputs/ImageInputSquare/ImageInputSquare";

interface CaseStudyCreateEditSolutionProps {
  formik: any;
}

export const CaseStudyCreateEditSolution: FC<CaseStudyCreateEditSolutionProps> = ({ formik }) => {
  return (
    <div className="CaseStudyCreateEditSolution-container">
      <div className="CaseStudyCreateEditSummary-container_leftSide">
        <ImageInputSquare
          title="Image"
          formik={formik}
          buttonTitle="Upload image"
          image={formik.values.solution.stack}
          error={formik.errors ? (formik.errors.solution ? formik.errors.solution.stack : "") : ""}
          target="solution.stack"
          folder="caseStudies"
          height="14em"
          width="25em"
          acceptType={null}
        />
      </div>

      <div className="CaseStudyCreateEditSolution-description">
        <HtmlInput
          error={formik.errors ? (formik.errors.solution ? formik.errors.solution.text : "") : ""}
          value={formik.values.solution.text}
          handleChange={formik.handleChange}
          label="Description"
          name="solution.text"
        />
      </div>
    </div>
  );
};
