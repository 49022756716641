import React, { FC } from "react";
import { Link } from "react-router-dom";
import { CaseStudy } from "../../../../../types/mkt/CaseStudy";
import { InfoCard } from "../../../Global/Cards/InfoCard/InfoCard";

interface CaseStudyCardProps {
  caseStudy: CaseStudy;
}

export const CaseStudyCard: FC<CaseStudyCardProps> = ({ caseStudy }) => {
  return (
    <InfoCard
      id={caseStudy.id}
      image={caseStudy.thumbnail}
      title={caseStudy.title}
      publish={caseStudy.publish}
      author={null}
      editAction={null}
      publishAction={null}
      deleteAction={null}
      duplicateAction={null}
      toCropImage={true}
    />
  );
};
