import { HTTP_CODES } from "../../constants/httpstatus.const";
import { emptyTeamMember } from "../../data/mkt/teamMember";
import { TeamMember } from "../../types/mkt/TeamMember";
import { apiClient, getAuthConfig } from "../HttpClient";

const TEAM_MEMBER_URL = "/teamMember";
const TEAM_MEMBER_BY_ID = (id: string) => TEAM_MEMBER_URL + "/" + id;
const TEAM_MEMBER_PUBLISH = (id: string) => `${TEAM_MEMBER_URL}/${id}/publish`;
const TEAM_MEMBER_UNPUBLISH = (id: string) => `${TEAM_MEMBER_URL}/${id}/unpublish`;

export default class TeamMemberService {
  static getTeamMembers = async () => {
    try {
      const response = await apiClient.get(TEAM_MEMBER_URL, getAuthConfig());
      const teamMembers: TeamMember[] = response.data;
      return {
        teamMembers,
        code: response.status,
      };
    } catch (err: any) {
      return {
        teamMembers: [],
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static getTeamMember = async (id: string) => {
    try {
      const response = await apiClient.get(TEAM_MEMBER_BY_ID(id), getAuthConfig());
      const teamMember: TeamMember = response.data;
      return {
        teamMember,
        code: response.status,
      };
    } catch (err: any) {
      return {
        teamMember: emptyTeamMember,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static createTeamMember = async (teamMember: TeamMember) => {
    try {
      const response = await apiClient.post(TEAM_MEMBER_URL, teamMember, getAuthConfig());
      return {
        teamMember: response.data as TeamMember,
        code: response.status,
      };
    } catch (err: any) {
      return {
        teamMember: emptyTeamMember,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static editTeamMember = async (teamMember: TeamMember) => {
    try {
      const response = await apiClient.put(TEAM_MEMBER_URL, teamMember, getAuthConfig());
      return {
        teamMember: response.data as TeamMember,
        code: response.status,
      };
    } catch (err: any) {
      return {
        teamMember: emptyTeamMember,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static deleteTeamMember = async (id: string) => {
    try {
      const response = await apiClient.delete(TEAM_MEMBER_BY_ID(id), getAuthConfig());
      return {
        code: response.status,
      };
    } catch (err: any) {
      return {
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static publishTeamMember = async (id: string) => {
    try {
      const response = await apiClient.put(TEAM_MEMBER_PUBLISH(id), {}, getAuthConfig());
      return {
        teamMember: response.data as TeamMember,
        code: response.status,
      };
    } catch (err: any) {
      return {
        teamMember: emptyTeamMember,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static unpublishTeamMember = async (id: string) => {
    try {
      const response = await apiClient.put(TEAM_MEMBER_UNPUBLISH(id), {}, getAuthConfig());
      return {
        teamMember: response.data as TeamMember,
        code: response.status,
      };
    } catch (err: any) {
      return {
        teamMember: emptyTeamMember,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };
}
