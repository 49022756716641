import React, { FC } from "react";
import { Input } from "../../../../Inputs/Input";

interface ServiceTitleCreateProps {
  formik: any;
}

export const ServiceCreateEditTitle: FC<ServiceTitleCreateProps> = ({ formik }) => {
  return (
    <Input
      error={formik.errors.title}
      value={formik.values.title}
      handleChange={formik.handleChange}
      label="Title"
      name="title"
      type="text"
      width="95%"
      height="2rem"
    />
  );
};
