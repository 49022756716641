import React, { FC, useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import "./Button.css";

export enum ButtonTypes {
  Primary,
  Secondary,
  Cancel,
}

interface ButtonProps {
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  type: ButtonTypes;
  disabled?: boolean;
}

const getButtonColorClass = (type: ButtonTypes, disabled?: boolean) => {
  let colorClass = "";
  if (disabled != undefined && disabled) {
    colorClass = "buttonDisabled";
    return colorClass;
  }
  switch (type) {
    case ButtonTypes.Primary:
      colorClass = "buttonPrimary";
      break;
    case ButtonTypes.Secondary:
      colorClass = "buttonSecondary";
      break;
    case ButtonTypes.Cancel:
      colorClass = "buttonCancel";
      break;
    default:
      colorClass = "buttonPrimary";
  }
  return colorClass;
};

export const Button: FC<ButtonProps> = ({ text, onClick, type, disabled }) => {
  const [colorClass, setColorClass] = useState("");

  useEffect(() => {
    setColorClass(getButtonColorClass(type, disabled));
  }, []);

  return (
    <button className={`button ${colorClass}`} onClick={onClick}>
      {text}
    </button>
  );
};

export const AddButton: FC<ButtonProps> = ({ text, onClick, type, disabled }) => {
  const [colorClass, setColorClass] = useState("");

  useEffect(() => {
    setColorClass(getButtonColorClass(type, disabled));
  }, []);

  return (
    <button className={`addButton ${colorClass}`} onClick={onClick}>
      <AddCircleOutlineIcon sx={{ marginRight: "4px", width: "20px" }} /> {text}
    </button>
  );
};
