import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApproverState } from "../../types/hr/ApproverState";
import ApproverService from "../../services/hr/Approver.service";

const approverSlice = createSlice({
  name: "approver",
  initialState: {
    loading: false,
    error: null,
    approvers: [],
    created: false,
    edited: false,
    deleted: false,
  } as ApproverState,
  reducers: {
    setApprover(state, action) {
      state.approvers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getApprovers.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.approvers = [];
      }
    });
    builder.addCase(getApprovers.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.approvers = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getApprovers.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

export const getApprovers = createAsyncThunk(
  "employee/getApprovers",
  async (approver: { email: string }) => {
    const response = await ApproverService.getApprovers(approver.email);
    return response.approvers;
  }
);

export default approverSlice.reducer;
