import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { emptyWBS } from "../../data/common/wbs";
import WBSService from "../../services/common/WBS.service";
import { WBSState } from "../../types/common/wbs/WBSState";

const wbsSlice = createSlice({
  name: "wbs",
  initialState: {
    loading: false,
    error: null,
    wbsAll: [],
    wbs: emptyWBS,
    created: false,
    edited: false,
    deleted: false,
  } as WBSState,
  reducers: {
    setWBS(state, action) {
      state.wbs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllWBS.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getAllWBS.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.wbsAll = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getAllWBS.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

export const getAllWBS = createAsyncThunk("wbs", async () => {
  const response = await WBSService.getAllWBS();
  return response.wbs;
});

export default wbsSlice.reducer;
export const { setWBS } = wbsSlice.actions;
