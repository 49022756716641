import React, { FC, useEffect, useState } from "react";
import { Input } from "../../../Inputs/Input";
import { ImageInputSquare } from "../../../ImageInputs/ImageInputSquare/ImageInputSquare";
import { HtmlInput } from "../../../Inputs/HtmlInput";

import "./TeamMemberCreateEdit.css";

interface TeamMemberCreateEditProps {
  formik: any;
}

export const TeamMemberCreateEdit: FC<TeamMemberCreateEditProps> = ({ formik }) => {
  return (
    <div className="TeamMemberCreateEdit-container">
      <div className="TeamMemberCreateEdit-image">
        <ImageInputSquare
          title="Image"
          formik={formik}
          buttonTitle="Upload image"
          image={formik.values.image}
          error={formik.errors.image}
          target="image"
          folder="team"
          height="22em"
          width="15em"
          acceptType={null}
        />
      </div>
      <div className="TeamMemberCreateEdit-containerRightSide">
        <div className="TeamMemberCreateEdit-row">
          <Input
            error={formik.errors.firstName}
            value={formik.values.firstName}
            handleChange={formik.handleChange}
            label="First Name"
            name="firstName"
            type="text"
            width="100%"
            height="2rem"
          />
          <Input
            error={formik.errors.lastName}
            value={formik.values.lastName}
            handleChange={formik.handleChange}
            label="Last Name"
            name="lastName"
            type="text"
            width="100%"
            height="2rem"
          />
        </div>
        <div className="TestimonialCreateEdit-description">
          <Input
            error={formik.errors ? (formik.errors.text ? formik.errors.text : "") : ""}
            value={formik.values.text}
            handleChange={formik.handleChange}
            label="Description"
            name="text"
            type="text"
            multiline
            maxRows={7}
            width="100%"
            height="11em"
          />
        </div>
      </div>
    </div>
  );
};
