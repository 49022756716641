import { Auth } from "../../types/mkt/Auth";
import { emptyUser } from "../../data/internal/user";
import { User } from "../../types/internal/User";
import { apiClient, buildConfig, getAuthConfig } from "../HttpClient";
import { HTTP_CODES } from "../../constants/httpstatus.const";

const USER_URL = "/employee";
const USER_BY_EMAIL = (email: string) => USER_URL + "/" + email;

export default class UserService {
  static getUser = async (email: string) => {
    try {
      const response = await apiClient.get(USER_BY_EMAIL(email), getAuthConfig());
      const user: User = response.data;
      return {
        user,
        code: response.status,
      };
    } catch (err: any) {
      return {
        user: emptyUser,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static patchUser = async (user: User) => {
    try {
      const response = await apiClient.patch(USER_URL, user, getAuthConfig());
      const newUser: User = response.data;
      return {
        user: newUser,
        code: response.status,
      };
    } catch (err: any) {
      return {
        user: emptyUser,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static getEmployeeDetails = async (email: string) => {
    try {
      const response = await apiClient.get(USER_BY_EMAIL(email), getAuthConfig());
      const user: User = response.data;
      return {
        user,
        code: response.status,
      };
    } catch (err: any) {
      return {
        user: emptyUser,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };
}
