import { HTTP_CODES } from "../../constants/httpstatus.const";
import { emptyVacation } from "../../data/internal/vacation";
import { Status } from "../../types/common/Status";
import { Vacation } from "../../types/internal/vacation/Vacation";
import {
  apiClient,
  getAuthConfig,
  buildConfigTimeReport,
  buildConfigVacation,
  buildConfigWithPagination,
} from "../HttpClient";

const VACATION_URL = "/vacation";
const VACATION_BY_EMPLOYEE = (employee: string) => VACATION_URL + "/" + employee;
const VACATION_BY_ID = (id: string) => VACATION_URL + "/" + id;
const VACATION_STATUS = (id: string) => VACATION_URL + "/" + id + "/status";
const VACATION_BY_APPROVER = (employee: string) =>
  VACATION_URL + "/pending/" + employee + "/approver";

export default class VacationService {
  static getVacationBy = async (id: string) => {
    try {
      const response = await apiClient.get(VACATION_BY_ID(id), getAuthConfig());
      const vacations: Vacation = response.data;
      return {
        vacation: vacations,
        code: response.status,
      };
    } catch (err: any) {
      return {
        vacation: emptyVacation,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static getVacationFrom = async (
    employeeEmail: string,
    startDate: string,
    endDate: string,
    page: number,
    size: number
  ) => {
    try {
      const response = await apiClient.get(
        VACATION_BY_EMPLOYEE(employeeEmail) + "/employee",
        buildConfigVacation(startDate, endDate, page, size)
      );
      const vacations: Vacation[] = response.data.content;
      return {
        vacationsPaginated: {
          totalElements: response.data.totalElements,
          totalPages: response.data.totalPages,
          size: response.data.totalSize,
          vacations: vacations,
        },
        code: response.status,
      };
    } catch (err: any) {
      return {
        vacationsPaginated: { totalElements: 0, totalPages: 0, size: 0, vacations: [] },
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static createVacation = async (vacation: Vacation) => {
    try {
      const response = await apiClient.post(VACATION_URL, vacation, getAuthConfig());
      return {
        vacation: response.data as Vacation,
        code: response.status,
      };
    } catch (err: any) {
      return {
        timeReport: emptyVacation,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static editVacation = async (vacation: Vacation) => {
    try {
      const response = await apiClient.put(
        VACATION_BY_ID(vacation.employee),
        vacation,
        getAuthConfig()
      );
      return {
        vacation: response.data as Vacation,
        code: response.status,
      };
    } catch (err: any) {
      return {
        vacation: emptyVacation,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static updateStatus = async (vacationId: string, status: Status) => {
    try {
      const response = await apiClient.put(VACATION_STATUS(vacationId), status, getAuthConfig());
      return {
        vacation: response.data as Vacation,
        code: response.status,
      };
    } catch (err: any) {
      return {
        vacation: emptyVacation,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static getVacationsByApprovers = async (employeeEmail: string, page: number, size: number) => {
    try {
      const response = await apiClient.get(
        VACATION_BY_APPROVER(employeeEmail),
        buildConfigWithPagination(page, size)
      );
      const vacations: Vacation[] = response.data.content;
      return {
        vacationsPaginated: {
          totalElements: response.data.totalElements,
          totalPages: response.data.totalPages,
          size: response.data.totalSize,
          vacations: vacations,
        },
        code: response.status,
      };
    } catch (err: any) {
      return {
        vacationsPaginated: { totalElements: 0, totalPages: 0, size: 0, vacations: [] },
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };
}
