import { apiClient, getAuthConfig } from "../HttpClient";
import { WBS } from "../../types/common/wbs/WBS";

const WBS_URL = "/wbs";

export default class WBSService {
  static getAllWBS = async () => {
    try {
      const response = await apiClient.get(WBS_URL, getAuthConfig());
      const wbs: WBS[] = response.data;
      return {
        wbs,
        code: response.status,
      };
    } catch (err: any) {
      return {
        wbs: [],
        code: err.response ? err.response.status : 500,
      };
    }
  };
}
