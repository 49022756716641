import React, { FC, useEffect, useMemo, useState } from "react";
import { List, Typography } from "@mui/material";
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Box } from "@mui/material";
import { SidebarItem } from "../../../mkt/Global/Sidebar/SidebarItem";
import { CollapseRight } from "../../../../assets/svg/CollapseRight";
import { CollapseLeft } from "../../../../assets/svg/CollapseLeft";
import { Person } from "../../../../assets/svg/Person";
import { sidebarItems } from "../../../../data/internal/sidebar";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { changeOpen } from "../../../../stores/Sidebar.store";
import { logout } from "../../../../stores/Authentication.store";
import { useNavigate } from "react-router-dom";
import { Finances } from "../../../../assets/svg/Finances";
import { KodlyIo } from "../../../../assets/svg/KodlyIo";
import { Hr } from "../../../../assets/svg/Hr";
import { FINANCE_ROLES, HR_ROLES, KODLY_IO_ROLES } from "../../../../constants/roles";
import LogoutIcon from "@mui/icons-material/Logout";
import "./Sidebar.css";
import { SHOW_EXTRA_WEBSITES } from "../../../../App";

export const Sidebar: FC = () => {
  const { open } = useTypedSelector((state: RootState) => state.sidebar);
  const { name } = useTypedSelector((state: RootState) => state.auth);
  const { roles } = useTypedSelector((state: RootState) => state.user.user);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [hasFinancesAccess, setHasFinancesAccess] = useState(false);
  const [hasHRAccess, setHasHRAccess] = useState(false);
  const [hasKodlyIoAccess, setHasKodlyIoAccess] = useState(false);

  useEffect(() => {
    setHasFinancesAccess(Boolean(roles.find((role) => FINANCE_ROLES.includes(role))));
    setHasHRAccess(Boolean(roles.find((role) => HR_ROLES.includes(role))));
    setHasKodlyIoAccess(Boolean(roles.find((role) => KODLY_IO_ROLES.includes(role))));
  }, [roles]);

  const checkSitesAcess = () => hasFinancesAccess || hasHRAccess || hasKodlyIoAccess;
  const showExtraSites = useMemo(
    () => checkSitesAcess(),
    [hasFinancesAccess, hasHRAccess, hasKodlyIoAccess]
  );

  const shouldShowExtraSites = () => {
    console.log(SHOW_EXTRA_WEBSITES);
    return SHOW_EXTRA_WEBSITES.toUpperCase() === "TRUE" && showExtraSites;
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const shouldDisplaySidebarItem = (role: string | undefined) => {
    return !role || (role && roles.includes(role));
  };

  interface mListItemProps {
    title: string;
    onClick: () => any;
    icon: JSX.Element;
  }

  const MListItem: FC<mListItemProps> = ({ title, onClick, icon }) => {
    return (
      <ListItem key={title} disablePadding sx={{ display: "block", margin: "1em 0" }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: "flex-start",
            px: 2.5,
          }}
          onClick={() => onClick()}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "flex-start",
              color: "white",
            }}
          >
            {icon}
          </ListItemIcon>
          {open && <ListItemText primary={title} />}
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <div className="Home-sidebar">
      <List>
        {sidebarItems.map(
          (item, index) =>
            shouldDisplaySidebarItem(item.requiredRole) && <SidebarItem key={index} item={item} />
        )}
        <Box margin="10px" sx={{ borderBottom: "1px solid #FFFFFF" }}></Box>
        {shouldShowExtraSites() && (
          <Box>
            {open ? (
              <Typography color="#FFFFFF" padding="0em 1em">
                Extra Websites
              </Typography>
            ) : null}
            {hasFinancesAccess && (
              <MListItem
                title={"Finance"}
                onClick={() => {
                  return;
                }}
                icon={<Finances />}
              />
            )}
            {hasKodlyIoAccess && (
              <MListItem
                title={"Kodly.io"}
                onClick={() => navigate("/careers")}
                icon={<KodlyIo />}
              />
            )}
            {hasHRAccess && (
              <MListItem
                title={"HR"}
                onClick={() => {
                  return;
                }}
                icon={<Hr />}
              />
            )}
          </Box>
        )}
      </List>
      <List className="bottomList">
        <MListItem
          title={"Collapse"}
          onClick={() => dispatch(changeOpen(!open))}
          icon={open ? <CollapseLeft /> : <CollapseRight />}
        />
        <MListItem title={name} onClick={() => {}} icon={<Person />} />
        <MListItem title={"Logout"} onClick={() => dispatch(logout({}))} icon={<LogoutIcon />} />
      </List>
    </div>
  );
};
