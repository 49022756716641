import React, { FC } from "react";
import { renderHtml } from "../../../../../utils/renderHtml";
import "./CaseStudyDetailsResults.css";

interface CaseStudyDetailsResultsProps {
  results: string;
}

export const CaseStudyDetailsResults: FC<CaseStudyDetailsResultsProps> = ({ results }) => {
  return (
    <section className="CaseStudyDetailsResults">
      <div className="CaseStudyDetailsResults-container">
        <h2>
          The <span>Results</span>
        </h2>
        <div className="CaseStudyDetailsResults-studyResults">
          <div dangerouslySetInnerHTML={renderHtml(results)} />
        </div>
      </div>
    </section>
  );
};
