import { Box, FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import React, { FC } from "react";

interface InputProps {
  placeholder?: string;
  disabled?: boolean;
  error: string | undefined;
  value: string | number;
  handleChange: (e: any) => void;
  name: string;
  type: string;
  multiline?: boolean;
  maxRows?: number;
  label?: string;
  width: string;
  height?: string;
  maxLength?: number;
  handleBlur?: (e: any) => void;
  noPadding?: boolean;
  minRows?: number;
}

export const Input: FC<InputProps> = ({
  error,
  value,
  handleChange,
  handleBlur,
  name,
  type,
  multiline,
  maxRows,
  label,
  width,
  height,
  maxLength,
  placeholder,
  disabled,
  noPadding,
  minRows,
}) => {
  return (
    <Box
      sx={{
        padding: noPadding ? "0" : "1em",
        width: width,
        height: "auto",
      }}
    >
      {label && (
        <Typography variant="body1" sx={{ color: error ? "#d32f2f" : "inherit" }}>
          {label}
        </Typography>
      )}
      <FormControl fullWidth variant="standard" error={Boolean(error)}>
        <TextField
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          type={type}
          variant="standard"
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          sx={{
            backgroundColor: "#C9ECE4",
            padding: "0.5em",
            paddingLeft: "1em",
            borderRadius: "24px",
            borderWidth: "0",
            width: "100%",
            height: "100%",
          }}
          InputProps={{
            style: { color: "black" },
            disableUnderline: true,
          }}
          multiline={multiline}
          minRows={minRows || maxRows}
          maxRows={maxRows}
          inputProps={{
            maxLength: maxLength,
            sx: {
              "&::placeholder": {
                color: "black",
                opacity: "0.7",
                transition: "opacity 0.5s",
              },
              "&:focus::placeholder": {
                opacity: "0",
                transition: "opacity 0.5s",
              },
            },
          }}
        />
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </Box>
  );
};
