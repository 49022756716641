import React, { FC } from "react";
import { TeamMember } from "../../../../../types/mkt/TeamMember";
import { ImageHoverCard } from "../../../Global/Cards/ImageHoverCard/ImageHoverCard";

interface TeamMemberCardProps {
  teamMember: TeamMember;
  editAction: any;
  publishAction: any;
  deleteAction: any;
  hoverEffectEnabled: boolean;
}

export const TeamMemberCard: FC<TeamMemberCardProps> = ({
  teamMember,
  editAction,
  publishAction,
  deleteAction,
  hoverEffectEnabled,
}) => {
  return (
    <ImageHoverCard
      imageURL={teamMember.image}
      title={teamMember.firstName + " " + teamMember.lastName}
      description={teamMember.text}
      publish={teamMember.publish}
      id={teamMember.id}
      editAction={editAction}
      publishAction={publishAction}
      deleteAction={deleteAction}
      hoverEffectEnabled={hoverEffectEnabled}
    />
  );
};
