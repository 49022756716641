import React, { FC, useEffect } from "react";
import "./ExpensesCreateEdit.css";
import { useFormik } from "formik";
import { createExpense, getAllExpensesType } from "../../../../stores/internal/Expense.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { Input } from "../../../Inputs/Input";
import { InputSelector } from "../../../Inputs/InputSelector";
import { getAllWBS } from "../../../../stores/common/WBS.store";
import { InputDate } from "../../../Inputs/InputDate";
import { emptyExpense } from "../../../../data/common/expense";
import { expenseValidationSchema } from "../../../../utils/internal/expense";
import { ConfirmAndCancelButtons } from "../../../common/buttons/CheckAndCancelButtons/ConfirmAndCancelButtons";
import { FileUploadButton } from "../../../ImageInputs/FileUploadButton/FileUploadButton";
import { WBS } from "../../../../types/common/wbs/WBS";
import { ExpenseType } from "../../../../types/internal/expenses/ExpenseType";

interface ExpensesCreateEditProps {
  navigateToSummary: () => void;
}

export const ExpensesCreateEdit: FC<ExpensesCreateEditProps> = ({ navigateToSummary }) => {
  const dispatch = useTypedDispatch();
  const { token, email } = useTypedSelector((state: RootState) => state.auth);
  const { expensesTypes } = useTypedSelector((state: RootState) => state.expenses);
  const { wbsAll } = useTypedSelector((state: RootState) => state.wbs);

  useEffect(() => {}, []);

  const formik = useFormik({
    initialValues: { ...emptyExpense },
    onSubmit: () => {},
    validationSchema: expenseValidationSchema,
  });

  useEffect(() => {
    fetchWBS();
    fetchExpensesType();
  }, []);

  const fetchWBS = async () => {
    await dispatch(getAllWBS());
  };

  const fetchExpensesType = async () => {
    await dispatch(getAllExpensesType());
  };

  const listWBSCodes = () => {
    return wbsAll.map((wbs: WBS) => wbs.code);
  };

  const listExpensesTypesNames = () => {
    return expensesTypes.map((expenseType: ExpenseType) => expenseType.code);
  };

  const onCancelButtonPress = () => {
    navigateToSummary();
  };

  const onConfirmButtonPress = async () => {
    if (await addExpense()) {
      navigateToSummary();
    }
  };

  const addExpense = async () => {
    formik.errors = await formik.validateForm(formik.values);
    if (Object.keys(formik.errors).length > 0) {
      return false;
    }
    formik.values.employee = email;
    await dispatch(
      createExpense({ expense: { ...formik.values, cost: Number(formik.values.cost).toString() } })
    );

    return true;
  };

  return (
    <div className="ExpensesCreateEdit-container">
      <div className="ExpensesCreateEdit-header">
        <div className="ExpensesCreateEdit-title">Create Expense</div>
        <div>
          <ConfirmAndCancelButtons
            onConfirmButtonTouch={() => onConfirmButtonPress()}
            onCancelButtonTouch={() => onCancelButtonPress()}
          />
        </div>
      </div>
      <div className="ExpensesCreateEdit-firstInputRow">
        <InputSelector
          error={formik.errors.wbs?.code}
          value={formik.values.wbs.code}
          handleChange={formik.handleChange}
          label="WBS Code"
          height="2rem"
          target={`wbs.code`}
          emptyValue=""
          emptyLabel=""
          values={listWBSCodes()}
          padding="1em"
          maxWidth="30%"
          multiple={false}
          notMappedValues={[]}
        />
        <InputDate
          error={formik.errors.submitted}
          value={formik.values.submitted}
          handleChange={formik.handleChange}
          label="Date"
          name={`submitted`}
          width="20%"
        />
        <InputSelector
          error={formik.errors.expensesType?.code}
          value={formik.values.expensesType.code}
          handleChange={formik.handleChange}
          label="Type"
          height="2rem"
          target={`expensesType.code`}
          emptyValue=""
          emptyLabel=""
          values={listExpensesTypesNames()}
          padding="1em"
          maxWidth="30%"
          notMappedValues={[]}
          multiple={false}
        />
      </div>
      <div className="ExpensesCreateEdit-secondInputRow">
        <Input
          error={formik.errors.description}
          value={formik.values.description}
          handleChange={formik.handleChange}
          label="Description"
          name="description"
          type="text"
          multiline
          maxRows={7}
          width="95%"
          height="11em"
        />
      </div>
      <div className="ExpensesCreateEdit-thirdInputRow">
        <Input
          error={formik.errors.cost}
          value={formik.values.cost}
          handleChange={formik.handleChange}
          label="Expense Value"
          name="cost"
          type="text"
          width="25%"
          height="2rem"
          maxLength={10}
        />
        <div className="ExpensesCreateEdit-FileUpload">
          <FileUploadButton
            title={"Attachment"}
            buttonTitle={"Attach document"}
            formik={formik}
            image={formik.values.attachment}
            target="attachment"
            folder="expenses"
          />
        </div>
      </div>
    </div>
  );
};
