import { Box, Button, Typography, Tooltip, IconButton } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { FC, ReactElement, useState } from "react";
import { Sidebar } from "../Sidebar/Sidebar";
import { TopBar } from "../../../TopBar/TopBar";
import { useNavigate } from "react-router-dom";
import { ListItemIcon } from "@mui/material";
import "./Page.css";
import { KodlyIcon } from "../../../../assets/svg/KodlyIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { RootState, useTypedSelector } from "../../../../stores/store";
import { PageCreateContent } from "../../../../types/mkt/PageContent";
import { renderHtml } from "../../../../utils/renderHtml";

import "./Page.css";
import { HomeKodlyIcon } from "../../../../assets/svg/HomeKodlyIcon";

interface PageCreateProps {
  children: ReactElement | null;
  pageContent: PageCreateContent;
  sidebar: any;
}

export const PageCreate: FC<PageCreateProps> = observer(({ children, pageContent, sidebar }) => {
  const { open } = useTypedSelector((state: RootState) => state.sidebar);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <Box display="flex" sx={{ height: "100vh" }}>
      <Box sx={{ height: "100vh", width: open ? "225px" : "75px" }}>
        <div className="Home-kodlyIcon">
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "flex-start",
              px: 2,
            }}
          >
            {open ? (
              <img
                src="https://kodly-assets.s3.eu-west-1.amazonaws.com/global/homeKodly_1660662061.png"
                alt="Logo"
                width="175px"
                onClick={() => navigate("/")}
              />
            ) : (
              <div onClick={() => navigate("/")}>
                <HomeKodlyIcon />
              </div>
            )}
          </ListItemIcon>
        </div>
        <Sidebar />
      </Box>
      <Box
        sx={{
          width: open ? "calc(100% - 225px)" : "calc(100% - 75px)",
          backgroundColor: "white",
        }}
      >
        <TopBar>
          <Box alignItems="center" sx={{ flexGrow: 1, display: { xs: "flex" } }}>
            {pageContent.switchTabAction == null || pageContent.tabs == null ? (
              <div className="Page-menuSwitch_container">
                <h2
                  className="Page-title"
                  dangerouslySetInnerHTML={renderHtml(pageContent.pageTitle)}
                ></h2>
                <Tooltip title="Cancel" color="error" sx={{ marginRight: "0.5em" }}>
                  <IconButton onClick={() => navigate(pageContent.previousPage)}>
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={pageContent.type == "Create" ? "Create" : "Confirm"}
                  sx={{ color: "#00b088", marginRight: "3em" }}
                >
                  <IconButton
                    onClick={() => {
                      pageContent.action();
                    }}
                  >
                    <CheckCircleIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div
                className="Page-menuSwitch_container"
                style={{ justifyContent: "space-between" }}
              >
                <div className="PageCreate-tabs" style={{ display: "contents" }}>
                  {pageContent.tabs.map((tab, index) => (
                    <Box
                      className={
                        activeTab == index
                          ? "Page-menuSwitch_activeElement"
                          : "Page-menuSwitch_element"
                      }
                      key={index}
                      onClick={() => {
                        pageContent.switchTabAction(index);
                        setActiveTab(index);
                      }}
                    >
                      {tab}
                      {activeTab == index ? <div className="Page-dashLine" /> : <div />}
                    </Box>
                  ))}
                </div>
                <div style={{ display: "flex", marginRight: "4.5em", marginLeft: "2.5em" }}>
                  <Tooltip className="PageCreate-cancelTooltip" title="Cancel" color="error">
                    <IconButton onClick={() => navigate(pageContent.previousPage)}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    className="PageCreate-confirmTooltip"
                    title={pageContent.type == "Create" ? "Create" : "Confirm"}
                    sx={{ color: "#00b088", marginLeft: "0.5em" }}
                  >
                    <IconButton
                      onClick={() => {
                        pageContent.action();
                      }}
                    >
                      <CheckCircleIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            )}
          </Box>
        </TopBar>
        <Box
          sx={{
            padding: "2em 4em",
            height: "calc(100vh - (92px + 4em))",
            overflowY: "auto",
            boxSizing: "content-box",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
});
