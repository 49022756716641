import React, { FC } from "react";

export const Person: FC = () => {
  return (
    <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5046 16.5C19.5434 16.5 22.0046 14.0387 22.0046 11C22.0046 7.96125 19.5434 5.5 16.5046 5.5C13.4659 5.5 11.0046 7.96125 11.0046 11C11.0046 14.0387 13.4659 16.5 16.5046 16.5ZM16.5046 19.25C12.8334 19.25 5.50464 21.0925 5.50464 24.75V27.5H27.5046V24.75C27.5046 21.0925 20.1759 19.25 16.5046 19.25Z"
        fill="white"
      />
    </svg>
  );
};
