import { CV } from "../../../types/internal/cv/CV";
import { emptyEducationCertifications } from "./educationCertifications";
import { emptyLanguages } from "./languages";
import { emptyPersonalInfo } from "./personalInfo";
import { emptyProfessionalExperience } from "./professionalExperience";
import { emptyTechStack } from "./techStack";

export const emptyCV: CV = {
  id: "",
  email: "",
  personalInfo: emptyPersonalInfo,
  techStack: [],
  experience: [],
  certifications: [],
  languages: [],
  interest: {
    description: "",
  },
};
