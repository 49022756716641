import { createSlice } from "@reduxjs/toolkit";
import { SidebarState } from "../types/SidebarState";

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: { open: true } as SidebarState,
  reducers: {
    changeOpen(state, action) {
      state.open = action.payload;
    },
  },
});

export default sidebarSlice.reducer;
export const { changeOpen } = sidebarSlice.actions;
