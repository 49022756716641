import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import { Vacancy } from "../../../../../types/mkt/Vacancy";
import { ButtonsCard } from "../../../Global/ButtonsCard/ButtonsCard";
import "./VacancyCard.css";

interface VacancyCardProps {
  icon: boolean;
  vacancy: Vacancy;
  editAction: any;
  publishAction: any;
  deleteAction: any;
  duplicateAction: any;
}

export const VacancyCard: FC<VacancyCardProps> = ({
  icon,
  vacancy,
  editAction,
  publishAction,
  deleteAction,
  duplicateAction,
}) => {
  return (
    <Box className={"VacancyCard-container"}>
      <Box className="VacancyCard-containerDisplay">
        <ButtonsCard
          id={vacancy.id}
          publish={vacancy.publish}
          editAction={editAction}
          publishAction={publishAction}
          deleteAction={deleteAction}
          duplicateAction={duplicateAction}
        />
        <div className={"VacancyCard-description"}>
          <Typography className="VacancyCard-role" variant="h6">
            <strong>{vacancy.role}</strong>
          </Typography>
          <Typography className="VacancyCard-info" variant="body1">
            {vacancy.type} {vacancy.officeMode} {vacancy.location}
          </Typography>
          <Typography
            sx={{
              fontWeight: "700",
              justifyContent: "center",
              marginTop: "1em",
              borderRadius: "32px",
              fontSize: "11px",
              width: "100px",
              padding: "0.2em",
            }}
            className={vacancy.publish ? "VacancyCard-published" : "VacancyCard-notPublished"}
            align="center"
          >
            {vacancy.publish ? "Published" : "Unpublished"}
          </Typography>
        </div>{" "}
      </Box>
    </Box>
  );
};
