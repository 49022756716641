import React, { FC } from "react";
import { Author } from "../../../../../types/mkt/Author";
import { BlogPostDetailsHeaderContent } from "./BlogPostDetailsHeaderContent/BlogPostDetailsHeaderContent";
import { BlogPostImage } from "./BlogPostImage/BlogPostImage";

import "./BlogPostDetailsHeader.css";
import { Category } from "../../../../../types/mkt/Category";

interface BlogPostDetailsHeaderProps {
  title: string;
  image: string;
  readTime: string;
  author: Author | null;
  subtitle: string;
  categories: Category[];
  date: string;
}

export const BlogPostDetailsHeader: FC<BlogPostDetailsHeaderProps> = ({
  title,
  image,
  readTime,
  author,
  subtitle,
  categories,
  date,
}) => {
  return (
    <section className="BlogPostDetailsHeader-container">
      <BlogPostImage image={image} />
      <BlogPostDetailsHeaderContent
        author={author}
        title={title}
        subtitle={subtitle}
        categories={categories}
        readTime={readTime}
        date={date}
      />
    </section>
  );
};
