import { useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CaseStudyCreateEdit } from "../../../../components/mkt/CaseStudy/CaseStudyCreateEdit/CaseStudyCreateEdit";
import { PageCreate } from "../../../../components/mkt/Global/Pages/PageCreate";
import { emptyCaseStudy } from "../../../../data/mkt/caseStudy";
import { createCaseStudy, setCreated } from "../../../../stores/mkt/CaseStudy.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { caseStudyValidationSchema } from "../../../../utils/mkt/caseStudy";

export const CaseStudyCreatePage: FC = () => {
  const dispatch = useTypedDispatch();
  const { caseStudy, created } = useTypedSelector((state: RootState) => state.caseStudies);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    if (created) {
      dispatch(setCreated(false));
      navigate("/case_studies");
    }
  }, [created]);

  useEffect(() => {}, [activeTab]);

  const createAction = async () => {
    formik.errors = await formik.validateForm(formik.values);

    if (Object.keys(formik.errors).length > 0) {
      return;
    }

    dispatch(createCaseStudy({ caseStudy: formik.values }));
  };

  const swithTabAction = (index: number) => {
    setActiveTab(index);
  };

  const formik = useFormik({
    initialValues: { ...emptyCaseStudy, order: 100 },
    onSubmit: (userInputData) => {},
    validationSchema: caseStudyValidationSchema,
  });

  return (
    <div className="CaseStudyCreatePage-pageCreateDiv">
      <PageCreate
        pageContent={{
          pageTitle: "Create CaseStudy",
          previousPage: "/case_studies",
          action: createAction,
          type: "Create",
          tabs: ["Summary", "The Challenge", "Kodly's Solution", "The Results"], //["Summary", "The Challenge", "Kodly's Solution", "The Results", "SEO"],
          switchTabAction: swithTabAction,
        }}
      >
        <CaseStudyCreateEdit formik={formik} activeTab={activeTab} />
      </PageCreate>
    </div>
  );
};
