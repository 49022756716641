import { HTTP_CODES } from "../../constants/httpstatus.const";
import { SubmissionStatus } from "../../data/common/SubmissionStatus";
import { emptyTimeReport } from "../../data/internal/timeReport";
import { TimeReport } from "../../types/internal/timeReport/TimeReport";
import { apiClient, buildConfigTimeReport, getAuthConfig } from "../HttpClient";

const TIME_REPORT_URL = "/tr";
const TIME_REPORT_BY_EMPLOYEE = (employee: string) => TIME_REPORT_URL + "/" + employee;
const TIME_REPORT_BY_ID = (id: string) => TIME_REPORT_URL + "/" + id;

export default class TimeReportService {
  static getTimeReport = async (employee: string, from: string, to: string) => {
    try {
      const response = await apiClient.get(
        TIME_REPORT_BY_EMPLOYEE(employee),
        buildConfigTimeReport(from, to)
      );
      const timeReport: TimeReport[] = response.data;
      const singleTimeReport = timeReport[0];
      return {
        timeReport: singleTimeReport,
        code: response.status,
      };
    } catch (err: any) {
      return {
        timeReport: emptyTimeReport("", ""),
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static createTimeReport = async (timeReport: TimeReport) => {
    try {
      const response = await apiClient.post(TIME_REPORT_URL, timeReport, getAuthConfig());
      return {
        timeReport: response.data as TimeReport,
        code: response.status,
      };
    } catch (err: any) {
      return {
        timeReport: emptyTimeReport,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static editTimeReport = async (timeReportId: string, timeReport: TimeReport) => {
    try {
      timeReport.status = SubmissionStatus.SUBMITTED;
      const response = await apiClient.put(
        TIME_REPORT_BY_ID(timeReportId),
        timeReport,
        getAuthConfig()
      );
      return {
        timeReport: response.data as TimeReport,
        code: response.status,
      };
    } catch (err: any) {
      return {
        timeReport: emptyTimeReport,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };
}
