import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { AddButton, ButtonTypes } from "../../../../button/Button";
import { Vacation } from "../../../../../types/internal/vacation/Vacation";
import {
  APPROVED_DAYS_CATEGORIES,
  computeVacationDaysGroupedByCategory,
  SCHEDULED_DAYS_CATEGORIES,
} from "../../../../../constants/internal/vacations.const";

interface VacationsSummaryCountProps {
  assignedVacationDays: number;
  vacationsForCurrentYear: Vacation[];
  navigateToCreateVacation: () => void;
}

export const VacationsSummaryCount: FC<VacationsSummaryCountProps> = ({
  assignedVacationDays,
  vacationsForCurrentYear,
  navigateToCreateVacation,
}) => {
  const currentYear = new Date().getFullYear().toString();

  let availableVacationDays =
    assignedVacationDays -
    computeVacationDaysGroupedByCategory(vacationsForCurrentYear, SCHEDULED_DAYS_CATEGORIES);

  const stillAllowedToAddVacations = () => {
    return availableVacationDays > 0;
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Box display={{ sm: "block", md: "flex", lg: "flex", marginTop: "50px" }}>
          <Box
            width="280px"
            minWidth="240px"
            height="135px"
            display="flex"
            sx={{
              backgroundColor: "#FCFCFC",
              alignItems: "center",
              boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
              margin: "10px",
            }}
            textAlign="center"
            justifyContent="center"
            borderRadius="50px"
          >
            <Box>
              <Typography variant="h5" color="initial">
                {availableVacationDays}
              </Typography>
              <Typography variant="body1" color="initial">
                Available Vacation Days <br /> {"(" + currentYear + ")"}
              </Typography>
            </Box>
          </Box>
          <Box
            width="280px"
            height="135px"
            display="flex"
            sx={{
              backgroundColor: "#F7F5F6",
              alignItems: "center",
              boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
              margin: "10px",
            }}
            textAlign="center"
            justifyContent="center"
            borderRadius="50px"
          >
            <Box>
              <Typography variant="h5" color="initial">
                {computeVacationDaysGroupedByCategory(
                  vacationsForCurrentYear,
                  SCHEDULED_DAYS_CATEGORIES
                )}
              </Typography>
              <Typography variant="body1" color="initial">
                Scheduled Vacation Days <br /> {"(" + currentYear + ")"}
              </Typography>
            </Box>
          </Box>
          <Box
            width="280px"
            height="135px"
            display="flex"
            sx={{
              backgroundColor: "#209973",
              alignItems: "center",
              boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
              margin: "10px",
            }}
            textAlign="center"
            justifyContent="center"
            borderRadius="50px"
          >
            <Box>
              <Typography variant="h5" color="white">
                {computeVacationDaysGroupedByCategory(
                  vacationsForCurrentYear,
                  APPROVED_DAYS_CATEGORIES
                )}
              </Typography>
              <Typography variant="body1" color="white">
                Approved Vacation Days <br /> {"(" + currentYear + ")"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="start"
        justifyContent="end"
        sx={{ width: "30%", marginTop: "10px" }}
      >
        <div className="buttonsContainer">
          <AddButton
            text="New Vacation"
            onClick={stillAllowedToAddVacations() ? navigateToCreateVacation : () => {}}
            type={ButtonTypes.Primary}
            disabled={!stillAllowedToAddVacations()}
          />
        </div>
      </Box>
    </Box>
  );
};
