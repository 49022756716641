import { Vacation } from "../../types/internal/vacation/Vacation";

export const emptyVacation: Vacation = {
  id: null,
  employee: "",
  startDate: "",
  endDate: "",
  status: "",
  approvers: [],
  total: 0,
};
