import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BlogPostDetails } from "../../../../components/mkt/BlogPost/BlogPostDetails/BlogPostDetails";
import { PageDetails } from "../../../../components/mkt/Global/Pages/PageDetails";
import {
  deleteBlogPost,
  getBlogPost,
  publishBlogPost,
  setDeleted,
} from "../../../../stores/mkt/BlogPost.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { PageDetailsContent } from "../../../../types/mkt/PageContent";

export const BlogPostDetailsPage: FC = () => {
  const dispatch = useTypedDispatch();
  const { blogPost, loading, error, deleted } = useTypedSelector(
    (state: RootState) => state.blogPosts
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchBlogPost = async () => {
      await dispatch(getBlogPost({ id: id!! })).then(() => {
        setLoaded(true);
      });
    };
    // call the function
    fetchBlogPost();
  }, []);

  useEffect(() => {
    setPageContent({
      pageTitle: "",
      isPublish: blogPost.publish,
      previousPage: "/blog_posts",
      publishAction: publishAction,
      deleteAction: deleteAction,
      editAction: editAction,
    });
  }, [blogPost]);

  const editAction = () => {
    navigate(`/blog_posts/${blogPost.id}/edit`);
  };

  const publishAction = () => {
    dispatch(publishBlogPost({ id: id!!, publish: blogPost.publish }));
  };

  const deleteAction = () => {
    dispatch(deleteBlogPost({ id: id!! }));
  };

  const [pageContent, setPageContent] = useState<PageDetailsContent>({
    pageTitle: "",
    isPublish: blogPost.publish,
    previousPage: "/blog_posts",
    publishAction: publishAction,
    deleteAction: deleteAction,
    editAction: editAction,
  });

  useEffect(() => {
    if (deleted) {
      dispatch(setDeleted(false));
      navigate("/blog_posts");
    }
  }, [deleted]);

  return (
    <PageDetails pageContent={pageContent}>
      {loaded ? <BlogPostDetails blogPost={blogPost} /> : null}
    </PageDetails>
  );
};
