import React, { FC } from "react";
import { Category } from "../../../../../../../types/mkt/Category";

import "./BlogPostCategories.css";

interface BlogPostCategoriesProps {
  categories: Category[] | null;
}

export const BlogPostCategories: FC<BlogPostCategoriesProps> = ({ categories }) => {
  return (
    <div className="BlogPostCategories-container">
      {categories &&
        categories.map((category, index) => (
          <span key={index} className="BlogPostCategories-single">
            {category.name}
          </span>
        ))}
    </div>
  );
};
