import { observer } from "mobx-react-lite";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Theme, CSSObject } from "@mui/material/styles";
import { List, Typography } from "@mui/material";
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Box } from "@mui/material";
import { SidebarItem } from "./SidebarItem";
import { CollapseRight } from "../../../../assets/svg/CollapseRight";
import { CollapseLeft } from "../../../../assets/svg/CollapseLeft";
import { Person } from "../../../../assets/svg/Person";
import { sidebarItems } from "../../../../data/mkt/sidebar";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { changeOpen } from "../../../../stores/Sidebar.store";
import { logout } from "../../../../stores/Authentication.store";
import { useNavigate } from "react-router-dom";
import "./Sidebar.css";
import LogoutIcon from "@mui/icons-material/Logout";
import { FINANCE_ROLES, HR_ROLES, INTERNAL_ROLES } from "../../../../constants/roles";
import { Finances } from "../../../../assets/svg/Finances";
import { MyKodly } from "../../../../assets/svg/MyKodly";
import { Hr } from "../../../../assets/svg/Hr";
import { SHOW_EXTRA_WEBSITES } from "../../../../App";

export const Sidebar: FC = observer(() => {
  const { open } = useTypedSelector((state: RootState) => state.sidebar);
  const { name } = useTypedSelector((state: RootState) => state.auth);
  const { roles } = useTypedSelector((state: RootState) => state.user.user);
  const dispatch = useTypedDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [hasFinancesAccess, setHasFinancesAccess] = useState(false);
  const [hasHRAccess, setHasHRAccess] = useState(false);
  const [hasMyKodlyAccess, setHasMyKodlyAccess] = useState(false);

  useEffect(() => {
    setHasFinancesAccess(Boolean(roles.find((role) => FINANCE_ROLES.includes(role))));
    setHasHRAccess(Boolean(roles.find((role) => HR_ROLES.includes(role))));
    setHasMyKodlyAccess(Boolean(roles.find((role) => INTERNAL_ROLES.includes(role))));
  }, [roles]);

  const checkSitesAcess = () => hasFinancesAccess || hasHRAccess || hasMyKodlyAccess;
  const showExtraSites = useMemo(
    () => checkSitesAcess(),
    [hasFinancesAccess, hasHRAccess, hasMyKodlyAccess]
  );

  const shouldShowExtraSites = () => {
    return SHOW_EXTRA_WEBSITES.toUpperCase() === "TRUE" && showExtraSites;
  };

  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  interface mListItemProps {
    title: string;
    onClick: () => any;
    icon: JSX.Element;
  }

  const MListItem: FC<mListItemProps> = ({ title, onClick, icon }) => {
    return (
      <ListItem key={title} disablePadding sx={{ display: "block", margin: "1em 0" }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: "flex-start",
            px: 2.5,
          }}
          onClick={() => onClick()}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "flex-start",
              color: "white",
            }}
          >
            {icon}
          </ListItemIcon>
          {open && <ListItemText primary={title} />}
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <div className="sidebar">
      <List>
        {sidebarItems.map((item, index) => (
          <SidebarItem key={index} item={item} />
        ))}
        <Box margin="10px" sx={{ borderBottom: "1px solid #FFFFFF" }}></Box>
        {shouldShowExtraSites() && (
          <Box>
            {open ? (
              <Typography color="#FFFFFF" padding="0em 1em">
                Extra Websites
              </Typography>
            ) : null}
            {hasFinancesAccess && (
              <MListItem title={"Finance"} onClick={() => navigate("/home")} icon={<Finances />} />
            )}
            {hasMyKodlyAccess && (
              <MListItem
                title={"My Kodly"}
                onClick={() => navigate("/profile")}
                icon={<MyKodly />}
              />
            )}
            {hasHRAccess && (
              <MListItem title={"HR"} onClick={() => navigate("/home")} icon={<Hr />} />
            )}
          </Box>
        )}
      </List>
      <List className="bottomList">
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: "flex-start",
              px: 2.5,
            }}
            onClick={() => dispatch(changeOpen(!open))}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "flex-start",
              }}
            >
              {open ? <CollapseLeft /> : <CollapseRight />}
            </ListItemIcon>
            {open && <ListItemText primary="Collapse" />}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItem
            sx={{
              minHeight: 48,
              justifyContent: "flex-start",
              px: 2.5,
            }}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "flex-start",
              }}
            >
              <Person />
            </ListItemIcon>
            {open && <ListItemText primary={name} />}
          </ListItem>
        </ListItem>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: "flex-start",
              px: 2.5,
            }}
            onClick={() => dispatch(logout({}))}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "flex-start",
                color: "white",
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            {open && <ListItemText primary="Logout" />}
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
});
