import React, { FC, ReactElement, useState } from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Sidebar } from "../Sidebar/Sidebar";
import { PageContent } from "../../../../types/internal/PageContent";
import { RootState, useTypedSelector } from "../../../../stores/store";
import { useNavigate } from "react-router-dom";
import "./Page.css";
import { ListItemIcon } from "@mui/material";
import { TopBar } from "../../../TopBar/TopBar";
import { HomeKodlyIcon } from "../../../../assets/svg/HomeKodlyIcon";

interface PageProps {
  children: ReactElement | null;
  pageContent: PageContent | null;
  backgroundColor: string | null;
}

export const Page: FC<PageProps> = observer(({ children, pageContent, backgroundColor }) => {
  const { open } = useTypedSelector((state: RootState) => state.sidebar);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <Box display="flex" sx={{ height: "100vh" }}>
      <Box sx={{ height: "100vh", width: open ? "225px" : "75px" }}>
        <div className="Home-kodlyIcon">
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "flex-start",
              px: 2,
            }}
          >
            {open ? (
              <img
                src="https://kodly-assets.s3.eu-west-1.amazonaws.com/global/homeKodly_1660662061.png"
                alt="Logo"
                width="175px"
                onClick={() => navigate("/")}
              />
            ) : (
              <div onClick={() => navigate("/")}>
                <HomeKodlyIcon />
              </div>
            )}
          </ListItemIcon>
        </div>
        <Sidebar />
      </Box>
      {!pageContent ? (
        <Box
          sx={{
            width: open ? "calc(100% - 225px)" : "calc(100% - 75px)",
            padding: "1em 2em",
            backgroundColor: backgroundColor == null ? "#F5F5F5" : backgroundColor,
            overflowY: "auto",
          }}
        >
          {children}
        </Box>
      ) : (
        <Box
          sx={{
            width: open ? "calc(100% - 225px)" : "calc(100% - 75px)",
            backgroundColor: backgroundColor == null ? "#F5F5F5" : backgroundColor,
          }}
        >
          <TopBar>
            {pageContent.switchTabAction == null || pageContent.tabs == null ? null : (
              <div className="Page-menuSwitch_container">
                {pageContent.tabs.map((tab, index) => (
                  <Box
                    className={
                      activeTab == index
                        ? "Page-menuSwitch_activeElement"
                        : "Page-menuSwitch_element"
                    }
                    key={index}
                    onClick={() => {
                      pageContent.switchTabAction(index);
                      setActiveTab(index);
                    }}
                  >
                    {tab}
                    {activeTab == index ? <div className="Page-dashLine" /> : <div />}
                  </Box>
                ))}
              </div>
            )}
          </TopBar>
          <Box
            sx={{
              padding: "1em 2em",
              height: "calc(100vh - (92px + 2em))",
              overflowY: "auto",
              boxSizing: "content-box",
            }}
          >
            {children}
          </Box>
        </Box>
      )}
    </Box>
  );
});
