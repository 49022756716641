import React, { FC, useEffect, useState } from "react";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../stores/store";
import { getUser } from "../../../stores/internal/User.store";
import { Page } from "../../../components/internal/Global/Pages/Page";
import { UserDetails } from "../../../components/internal/User/UserDetails/UserDetails";

export const UserPage: FC = () => {
  const dispatch = useTypedDispatch();
  const { user, loading, error } = useTypedSelector((state: RootState) => state.user);
  const { token, email } = useTypedSelector((state: RootState) => state.auth);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      await dispatch(getUser({ email: email!! })).then(() => {
        setLoaded(true);
      });
    };
    // call the function
    fetchUser();
  }, []);

  return (
    <Page pageContent={null} backgroundColor={null}>
      {loaded ? <UserDetails user={user} /> : null}
    </Page>
  );
};
