import React, { FC } from "react";
import { Category } from "../../../../../types/mkt/Category";
import { BubbleSearchInput } from "./BubbleSearchInput/BubbleSearchInput";
import "./BlogPostCreateEditCategories.css";

interface BlogPostCreateEditCategoriesProps {
  formik: any;
}

export const BlogPostCreateEditCategories: FC<BlogPostCreateEditCategoriesProps> = ({ formik }) => {
  const handleChange = (value: Category[]) => {
    const e = { target: { value: value, name: "categories" } };
    formik.handleChange(e);
  };

  return (
    <div className="BlogPostCreateEditCategories-categoriesInput">
      <BubbleSearchInput
        items={formik.values.categories}
        handleChange={handleChange}
        error={formik.errors.categories}
        label={"Categories"}
      />
    </div>
  );
};
