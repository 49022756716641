import React, { FC } from "react";

export const HomeKodlyIcon: FC = () => {
  return (
    <svg width="35" height="35" viewBox="0 0 37 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.9793 0.823517C12.6414 1.05881 7.55175 3.64705 4.4483 7.64705C3.95175 8.11764 3.82761 8.70587 3.4552 9.05881C0.103475 14.4706 0.103475 21.1765 3.4552 26.4706L11.2759 17.7647L3.4552 9.17646C3.82761 8.82352 3.95175 8.23528 4.4483 7.76469L13.6345 17.8823L4.4483 28C7.67589 32.2353 12.6414 34.7059 17.9793 34.9412V0.823517Z"
        fill="#000000"
      />
      <path
        d="M34.3656 26.5882C36.1035 24 36.8483 21.0588 36.8483 17.8823C36.8483 14.7059 35.8552 11.8823 34.3656 9.05881C33.9931 8.47058 33.6207 7.99999 33.2483 7.5294C30.1449 3.5294 25.1794 1.05881 19.7173 0.823517V34.9412C25.1794 34.5882 30.0207 32.1176 33.2483 28L24.0621 17.8823L33.2483 7.76469C33.6207 8.23528 33.9931 8.70587 34.3656 9.29411L26.4207 18L34.3656 26.5882Z"
        fill="#000000"
      />
    </svg>
  );
};
