import { HTTP_CODES } from "../constants/httpstatus.const";
import { Auth } from "../types/mkt/Auth";
import { apiClient, getAuthConfig } from "./HttpClient";

const SIGNUP_URL = "/signup";

export default class AuthService {
  static signup = async (token: string) => {
    try {
      this.setToken(token);
      const response = await apiClient.post(SIGNUP_URL, { token }, getAuthConfig());
      const auth: Auth = response.data;
      return {
        token,
        code: response.status,
      };
    } catch (err: any) {
      return {
        token: null,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static setToken = (token: any) => {
    localStorage.setItem("token", JSON.stringify(token));
  };

  static getToken = () => {
    const token = localStorage.getItem("token");
    return token ? JSON.parse(token) : null;
  };

  static removeToken = () => {
    localStorage.removeItem("token");
  };
}
