import React, { FC } from "react";
import { TimeReportedByDate } from "../../../../../../../types/internal/timeReport/TimeReportedByDate";
import { TimeReportedByProject } from "../../../../../../../types/internal/timeReport/TimeReportedByProject";
import { doesDateBelongToWeekend } from "../../../../../../../utils/date";
import "./TimeReportRowValues.css";
interface TimeReportRowValuesProps {
  vacationDays?: Date[];
  singleReportedTime: TimeReportedByProject;
  position: number;
  handleChange?: (value: any, name: string) => void;
  error: any;
  allowedToEdit: boolean;
}

export const TimeReportRowValues: FC<TimeReportRowValuesProps> = ({
  vacationDays,
  singleReportedTime,
  position,
  handleChange,
  error,
  allowedToEdit,
}) => {
  const getCellClassName = (date: string, index: number) => {
    var className = doesDateBelongToWeekend(new Date(date))
      ? "TimeReportRow-weekendInput"
      : isVacation(new Date(date))
      ? "TimeReportRow-vacationsInput"
      : isError(index)
      ? "TimeReportRow-input TimeReportRow-inputError"
      : !allowedToEdit
      ? "TimeReportRow-input TimeReportRow-approved"
      : "TimeReportRow-input";
    return className;
  };

  const isVacation = (dateToFind: Date) => {
    return vacationDays?.find((date: Date) => date.getTime() === dateToFind.getTime()) != undefined;
  };

  const isError = (index: number) => {
    return (
      error &&
      error.timeReported &&
      error.timeReported[index] &&
      error.timeReported[index].reportedTime
    );
  };

  return (
    <>
      {singleReportedTime &&
        singleReportedTime.timeReported.map(
          (singleTimeReported: TimeReportedByDate, index: number) => {
            return (
              <td className="TimeReportRow-td" key={index}>
                <input
                  placeholder="*"
                  onInput={(e: any) => (e.target.value = e.target.value.slice(0, 1))}
                  type="number"
                  name="name"
                  value={singleTimeReported.reportedTime.toString()}
                  className={getCellClassName(singleTimeReported.date, index)}
                  onChange={(event) => {
                    handleChange != undefined &&
                      handleChange(
                        parseInt(event.target.value),
                        `reportedTime[${position}].timeReported[${index}].reportedTime`
                      );
                  }}
                />
              </td>
            );
          }
        )}
    </>
  );
};
