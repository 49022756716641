import { Box, Button, IconButton } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import ClearIcon from "@mui/icons-material/Clear";

import "./FileDownloadButton.css";

interface FileDownloadButtonProps {
  title: string;
  fileURL: string;
  onDeleteButtonPress?: (e: any) => void;
}

export const FileDownloadButton: FC<FileDownloadButtonProps> = ({
  title,
  fileURL,
  onDeleteButtonPress,
}) => {
  const openFileURL = () => {
    var win = window.open(fileURL, "_blank");
    if (win != null) {
      win.focus();
    }
  };

  const trimStringToEllipsisInMiddle = () => {
    const name = getFileNameFromURL();
    if (name != undefined && name.length > 20) {
      return name.substring(0, 10) + "..." + name.substring(name.length - 10, name.length);
    }
    return name;
  };

  const getFileNameFromURL = () => {
    return fileURL.substring(fileURL.lastIndexOf("/") + 1);
  };

  return (
    <div className="FileDownloadButton-container">
      <div className="FileDownloadButton-title">{title}</div>
      <div className="FileDownloadButton-containerButton">
        <div className="FileDownloadButton-button">
          <div
            className="FileDownloadButton-buttonContent"
            onMouseDown={() => {
              openFileURL();
            }}
          >
            {trimStringToEllipsisInMiddle()}
            <DownloadIcon />
          </div>
          {onDeleteButtonPress != undefined && (
            <div className="FileDownloadButton-deleteButtonContainer">
              <IconButton className="FileDownloadButton-deleteButton" onClick={onDeleteButtonPress}>
                <ClearIcon className="FileDownloadButton-deleteIcon" />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
