import React, { FC, useRef, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import "./FileUploadButtonAddMode.css";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { deleteImage, uploadImage } from "../../../../stores/Image.store";

interface FileUploadButtonAddModeProps {
  title: string;
  buttonTitle: string;
  name?: string;
  formik: any;
  folder: string;
  target: string;
  onFileUpload: (url: string) => void;
}

export const FileUploadButtonAddMode: FC<FileUploadButtonAddModeProps> = ({
  title,
  buttonTitle,
  name,
  formik,
  folder,
  target,
  onFileUpload,
}) => {
  const dispatch = useTypedDispatch();
  const [preview, setPreview] = useState<string>();
  const inputFile = useRef<HTMLInputElement | null>(null);

  const onButtonClick = () => {
    inputFile.current?.click();
  };

  const onAddButtonPress = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      deleteFile();
      addFile(e);
    }
    e.target.value = null;
  };

  const addFile = (e: any) => {
    var data = dispatch(uploadImage({ file: e.target.files[0]!!, folder }));
    data.then((data: any) => {
      formik.setFieldValue(target, data.payload.url);
      onFileUpload(data.payload.url);
    });
  };

  const deleteFile = async () => {
    if (preview) {
      dispatch(
        deleteImage({
          key: folder + "/" + preview.substring(preview!!.lastIndexOf("/") + 1),
        })
      );
      formik.setFieldValue(name || target, undefined);
    }
  };

  return (
    <div className="FileUploadButtonAddMode-container">
      <div className="FileUploadButtonAddMode-title">{title}</div>
      <div
        className="FileUploadButtonAddMode-button"
        onMouseDown={() => {
          onButtonClick();
        }}
      >
        {buttonTitle}
        <AttachFileIcon />
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={onAddButtonPress}
          accept="image/*|application/pdf"
        />
      </div>
    </div>
  );
};
