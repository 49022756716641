import { emptyAuthor } from "./author";

export const emptyBlogPost = {
  id: "",
  title: "",
  subtitle: "",
  categories: [],
  date: "",
  readTime: "",
  author: emptyAuthor,
  thumbnail: "",
  publish: false,
  order: 0,
  content: "",
  url: "",
  metaInfo: "",
  pageTitle: "",
  h1: "",
  h2: "",
};
