import React, { FC, useEffect, useState } from "react";
import { Button, FormControl, FormHelperText, Typography, Box } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import "./BubbleSearchInput.css";
import { AutoComplete } from "./AutoComplete/AutoComplete";
import { Category } from "../../../../../../types/mkt/Category";
import { emptyCategory } from "../../../../../../data/mkt/category";

interface BubbleSearchInputProps {
  items: Category[];
  handleChange: (value: Category[]) => void;
  error: any;
  label: string;
}

export const BubbleSearchInput: FC<BubbleSearchInputProps> = ({
  items,
  handleChange,
  error,
  label,
}) => {
  const [values, setValues] = useState<Category[]>([]);

  useEffect(() => {
    setValues(items);
  });

  useEffect(() => {}, [error]);

  const handleAdd = () => {
    setValues([...items, emptyCategory]);
    handleChange([...items, emptyCategory]);
  };

  const handleDelete = (index: number) => {
    let aux = [...values];
    aux.splice(index, 1);
    setValues(aux);
    handleChange(aux);
  };

  const handleInputChange = (value: Category, index: number) => {
    let aux = [...values];
    aux[index] = value;
    setValues(aux);
    handleChange(aux);
  };

  return (
    <Box sx={{ padding: "1em", width: "80%" }}>
      <FormControl fullWidth variant="standard">
        <Typography variant="body1">{label}</Typography>
        <Button
          className="BubbleInput-addItemsButton"
          endIcon={<AddCircleOutlineIcon />}
          onClick={handleAdd}
        >
          Add {label}
        </Button>
        <Box className="BubbleInput-grid">
          {values &&
            values.map((item: any, index: number) => {
              return (
                <AutoComplete
                  key={index}
                  index={index}
                  category={item}
                  handleInputChange={handleInputChange}
                  handleDelete={handleDelete}
                  error={error}
                />
              );
            })}
        </Box>
        {items.length === 0 && error && (
          <FormHelperText sx={{ color: error ? "#d32f2f" : "inherit" }}>
            {error.toString()}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};
