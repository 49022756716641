import React, { FC } from "react";

export const CollapseRight: FC = () => {
  return (
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.463 17.7083C11.2561 17.7095 11.0535 17.649 10.8811 17.5347C10.7087 17.4203 10.5743 17.2572 10.4949 17.0661C10.4156 16.875 10.3949 16.6646 10.4356 16.4617C10.4763 16.2589 10.5765 16.0727 10.7234 15.927L14.1609 12.4999L10.7234 9.07286C10.5528 8.87359 10.4636 8.61726 10.4737 8.3551C10.4839 8.09294 10.5925 7.84425 10.778 7.65873C10.9636 7.47322 11.2122 7.36454 11.4744 7.35441C11.7366 7.34429 11.9929 7.43346 12.1922 7.60411L16.3588 11.7708C16.5529 11.9659 16.6618 12.23 16.6618 12.5052C16.6618 12.7803 16.5529 13.0444 16.3588 13.2395L12.1922 17.4062C11.9982 17.5986 11.7363 17.7071 11.463 17.7083Z"
        fill="white"
      />
      <path
        d="M15.6297 13.5416H5.21305C4.93679 13.5416 4.67183 13.4319 4.47648 13.2365C4.28113 13.0412 4.17139 12.7762 4.17139 12.5C4.17139 12.2237 4.28113 11.9587 4.47648 11.7634C4.67183 11.568 4.93679 11.4583 5.21305 11.4583H15.6297C15.906 11.4583 16.1709 11.568 16.3663 11.7634C16.5616 11.9587 16.6714 12.2237 16.6714 12.5C16.6714 12.7762 16.5616 13.0412 16.3663 13.2365C16.1709 13.4319 15.906 13.5416 15.6297 13.5416ZM19.7964 20.8333C19.5201 20.8333 19.2552 20.7235 19.0598 20.5282C18.8645 20.3328 18.7547 20.0679 18.7547 19.7916V5.20829C18.7547 4.93203 18.8645 4.66707 19.0598 4.47172C19.2552 4.27637 19.5201 4.16663 19.7964 4.16663C20.0727 4.16663 20.3376 4.27637 20.533 4.47172C20.7283 4.66707 20.8381 4.93203 20.8381 5.20829V19.7916C20.8381 20.0679 20.7283 20.3328 20.533 20.5282C20.3376 20.7235 20.0727 20.8333 19.7964 20.8333Z"
        fill="white"
      />
    </svg>
  );
};
