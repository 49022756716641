import { Box } from "@mui/material";
import React, { FC } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";

import { setServices } from "../../../../../stores/mkt/Service.store";
import { useTypedDispatch } from "../../../../../stores/store";
import { Service } from "../../../../../types/mkt/Service";
import { reorder } from "../../../../../utils/array";
import { ServiceDndSingle } from "./ServiceDndSingle/ServiceDnDSingle";

interface ServiceDndProps {
  services: Service[];
}

export const ServiceDnd: FC<ServiceDndProps> = ({ services }) => {
  const dispatch = useTypedDispatch();

  // Function to update list on drop
  const handleDrop = (result: any) => {
    // Ignore drop outside droppable container
    if (!result.destination) {
      return;
    }

    const items = reorder(services, result.source.index, result.destination.index);
    dispatch(setServices(items));
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId={"dndServices"}>
            {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
              <Box ref={dropProvided.innerRef}>
                {services.map((service, index: number) => (
                  <Draggable index={index} draggableId={service.id} key={service.id}>
                    {(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => (
                      <div
                        ref={dragProvided.innerRef}
                        key={service.id}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                      >
                        <ServiceDndSingle service={service} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {dropProvided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
};
