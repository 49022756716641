import React, { FC, useState } from "react";
import "./ImageHoverCard.css";
import { ButtonsCard } from "../../ButtonsCard/ButtonsCard";

interface ImageHooverCardProps {
  imageURL: string;
  title: string;
  description: string;
  publish: boolean;
  id: string;
  editAction: any;
  publishAction: any;
  deleteAction: any;
  hoverEffectEnabled: boolean;
}

export const ImageHoverCard: FC<ImageHooverCardProps> = ({
  imageURL,
  title,
  description,
  publish,
  id,
  editAction,
  publishAction,
  deleteAction,
  hoverEffectEnabled,
}) => {
  const [hooverActive, setHooverActive] = useState(false);

  return (
    <div
      className="image-hover-card-container"
      onMouseEnter={() => {
        setHooverActive(true);
      }}
      onMouseLeave={() => {
        setHooverActive(false);
      }}
      style={{
        backgroundImage: `url(${imageURL})`,
      }}
    >
      <div
        className="image-hover-card-container-background"
        style={{
          backgroundColor:
            hooverActive && hoverEffectEnabled ? "rgb(102,205,181,0.8)" : "transparent",
        }}
      >
        <ButtonsCard
          id={id}
          publish={publish}
          editAction={editAction}
          publishAction={publishAction}
          deleteAction={deleteAction}
          duplicateAction={null}
        />
        <div
          className={
            publish ? "image-hover-card-is-published" : "image-hover-card-is-not-published"
          }
        >
          {publish ? "Published" : "Unpublished"}
        </div>

        {hooverActive && hoverEffectEnabled && (
          <div className="image-hover-card-container-content">
            <div className="image-hover-card-title">{title}</div>
            <div className="image-hover-card-description">{description}</div>
          </div>
        )}
      </div>
    </div>
  );
};
