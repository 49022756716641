import { Box } from "@mui/material";
import React, { FC } from "react";
import { CaseStudy } from "../../../../../../types/mkt/CaseStudy";
import { CaseStudyCard } from "../../CaseStudyCard/CaseStudyCard";

interface CaseStudyDnDSingleProps {
  caseStudy: CaseStudy;
}

export const CaseStudyDnDSingle: FC<CaseStudyDnDSingleProps> = ({ caseStudy }) => {
  return (
    <div>
      <CaseStudyCard caseStudy={caseStudy} />
      <br />
    </div>
  );
};
