import * as yup from "yup";

export const clientValidationSchema = yup.object({
  name: yup
    .string()
    .strict()
    .trim("Name should not end in an empty space")
    .required("Name is required")
    .matches(/^((?!\s\s+).)*$/, "Name has multiple white spaces"),
  image: yup.string().strict().trim().required("Image is required"),
});
