import { HTTP_CODES } from "../../constants/httpstatus.const";
import { emptyVacancy } from "../../data/mkt/vacancy";
import { Vacancy } from "../../types/mkt/Vacancy";
import { apiClient, getAuthConfig } from "../HttpClient";

const VACANCY_URL = "/vacancy";
const VACANCY_BY_ID = (id: string) => VACANCY_URL + "/" + id;
const VACANCY_PUBLISH = (id: string) => `${VACANCY_URL}/${id}/publish`;
const VACANCY_UNPUBLISH = (id: string) => `${VACANCY_URL}/${id}/unpublish`;
const VACANCY_LOCATIONS = VACANCY_URL + "/locations";

export default class VacancyService {
  static getVacancies = async () => {
    try {
      const response = await apiClient.get(VACANCY_URL, getAuthConfig());
      const vacancies: Vacancy[] = response.data;
      return {
        vacancies,
        code: response.status,
      };
    } catch (err: any) {
      return {
        vacancies: [],
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static getVacancy = async (id: string) => {
    try {
      const response = await apiClient.get(VACANCY_BY_ID(id), getAuthConfig());
      const vacancy: Vacancy = response.data;
      return {
        vacancy,
        code: response.status,
      };
    } catch (err: any) {
      return {
        vacancy: emptyVacancy,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static createVacancy = async (vacancy: Vacancy) => {
    try {
      const response = await apiClient.post(VACANCY_URL, vacancy, getAuthConfig());
      return {
        vacancy: response.data as Vacancy,
        code: response.status,
      };
    } catch (err: any) {
      return {
        vacancy: emptyVacancy,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static editVacancy = async (vacancy: Vacancy) => {
    try {
      const response = await apiClient.put(VACANCY_URL, vacancy, getAuthConfig());
      return {
        vacancy: response.data as Vacancy,
        code: response.status,
      };
    } catch (err: any) {
      return {
        vacancy: emptyVacancy,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static deleteVacancy = async (id: string) => {
    try {
      const response = await apiClient.delete(VACANCY_BY_ID(id), getAuthConfig());
      return {
        code: response.status,
      };
    } catch (err: any) {
      return {
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static publishVacancy = async (id: string) => {
    try {
      const response = await apiClient.put(VACANCY_PUBLISH(id), {}, getAuthConfig());
      return {
        vacancy: response.data as Vacancy,
        code: response.status,
      };
    } catch (err: any) {
      return {
        vacancy: emptyVacancy,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static unpublishVacancy = async (id: string) => {
    try {
      const response = await apiClient.put(VACANCY_UNPUBLISH(id), {}, getAuthConfig());
      return {
        vacancy: response.data as Vacancy,
        code: response.status,
      };
    } catch (err: any) {
      return {
        vacancy: emptyVacancy,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static getLocations = async () => {
    try {
      const response = await apiClient.get(VACANCY_LOCATIONS, getAuthConfig());
      const locations: string[] = response.data;
      return {
        locations,
        code: response.status,
      };
    } catch (err: any) {
      return {
        locations: [],
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };
}
