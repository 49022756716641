import React, { FC, useEffect } from "react";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { getExpenseById } from "../../../../stores/internal/Expense.store";
import "./ExpensesDetails.css";
import { ExpenseDetailsKeyValueItem } from "./ExpenseDetailsKeyValueItem/ExpenseDetailsKeyValueItem";
import { dateToDDMMYYYY } from "../../../../utils/date";
import {
  getSubmissionStatusText,
  getSubmissionStatusTextColor,
} from "../../../../data/common/SubmissionStatus";
import { FileDownloadButton } from "../../../ImageInputs/FileUploadButton/FileDownloadButton/FileDownloadButton";

interface ExpensesDetailsProps {
  expenseId: string;
  navigateToSummary: () => void;
}

export const ExpensesDetails: FC<ExpensesDetailsProps> = ({ expenseId, navigateToSummary }) => {
  const dispatch = useTypedDispatch();
  const { expense } = useTypedSelector((state: RootState) => state.expenses);

  useEffect(() => {
    getExpense();
  }, []);

  const getExpense = async () => {
    await dispatch(getExpenseById({ id: expenseId }));
  };

  const attachmentExists = () => {
    return expense.attachment != undefined && expense.attachment != "";
  };

  return (
    <div className="ExpensesDetails-container" style={{ color: "#000" }}>
      <div className="ExpensesDetails-header" onClick={() => navigateToSummary()}>
        <div className="ExpensesDetails-backButton">
          <img
            src="https://kodly-assets.s3.eu-west-1.amazonaws.com/global/black_arrow.svg"
            className="ExpensesDetails-backArrow"
            alt="backButton"
          />
        </div>
        <div className="ExpensesDetails-title">Expense Detail</div>
      </div>
      <div className="ExpensesDetails-content">
        <div className="ExpensesDetails-firstInputRow">
          <ExpenseDetailsKeyValueItem keyTitle={"WBS Code"} value={expense.wbs.code} />
          <ExpenseDetailsKeyValueItem
            keyTitle={"Date"}
            value={dateToDDMMYYYY(new Date(expense.submitted))}
          />
          <ExpenseDetailsKeyValueItem keyTitle={"Type"} value={expense.expensesType.code} />
          <div style={{ color: getSubmissionStatusTextColor(expense.status) }}>
            <ExpenseDetailsKeyValueItem
              keyTitle={"Status"}
              value={getSubmissionStatusText(expense.status)}
            />
          </div>
        </div>
        <div className="ExpensesDetails-secondInputRow">
          <ExpenseDetailsKeyValueItem
            keyTitle={"Description"}
            value={expense.description}
            maxLines={5}
          />
        </div>
        <div className="ExpensesDetails-thirdInputRow">
          <ExpenseDetailsKeyValueItem keyTitle={"Expense Value"} value={expense.cost + " EUR"} />
          {attachmentExists() && (
            <div className="ExpensesDetails-downloadButton">
              <FileDownloadButton title={"Attachment"} fileURL={expense.attachment} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
