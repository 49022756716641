import React, { FC } from "react";
import { Input } from "../../../../Inputs/Input";
import { InputDate } from "../../../../Inputs/InputDate";
import { ImageInputSquare } from "../../../../ImageInputs/ImageInputSquare/ImageInputSquare";

import "./CaseStudyCreateEditSummary.css";

interface CaseStudyCreateEditSummaryProps {
  formik: any;
}

export const CaseStudyCreateEditSummary: FC<CaseStudyCreateEditSummaryProps> = ({ formik }) => {
  return (
    <div className="CaseStudyCreateEditSummary-container">
      <div className="CaseStudyCreateEditSummary-container_leftSide">
        <ImageInputSquare
          title="Image"
          formik={formik}
          buttonTitle="Upload image"
          image={formik.values.thumbnail}
          error={formik.errors.thumbnail}
          target="thumbnail"
          folder="caseStudies"
          height="14em"
          width="25em"
          acceptType={null}
        />
      </div>

      <div className="CaseStudyCreateEditSummary-container_rightSide">
        <Input
          error={formik.errors.title}
          value={formik.values.title}
          handleChange={formik.handleChange}
          label="Title"
          name="title"
          type="text"
          width="100%"
          height="2rem"
        />
        <Input
          error={formik.errors.subtitle}
          value={formik.values.subtitle}
          handleChange={formik.handleChange}
          label="Subtitle"
          name="subtitle"
          type="text"
          width="100%"
          height="2rem"
        />

        <div className="CaseStudyCreateEditSummary-dateContainer">
          <InputDate
            error={formik.errors.date}
            value={formik.values.date}
            handleChange={formik.handleChange}
            label="Date"
            name="date"
          />
          <div>
            <Input
              error={formik.errors.readTime}
              value={formik.values.readTime}
              handleChange={formik.handleChange}
              label="Read Time"
              name="readTime"
              type="text"
              width="auto"
              height="2rem"
              maxLength={4}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
