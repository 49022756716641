import { Box, FormControl, FormHelperText, MenuItem, Select, Typography } from "@mui/material";
import React, { FC } from "react";

interface ServiceCreateEditProps {
  formik: any;
}

export const ServiceCreateEditButtonDestination: FC<ServiceCreateEditProps> = ({ formik }) => {
  return (
    <Box>
      <Typography variant="body1" sx={{ color: formik.errors.url ? "#d32f2f" : "inherit" }}>
        Destination
      </Typography>
      <FormControl fullWidth variant="standard" error={Boolean(formik.errors.url)}>
        <Select
          variant="standard"
          inputProps={{ disableUnderline: true }}
          onChange={(e) => {
            formik.handleChange(e);
          }}
          value={formik.values.url}
          size="medium"
          name="url"
          sx={{
            backgroundColor: "#C9ECE4",
            padding: "0.5em",
            borderRadius: "24px",
            borderWidth: "0",
            width: "100%",
            "&::before": {
              borderBottom: 0,
            },
            "&::after": {
              borderBottom: 0,
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: 0,
            },
          }}
        >
          <MenuItem value="/blog">Blog</MenuItem>
          <MenuItem value="/case-studies">Case Study</MenuItem>
        </Select>
        <FormHelperText>{formik.errors.url}</FormHelperText>
      </FormControl>
    </Box>
  );
};
