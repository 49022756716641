import { HTTP_CODES } from "../../constants/httpstatus.const";
import { emptyTestimonial } from "../../data/mkt/testimonial";
import { Testimonial } from "../../types/mkt/Testimonial";
import { apiClient, getAuthConfig } from "../HttpClient";

const TESTIMONIAL_URL = "/testimonial";
const TESTIMONIAL_ID = (id: string) => TESTIMONIAL_URL + "/" + id;
const TESTIMONIAL_PUBLISH = (id: string) => `${TESTIMONIAL_URL}/${id}/publish`;
const TESTIMONIAL_UNPUBLISH = (id: string) => `${TESTIMONIAL_URL}/${id}/unpublish`;

export default class TestimonialService {
  static getTestimonials = async () => {
    try {
      const response = await apiClient.get(TESTIMONIAL_URL, getAuthConfig());
      const testimonials: Testimonial[] = response.data;
      return {
        testimonials,
        code: response.status,
      };
    } catch (err: any) {
      return {
        testimonials: [],
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static getTestimonial = async (id: string) => {
    try {
      const response = await apiClient.get(TESTIMONIAL_ID(id), getAuthConfig());
      const testimonial: Testimonial = response.data;
      return {
        testimonial,
        code: response.status,
      };
    } catch (err: any) {
      return {
        testimonial: emptyTestimonial,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static createTestimonial = async (testimonial: Testimonial) => {
    try {
      const response = await apiClient.post(TESTIMONIAL_URL, testimonial, getAuthConfig());
      return {
        testimonial: response.data as Testimonial,
        code: response.status,
      };
    } catch (err: any) {
      return {
        testimonial: emptyTestimonial,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static editTestimonial = async (testimonial: Testimonial) => {
    try {
      const response = await apiClient.put(TESTIMONIAL_URL, testimonial, getAuthConfig());
      return {
        testimonial: response.data as Testimonial,
        code: response.status,
      };
    } catch (err: any) {
      return {
        testimonial: emptyTestimonial,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static deleteTestimonial = async (id: string) => {
    try {
      const response = await apiClient.delete(TESTIMONIAL_ID(id), getAuthConfig());
      return {
        code: response.status,
      };
    } catch (err: any) {
      return {
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static publishTestimonial = async (id: string) => {
    try {
      const response = await apiClient.put(TESTIMONIAL_PUBLISH(id), {}, getAuthConfig());
      return {
        testimonial: response.data as Testimonial,
        code: response.status,
      };
    } catch (err: any) {
      return {
        testimonial: emptyTestimonial,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static unpublishTestimonial = async (id: string) => {
    try {
      const response = await apiClient.put(TESTIMONIAL_UNPUBLISH(id), {}, getAuthConfig());
      return {
        testimonial: response.data as Testimonial,
        code: response.status,
      };
    } catch (err: any) {
      return {
        testimonial: emptyTestimonial,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };
}
