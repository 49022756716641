import React, { FC, useEffect, useState } from "react";
import { CaseStudyDnD } from "../../../components/mkt/CaseStudy/CaseStudyLists/CaseStudyDnD/CaseStudyDnD";
import { CaseStudyGrid } from "../../../components/mkt/CaseStudy/CaseStudyLists/CaseStudyGrid/CaseStudyGrid";
import { Page } from "../../../components/mkt/Global/Pages/Page";
import { pages } from "../../../data/mkt/pageContent";
import { editCaseStudy, getCaseStudies } from "../../../stores/mkt/CaseStudy.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../stores/store";

export const CaseStudiesPage: FC = () => {
  const dispatch = useTypedDispatch();
  const { caseStudies, loading, error } = useTypedSelector((state: RootState) => state.caseStudies);
  const { token } = useTypedSelector((state: RootState) => state.auth);
  const [dndEnabled, setDndEnabled] = useState<boolean>(false);
  const [loaded, setLoaded] = useState(false);

  const dndAction = () => {
    for (let index = 0; index < caseStudies.length; index++) {
      dispatch(editCaseStudy({ caseStudy: { ...caseStudies[index], order: index } }));
    }
    setDndEnabled(false);
  };

  const fetchCaseStudy = async () => {
    await dispatch(getCaseStudies()).then(() => {
      setLoaded(true);
    });
  };

  async function cancelDndAction() {
    setDndEnabled(false);
    setLoaded(false);
    fetchCaseStudy();
  }

  useEffect(() => {
    fetchCaseStudy();
  }, []);

  return (
    <Page
      pageContent={pages.caseStudies}
      search={(term) => {}}
      dndEnabled={dndEnabled}
      dndAction={dndAction}
      cancelDndAction={cancelDndAction}
      setDndEnabled={setDndEnabled}
    >
      {loaded ? (
        dndEnabled ? (
          <CaseStudyDnD caseStudies={caseStudies} />
        ) : (
          <CaseStudyGrid caseStudies={caseStudies} />
        )
      ) : null}
    </Page>
  );
};
