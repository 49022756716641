import React, { FC } from "react";
import { Testimonial } from "../../../../../../types/mkt/Testimonial";
import { TestimonialCard } from "../../TestimonialCard/TestimonialCard";

interface TestimonialDnDSingleProps {
  testimonial: Testimonial;
}

export const TestimonialDnDSingle: FC<TestimonialDnDSingleProps> = ({ testimonial }) => {
  return (
    <div>
      <TestimonialCard
        testimonials={testimonial}
        editAction={null}
        publishAction={null}
        deleteAction={null}
        hoverEffectEnabled={false}
      />
    </div>
  );
};
