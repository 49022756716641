import { Grid } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Page } from "../../../components/mkt/Global/Pages/Page";
import { pages } from "../../../data/mkt/pageContent";
import {
  editTeamMember,
  getTeamMembers,
  deleteTeamMember,
  publishTeamMember,
} from "../../../stores/mkt/TeamMember.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../stores/store";
import { TeamMemberGrid } from "../../../components/mkt/TeamMember/TeamMemberLists/TeamMemberGrid/TeamMemberGrid";
import { TeamMemberDnD } from "../../../components/mkt/TeamMember/TeamMemberLists/TeamMemberDnD/TeamMemberDnD";
import { useNavigate } from "react-router-dom";

export const TeamMembersPage: FC = observer(() => {
  const dispatch = useTypedDispatch();
  const { teamMembers, loading, error, deleted } = useTypedSelector(
    (state: RootState) => state.teamMembers
  );
  const [dndEnabled, setDndEnabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  const dndAction = () => {
    for (let index = 0; index < teamMembers.length; index++) {
      dispatch(
        editTeamMember({
          teamMember: { ...teamMembers[index], order: index },
        })
      );
    }
    setDndEnabled(false);
  };

  const fetchTeamMember = async () => {
    await dispatch(getTeamMembers()).then(() => {
      setLoaded(true);
    });
  };

  async function cancelDndAction() {
    setDndEnabled(false);
    setLoaded(false);
    fetchTeamMember();
  }

  useEffect(() => {
    fetchTeamMember();
  }, [deleted]);

  function editAction(id: string) {
    navigate(`/team_members/${id}/edit`);
  }

  async function publishAction(id: string, publish: boolean) {
    await dispatch(publishTeamMember({ id: id!!, publish: publish }));
    dispatch(getTeamMembers());
  }

  async function deleteAction(id: string) {
    await dispatch(deleteTeamMember({ id: id!! }));
  }

  return (
    <Page
      pageContent={pages.teamMembers}
      search={(term) => {}}
      dndEnabled={dndEnabled}
      dndAction={dndAction}
      cancelDndAction={cancelDndAction}
      setDndEnabled={setDndEnabled}
    >
      {loaded ? (
        dndEnabled ? (
          <TeamMemberDnD teamMembers={teamMembers} />
        ) : (
          <TeamMemberGrid
            teamMembers={teamMembers}
            editAction={editAction}
            publishAction={publishAction}
            deleteAction={deleteAction}
          />
        )
      ) : null}
    </Page>
  );
});
