import React, { FC } from "react";
import { TeamMember } from "../../../../../../types/mkt/TeamMember";
import { TeamMemberCard } from "../../TeamMemberCard/TeamMemberCard";

interface TeamMemberDnDSingleProps {
  teamMember: TeamMember;
}

export const TeamMemberDnDSingle: FC<TeamMemberDnDSingleProps> = ({ teamMember }) => {
  return (
    <div>
      <TeamMemberCard
        teamMember={teamMember}
        editAction={null}
        publishAction={null}
        deleteAction={null}
        hoverEffectEnabled={false}
      />
      <br></br>
    </div>
  );
};
