import React, { FC, useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { ArrowBack, Visibility, VisibilityOff, Delete, Edit } from "@mui/icons-material";
import "./ImageOnlyCard.css";
import { boolean } from "yup";
import { ButtonsCard } from "../../ButtonsCard/ButtonsCard";

interface ImageOnlyCardProps {
  id: string;
  imageURL: string;
  publish: boolean;
  registerPublish: any;
  registerDelete: any;
  enableHover: boolean;
  duplicateAction: any;
}

export const ImageOnlyCard: FC<ImageOnlyCardProps> = ({
  id,
  imageURL,
  publish,
  registerPublish,
  registerDelete,
  enableHover,
  duplicateAction,
}) => {
  const [hooverActive, setHooverActive] = useState(false);

  return (
    <div
      style={{
        backgroundImage: `url(${imageURL})`,
        borderColor: hooverActive ? "#fff" : "#66cdb5",
        borderWidth: hooverActive ? "0.20em" : "0.25em",
      }}
      className="ImageOnlyCard-container"
      onMouseEnter={() => {
        setHooverActive(enableHover);
      }}
      onMouseLeave={() => {
        setHooverActive(false);
      }}
    >
      <div className={publish ? "ImageOnlyCard-isPublished" : "ImageOnlyCard-isNotPublished"}>
        {publish ? "published" : "unpublished"}
      </div>{" "}
      <ButtonsCard
        id={id}
        publish={publish}
        editAction={null}
        publishAction={registerPublish}
        deleteAction={registerDelete}
        duplicateAction={duplicateAction}
      />
    </div>
  );
};
