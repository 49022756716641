import { useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BlogPostCreateEdit } from "../../../../components/mkt/BlogPost/BlogPostCreateEdit/BlogPostCreateEdit";
import { PageCreate } from "../../../../components/mkt/Global/Pages/PageCreate";
import { emptyBlogPost } from "../../../../data/mkt/blogpost";
import { createBlogPost, setCreated } from "../../../../stores/mkt/BlogPost.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { blogPostValidationSchema } from "../../../../utils/mkt/blogPost";

export const BlogPostCreatePage: FC = () => {
  const dispatch = useTypedDispatch();
  const { created } = useTypedSelector((state: RootState) => state.blogPosts);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    if (created) {
      dispatch(setCreated(false));
      navigate("/blog_posts");
    }
  }, [created]);

  const swithTabAction = (index: number) => {
    setActiveTab(index);
  };

  const createAction = async () => {
    formik.errors = await formik.validateForm(formik.values);

    if (Object.keys(formik.errors).length > 0) {
      return;
    }

    dispatch(createBlogPost({ blogPost: formik.values }));
  };

  const formik = useFormik({
    initialValues: { ...emptyBlogPost, order: 100 },
    onSubmit: (userInputData) => {},
    validationSchema: blogPostValidationSchema,
  });

  return (
    <div className="BlogPostCreatePage-pageCreateDiv">
      <PageCreate
        pageContent={{
          pageTitle: "Create Blog Post",
          previousPage: "/blog_posts",
          action: createAction,
          type: "Create",
          tabs: ["Summary", "Author", "Categories", "Content"], //["Summary", "Author", "Categories", "Content", "SEO"]
          switchTabAction: swithTabAction,
        }}
      >
        <BlogPostCreateEdit formik={formik} activeTab={activeTab} />
      </PageCreate>
    </div>
  );
};
