import { Vacancy } from "../../types/mkt/Vacancy";

export const emptyVacancy: Vacancy = {
  id: "",
  order: 0,
  role: "",
  type: "",
  location: "",
  publish: false,
  skills: "",
  benefits: "",
  responsibilities: "",
  officeMode: "",
  url: "",
  metaInfo: "",
  pageTitle: "",
  h1: "",
  h2: "",
};
