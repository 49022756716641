import { AnyAction, configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import careersReducer from "./mkt/Career.store";
import authReducer from "./Authentication.store";
import sidebarReducer from "./Sidebar.store";
import servicesReducer from "./mkt/Service.store";
import imageReducer from "./Image.store";
import blogPostsReducer from "./mkt/BlogPost.store";
import teamMemberReducer from "./mkt/TeamMember.store";
import caseStudyReducer from "./mkt/CaseStudy.store";
import testimonialReducer from "./mkt/Testimonial.store";
import clientReducer from "./mkt/Client.store";
import userReducer from "./internal/User.store";
import categoryReducer from "./mkt/Category.store";
import timeReportReducer from "./internal/TimeReport.store";
import wbsReducer from "./common/WBS.store";
import curriculumVitaeReducer from "./internal/CV.store";
import expensesReducer from "./internal/Expense.store";
import vacationReducer from "./internal/Vacation.store";
import approverReducer from "./hr/Approver.store";

const reducers = combineReducers({
  careers: careersReducer,
  auth: authReducer,
  sidebar: sidebarReducer,
  services: servicesReducer,
  image: imageReducer,
  blogPosts: blogPostsReducer,
  teamMembers: teamMemberReducer,
  caseStudies: caseStudyReducer,
  testimonials: testimonialReducer,
  clients: clientReducer,
  user: userReducer,
  category: categoryReducer,
  timeReport: timeReportReducer,
  wbs: wbsReducer,
  vacation: vacationReducer,
  CV: curriculumVitaeReducer,
  expenses: expensesReducer,
  approvers: approverReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof reducers>;
export type TypedDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
export const useTypedDispatch = () => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
