import { Pages } from "../../types/mkt/PageContent";

export const pages: Pages = {
  careers: {
    pageTitle: "Careers",
    buttonTitle: "Add Vacancy",
    buttonUrl: "/careers/vacancy/create",
  },
  services: {
    pageTitle: "Services",
    buttonTitle: "Add Service",
    buttonUrl: "/services/create",
  },
  teamMembers: {
    pageTitle: "Team Members",
    buttonTitle: "Add Team Member",
    buttonUrl: "/team_members/create",
  },
  homepage: {
    pageTitle: "Home",
    buttonTitle: null,
    buttonUrl: null,
  },
  blogPosts: {
    pageTitle: "Blog Posts",
    buttonTitle: "Add Blog Post",
    buttonUrl: "/blog_posts/create",
  },
  caseStudies: {
    pageTitle: "Case Studies",
    buttonTitle: "Add Case Study",
    buttonUrl: "/case_studies/create",
  },
  testimonials: {
    pageTitle: "Testimonials",
    buttonTitle: "Add Testimonial",
    buttonUrl: "/testimonials/create",
  },
  clients: {
    pageTitle: "Clients",
    buttonTitle: "Add Client",
    buttonUrl: "/clients/create",
  },
};
