import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export const Loading: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </div>
  );
};
