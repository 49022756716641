import React, { FC, useState, useEffect } from "react";
import { ImageInputSquare } from "../../../../ImageInputs/ImageInputSquare/ImageInputSquare";

interface ServiceCreateEditUploadIconProps {
  formik: any;
}

export const ServiceCreateEditUploadIcon: FC<ServiceCreateEditUploadIconProps> = ({ formik }) => {
  return (
    <ImageInputSquare
      title="Image"
      formik={formik}
      buttonTitle="Upload icon"
      image={formik.values.icon}
      error={formik.errors.icon}
      target="icon"
      folder="caseStudies"
      height="10em"
      width="10em"
      acceptType={"application/JSON"}
    />
  );
};
