import React, { FC, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { RootState, useTypedSelector } from "../../../../../../../stores/store";
import { WBS } from "../../../../../../../types/common/wbs/WBS";
import { emptyWBS } from "../../../../../../../data/common/wbs";
import { VACATION_WBS_ID } from "../../../../../../../constants/internal/vacations.const";

interface SearchWBSProps {
  handleChangeWbs?: (wbs: any, index: number) => void;
  index: number;
  disabled: boolean;
  wbs: WBS | null;
}

export const SearchWBS: FC<SearchWBSProps> = ({ handleChangeWbs, index, disabled, wbs }) => {
  const { wbsAll } = useTypedSelector((state: RootState) => state.wbs);
  const [selectedWBS, setSelectedWBS] = useState<String | null>(null);

  useEffect(() => {
    setInitialSelectedWBSIfAny();
  }, []);

  useEffect(() => {
    setInitialSelectedWBSIfAny();
  }, [wbs]);

  const setInitialSelectedWBSIfAny = () => {
    if (wbs?.code != undefined && wbs?.code != "") {
      setSelectedWBS(wbs.code);
    }
  };

  const createWBSOptions = () => {
    const options: string[] = [];
    wbsAll.forEach((element: WBS) => {
      if (element.active && element.id != VACATION_WBS_ID) {
        options.push(element.code);
      }
    });
    return options;
  };

  const onChange = (event: any, values: any) => {
    if (handleChangeWbs == undefined) {
      return;
    }
    setSelectedWBS(values);
    if (values == null) {
      const wbs: WBS = emptyWBS;
      handleChangeWbs(wbs, index);
    } else {
      wbsAll.forEach((wbs) => {
        if (values.startsWith(wbs.code)) {
          handleChangeWbs(wbs, index);
        }
      });
    }
  };

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={createWBSOptions()}
      sx={{ width: 200 }}
      onChange={onChange}
      renderInput={(params) => <TextField {...params} placeholder="Search..." />}
      disabled={disabled}
      value={selectedWBS}
    />
  );
};
