import { Box } from "@mui/material";
import React, { FC } from "react";
import { Challenge } from "../../../../../types/mkt/Challenge";
import { renderHtml } from "../../../../../utils/renderHtml";
import "./CaseStudyDetailsChallenge.css";

interface CaseStudyDetailsChallengeProps {
  challenge: Challenge;
}

export const CaseStudyDetailsChallenge: FC<CaseStudyDetailsChallengeProps> = ({ challenge }) => {
  return (
    <div className="CaseStudyDetails-container">
      <h2>
        The <span>Challenge</span>
      </h2>
      <div className="CaseStudyDetailsChallenge-content">
        <div className="CaseStudyDetailsChallenge-title">
          <h3>{challenge.title}</h3>
        </div>
        <p
          className="CaseStudyDetailsChallenge-bodyText"
          dangerouslySetInnerHTML={renderHtml(challenge.description)}
        />
      </div>
    </div>
  );
};
