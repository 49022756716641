import React, { FC } from "react";
import { CaseStudyCreateEditSummary } from "./CaseStudyCreateEditSummary/CaseStudyCreateEditSummary";
import { CaseStudyCreateEditChallenge } from "./CaseStudyCreateEditChallenge/CaseStudyCreateEditChallenge";
import { CaseStudyCreateEditSolution } from "./CaseStudyCreateEditSolution/CaseStudyCreateEditSolution";
import { CaseStudyCreateEditResults } from "./CaseStudyCreateEditResults/CaseStudyCreateEditResults";
import { PageCreateEditSEO } from "../../Global/Pages/PageCreateEditSEO";

interface CaseStudyCreateEditProps {
  formik: any;
  activeTab: number;
}

export const CaseStudyCreateEdit: FC<CaseStudyCreateEditProps> = ({ formik, activeTab }) => {
  const handleItemChange = (value: string[]) => {
    const e = { target: { value: value, name: "results" } };
    formik.handleChange(e);
  };

  const renderTab = (index: number) => {
    switch (index) {
      case 0:
        return <CaseStudyCreateEditSummary formik={formik} />;
      case 1:
        return <CaseStudyCreateEditChallenge formik={formik} />;
      case 2:
        return <CaseStudyCreateEditSolution formik={formik} />;
      case 3:
        return <CaseStudyCreateEditResults formik={formik} />;
      case 4:
        return <PageCreateEditSEO formik={formik} />;
    }
  };

  return <div>{renderTab(activeTab)}</div>;
};
