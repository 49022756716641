import React, { FC } from "react";
import { Box } from "@mui/material";
import { VacationsSummaryTable } from "./VacationsSummaryTable/VacationsSummaryTable";
import { VacationsSummaryCount } from "./VacationsSummaryCount/VacationsSummaryCount";
import { Vacation } from "../../../../types/internal/vacation/Vacation";
import { VacationPaginated } from "../../../../types/internal/vacation/VacationPaginated";

interface VacationsSummaryProps {
  vacationsPaginated: VacationPaginated;
  vacationsForCurrentYear: Vacation[];
  assignedVacationDays: number;
  tablePage: number;
  navigateToCreateVacation: () => void;
  onPaginationChange: (newPage: number) => void;
}

export const VacationsSummary: FC<VacationsSummaryProps> = ({
  vacationsPaginated,
  vacationsForCurrentYear,
  assignedVacationDays,
  tablePage,
  navigateToCreateVacation,
  onPaginationChange,
}) => {
  return (
    <Box>
      <Box sx={{ backgroundColor: "#ffffff" }} height="66vh">
        <Box>
          <Box paddingBottom="2em" paddingLeft="2em" paddingRight="2em">
            <VacationsSummaryCount
              assignedVacationDays={assignedVacationDays}
              vacationsForCurrentYear={vacationsForCurrentYear}
              navigateToCreateVacation={navigateToCreateVacation}
            />
          </Box>
        </Box>
        <Box paddingTop="2em">
          <VacationsSummaryTable
            vacationsPaginated={vacationsPaginated}
            onPaginationChange={onPaginationChange}
            tablePage={tablePage}
          />
        </Box>
      </Box>
    </Box>
  );
};
