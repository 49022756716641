import { createAsyncThunk, createSlice, Slice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import AuthService from "../services/Auth.service";
import { DecodedJWT } from "../types/mkt/Auth";
import { AuthState } from "../types/mkt/AuthState";
import { SEC_TO_MS_MULTIPLIER } from "../utils/date";

const authSlice: Slice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    error: null,
    token: null,
    name: "",
    email: "",
  } as AuthState,
  reducers: {
    logout(state, action) {
      state.name = "";
      state.email = "";
      state.token = null;
      AuthService.removeToken();
    },
    checkToken(state, action) {
      if (!state.token) {
        return;
      }
      const decoded: DecodedJWT = jwtDecode(state.token);
      if (new Date().getTime() >= decoded.exp * SEC_TO_MS_MULTIPLIER) {
        state.name = "";
        state.email = "";
        state.token = null;
        AuthService.removeToken();
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signup.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(signup.fulfilled, (state, action) => {
      if (state.loading === true) {
        const decoded: DecodedJWT = jwtDecode(action.payload!!);
        state.name = decoded.name;
        state.email = decoded.email;
        state.token = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(signup.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

export const signup = createAsyncThunk("auth/signup", async (token: string) => {
  const response = await AuthService.signup(token);
  return response.token;
});

export const { logout, checkToken } = authSlice.actions;

export default authSlice.reducer;
