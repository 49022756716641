import { Box } from "@mui/material";
import React, { FC } from "react";
import { Vacancy } from "../../../../../types/mkt/Vacancy";
import { VacancyCard } from "../VacancyCard/VacancyCard";

import "./VacancyGrid.css";

interface VacanciesProps {
  vacancies: Vacancy[];
  editAction: any;
  publishAction: any;
  deleteAction: any;
  duplicateAction: any;
}

export const VacancyGrid: FC<VacanciesProps> = ({
  vacancies,
  editAction,
  publishAction,
  deleteAction,
  duplicateAction,
}) => {
  return (
    <Box className="VacancyGrid-container">
      {vacancies.map((vacancy, index) => (
        <div className="VacancyGrid-link" key={index}>
          <VacancyCard
            icon={true}
            vacancy={vacancy}
            editAction={editAction}
            publishAction={publishAction}
            deleteAction={deleteAction}
            duplicateAction={duplicateAction}
          />
        </div>
      ))}
    </Box>
  );
};
