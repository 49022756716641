import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CaseStudyDetails } from "../../../../components/mkt/CaseStudy/CaseStudyDetails/CaseStudyDetails";
import { PageDetails } from "../../../../components/mkt/Global/Pages/PageDetails";
import {
  deleteCaseStudy,
  getCaseStudy,
  publishCaseStudy,
  setDeleted,
} from "../../../../stores/mkt/CaseStudy.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { PageDetailsContent } from "../../../../types/mkt/PageContent";
import "./CaseStudyDetailsPage.css";

export const CaseStudyDetailsPage: FC = () => {
  const dispatch = useTypedDispatch();
  const { caseStudy, loading, error, deleted } = useTypedSelector(
    (state: RootState) => state.caseStudies
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchCaseStudy = async () => {
      await dispatch(getCaseStudy({ id: id!! })).then(() => {
        setLoaded(true);
      });
    };
    // call the function
    fetchCaseStudy();
  }, []);

  useEffect(() => {
    setPageContent({
      pageTitle: "",
      isPublish: caseStudy.publish,
      previousPage: "/case_studies",
      publishAction: publishAction,
      deleteAction: deleteAction,
      editAction: editAction,
    });
  }, [caseStudy]);

  const editAction = () => {
    navigate(`/case_studies/${caseStudy.id}/edit`);
  };

  const publishAction = () => {
    dispatch(publishCaseStudy({ id: id!!, publish: caseStudy.publish }));
  };

  const deleteAction = () => {
    dispatch(deleteCaseStudy({ id: id!! }));
  };

  const [pageContent, setPageContent] = useState<PageDetailsContent>({
    pageTitle: "",
    isPublish: caseStudy.publish,
    previousPage: "/case_studies",
    publishAction: publishAction,
    deleteAction: deleteAction,
    editAction: editAction,
  });

  useEffect(() => {
    if (deleted) {
      dispatch(setDeleted(false));
      navigate("/case_studies");
    }
  }, [deleted]);

  return (
    <PageDetails pageContent={pageContent}>
      <>
        {loaded ? (
          <CaseStudyDetails
            title={caseStudy.title}
            subtitle={caseStudy.subtitle}
            image={caseStudy.thumbnail}
            date={caseStudy.date}
            readTime={caseStudy.readTime}
            challenge={caseStudy.challenge}
            solution={caseStudy.solution}
            results={caseStudy.results}
          />
        ) : null}
      </>
    </PageDetails>
  );
};
