import React, { FC } from "react";
import { CVPersonalInfo } from "./CVPersonalInfo/CVPersonalInfo";
import { CVTechnologyStack } from "./CVTechnologyStack/CVTechnologyStack";
import { CVProfessionalExperiences } from "./CVProfessionalExperiences/CVProfessionalExperiences";
import { CVEducationCertifications } from "./CVEducationCertifications/CVEducationCertifications";
import { CVLanguages } from "./CVLanguages/CVLanguages";
import { Box } from "@mui/material";
import "./CV.css";
import { observer } from "mobx-react-lite";
interface CVProps {
  activeTab: number;
  formik: any;
}

export const CVComponent: FC<CVProps> = observer(({ activeTab, formik }) => {
  const renderTab = (index: number) => {
    switch (index) {
      case 0:
        return <CVPersonalInfo formik={formik} />;
      case 1:
        return <CVTechnologyStack formik={formik} />;
      case 2:
        return <CVProfessionalExperiences formik={formik} />;
      case 3:
        return <CVEducationCertifications formik={formik} />;
      case 4:
        return <CVLanguages formik={formik} />;
    }
  };

  return (
    <div>
      <Box className="CV-container">{renderTab(activeTab)}</Box>
    </div>
  );
});
