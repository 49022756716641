import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { emptyTeamMember } from "../../data/mkt/teamMember";
import TeamMemberService from "../../services/mkt/TeamMember.service";
import { TeamMemberState } from "../../types/mkt/TeamMemberState";
import { TeamMember } from "../../types/mkt/TeamMember";

const teamMemberSlice = createSlice({
  name: "teamMembers",
  initialState: {
    loading: false,
    error: null,
    teamMembers: [],
    teamMember: emptyTeamMember,
    created: false,
    edited: false,
    deleted: false,
  } as TeamMemberState,
  reducers: {
    setCreated(state, action) {
      state.created = action.payload;
    },
    setEdited(state, action) {
      state.edited = action.payload;
    },
    setDeleted(state, action) {
      state.deleted = action.payload;
    },
    setTeamMembers(state, action) {
      state.teamMembers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTeamMembers.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getTeamMembers.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.teamMembers = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getTeamMembers.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(getTeamMember.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getTeamMember.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.teamMember = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getTeamMember.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(createTeamMember.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.created = false;
      }
    });
    builder.addCase(createTeamMember.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.created = true;
      }
    });
    builder.addCase(createTeamMember.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(editTeamMember.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.edited = false;
      }
    });
    builder.addCase(editTeamMember.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.edited = true;
      }
    });
    builder.addCase(editTeamMember.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(deleteTeamMember.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.deleted = false;
      }
    });
    builder.addCase(deleteTeamMember.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.deleted = true;
      }
    });
    builder.addCase(deleteTeamMember.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(publishTeamMember.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.deleted = false;
      }
    });
    builder.addCase(publishTeamMember.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.teamMember = action.payload;
      }
    });
    builder.addCase(publishTeamMember.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

export const getTeamMembers = createAsyncThunk("teamMembers/getTeamMembers", async () => {
  const response = await TeamMemberService.getTeamMembers();
  return response.teamMembers.sort((a, b) => a.order - b.order);
});

export const getTeamMember = createAsyncThunk(
  "teamMembers/teamMember/getById",
  async (teamMember: { id: string }) => {
    const response = await TeamMemberService.getTeamMember(teamMember.id);
    return response.teamMember;
  }
);

export const createTeamMember = createAsyncThunk(
  "teamMembers/teamMember/create",
  async (data: { teamMember: TeamMember }) => {
    const response = await TeamMemberService.createTeamMember(data.teamMember);
    return response.teamMember;
  }
);

export const editTeamMember = createAsyncThunk(
  "teamMembers/teamMember/edit",
  async (data: { teamMember: TeamMember }) => {
    const response = await TeamMemberService.editTeamMember(data.teamMember);
    return response.teamMember;
  }
);

export const deleteTeamMember = createAsyncThunk(
  "teamMembers/teamMember/delete",
  async (data: { id: string }) => {
    await TeamMemberService.deleteTeamMember(data.id);
    return;
  }
);

export const publishTeamMember = createAsyncThunk(
  "teamMembers/teamMember/publish",
  async (data: { id: string; publish: boolean }) => {
    const response = data.publish
      ? await TeamMemberService.unpublishTeamMember(data.id)
      : await TeamMemberService.publishTeamMember(data.id);
    return response.teamMember;
  }
);

export default teamMemberSlice.reducer;
export const { setCreated, setEdited, setDeleted, setTeamMembers } = teamMemberSlice.actions;
