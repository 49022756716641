import { Box, InputAdornment, TextField, Tooltip, IconButton } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { FC, ReactElement } from "react";
import { Sidebar } from "../Sidebar/Sidebar";
import { TopBar } from "../../../TopBar/TopBar";
import AddIcon from "@mui/icons-material/Add";
import { PageContent } from "../../../../types/mkt/PageContent";
import { RootState, useTypedSelector } from "../../../../stores/store";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ReorderIcon from "@mui/icons-material/Reorder";
import "./Page.css";
import { renderHtml } from "../../../../utils/renderHtml";
import { ListItemIcon } from "@mui/material";
import { KodlyIcon } from "../../../../assets/svg/KodlyIcon";

interface PageProps {
  children: ReactElement | null;
  pageContent: PageContent;
  search: (term: string) => void;
  dndEnabled: boolean;
  dndAction: () => void;
  setDndEnabled: (dndEnabled: boolean) => void;
  cancelDndAction: () => void;
}

export const Page: FC<PageProps> = observer(
  ({ children, pageContent, search, dndEnabled, dndAction, cancelDndAction, setDndEnabled }) => {
    const { open } = useTypedSelector((state: RootState) => state.sidebar);
    const navigate = useNavigate();

    return (
      <Box display="flex" sx={{ height: "100vh" }}>
        <Box sx={{ height: "100vh", width: open ? "225px" : "75px" }}>
          <div className="Page-kodlyIcon">
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "flex-start",
                px: 2,
              }}
            >
              {open ? (
                <img
                  className="Page-kodlyIconImg"
                  src="https://kodly-assets.s3.eu-west-1.amazonaws.com/global/kodly.png"
                  alt="Logo"
                  width="175px"
                  onClick={() => navigate("/")}
                />
              ) : (
                <div className="Page-kodlyIconImg" onClick={() => navigate("/")}>
                  <KodlyIcon />
                </div>
              )}
            </ListItemIcon>
          </div>
          <Sidebar />
        </Box>
        <Box
          sx={{
            width: open ? "calc(100% - 225px)" : "calc(100% - 75px)",
            backgroundColor: "#F5F5F5",
          }}
        >
          <TopBar>
            <h2
              className="Page-title"
              dangerouslySetInnerHTML={renderHtml(pageContent.pageTitle)}
            ></h2>

            <Box sx={{ display: { xs: "flex" } }} alignItems="center">
              {dndEnabled ? (
                <>
                  <Tooltip title="Cancel" color="error" sx={{ marginRight: "0.5em" }}>
                    <IconButton onClick={() => cancelDndAction()}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Confirm" sx={{ color: "#00b088", marginRight: "3em" }}>
                    <IconButton onClick={() => dndAction()}>
                      <CheckCircleIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  {pageContent.buttonTitle && pageContent.buttonUrl && (
                    <>
                      <Tooltip title="Change Order" sx={{ color: "#00b088", marginRight: "0.5em" }}>
                        <IconButton onClick={() => setDndEnabled(true)}>
                          <ReorderIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={pageContent.buttonTitle}
                        sx={{ marginRight: "2.9em", color: "#00b088" }}
                      >
                        <IconButton onClick={() => navigate(pageContent.buttonUrl!!)}>
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </>
              )}
            </Box>
          </TopBar>
          <Box
            sx={{
              padding: "2em 4em",
              height: "calc(100vh - (92px + 4em))",
              overflowY: "auto",
              boxSizing: "content-box",
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    );
  }
);
