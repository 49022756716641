import { apiClient, getAuthConfig } from "../HttpClient";
import { HTTP_CODES } from "../../constants/httpstatus.const";
import { Approver } from "../../types/hr/Approver";

const APPROVER_URL = (email: string) => "/employee/" + email + "/approvers";

export default class ApproverService {
  static getApprovers = async (email: string) => {
    try {
      const response = await apiClient.get(APPROVER_URL(email), getAuthConfig());
      const approvers: Approver[] = response.data;
      return {
        approvers,
        code: response.status,
      };
    } catch (err: any) {
      return {
        approvers: [],
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };
}
