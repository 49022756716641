import * as yup from "yup";
import { REGEX } from "../../constants/regex.const";

const experienceValidationSchema = yup.object({
  companyName: yup
    .string()
    .required("Company name is required")
    .matches(REGEX.TEXT_WITH_NUMBERS_NO_LATIN_CHARS, "Some of the characters are not allowed"),
  professionalTitle: yup
    .string()
    .required("Company name is required")
    .matches(REGEX.TEXT_WITH_NUMBERS_NO_LATIN_CHARS, "Some of the characters are not allowed"),
  startDate: yup.date().required("Date is required"),
  endDate: yup.date().when("actualProject", {
    is: false,
    then: yup
      .date()
      .required("Date is required")
      .when(
        "startDate",
        (startDate, yup) => startDate && yup.min(startDate, "End date must come after start date")
      ),
    otherwise: yup.date(),
  }),
  description: yup
    .string()
    .optional()
    .matches(REGEX.TEXT_WITH_NUMBERS_NO_LATIN_CHARS, "Some of the characters are not allowed"),
  technologiesUsed: yup
    .string()
    .optional()
    .matches(REGEX.TEXT_WITH_NUMBERS_NO_LATIN_CHARS, "Some of the characters are not allowed"),
});

const certificationsValidationSchema = yup.object({
  institutionName: yup
    .string()
    .required("Company name is required")
    .matches(REGEX.TEXT_WITH_NUMBERS_NO_LATIN_CHARS, "Some of the characters are not allowed"),
  degree: yup
    .string()
    .required("Company name is required")
    .matches(REGEX.TEXT_WITH_NUMBERS_NO_LATIN_CHARS, "Some of the characters are not allowed"),
  area: yup
    .string()
    .required("Area is required")
    .matches(REGEX.TEXT_WITH_NUMBERS_NO_LATIN_CHARS, "Some of the characters are not allowed"),
  startDate: yup.date().required("Date is required"),
  endDate: yup
    .date()
    .required("Date is mandatory")
    .when(
      "startDate",
      (startDate, yup) => startDate && yup.min(startDate, "End date must come after start date")
    ),
});

const techStackValidationSchema = yup.object({
  technologyArea: yup
    .string()
    .required("Area is required")
    .matches(REGEX.TEXT_WITH_NUMBERS_NO_LATIN_CHARS, "Some of the characters are not allowed"),
  technologies: yup
    .string()
    .required("Technologies are required")
    .matches(REGEX.TEXT_WITH_NUMBERS_NO_LATIN_CHARS, "Some of the characters are not allowed"),
});

export const cvValidationSchema = yup.object({
  personalInfo: yup.object({
    image: yup.string().optional(),
    firstName: yup
      .string()
      .matches(REGEX.MULTIPLE_WORDS_LATIN_CHARS, "Some of the characters are not allowed")
      .strict()
      .required("First name is required"),
    lastName: yup
      .string()
      .matches(REGEX.MULTIPLE_WORDS_LATIN_CHARS, "Some of the characters are not allowed")
      .strict()
      .required("Last name is required"),
    professionalTitle: yup
      .string()
      .matches(REGEX.TEXT_WITH_NUMBERS_NO_LATIN_CHARS, "Some of the characters are not allowed")
      .strict()
      .required("Professional Title is required"),
    summary: yup
      .string()
      .strict()
      .optional()
      .matches(REGEX.TEXT_WITH_NUMBERS_NO_LATIN_CHARS, "Some of the characters are not allowed"),
  }),
  experience: yup.array().of(experienceValidationSchema).optional(),
  certifications: yup.array().of(certificationsValidationSchema).optional(),
  techStack: yup.array().of(techStackValidationSchema).optional(),
  interest: yup.object({
    description: yup
      .string()
      .optional()
      .matches(REGEX.TEXT_WITH_NUMBERS_NO_LATIN_CHARS, "Some of the characters are not allowed"),
  }),
});
