import React, { FC } from "react";
import { HtmlInput } from "../../../../../../Inputs/HtmlInput";
import "./VacancySkillsCreate.css";

interface VacancySkillsCreateProps {
  formik: any;
}

export const VacancySkillsCreate: FC<VacancySkillsCreateProps> = ({ formik }) => {
  return (
    <div className="VacancySkillsCreate-skillsInput">
      <HtmlInput
        error={formik.errors && formik.errors.skills ? formik.errors.skills : ""}
        value={formik.values.skills}
        handleChange={formik.handleChange}
        label="Skills & Experience"
        name="skills"
      />
    </div>
  );
};
