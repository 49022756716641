import { Box, FormControl, FormHelperText, Typography } from "@mui/material";
import React, { FC } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./HtmlInput.css";

interface HtmlInputProps {
  value: string;
  error: string;
  handleChange: (e: any) => void;
  name: string;
  label: string;
}

export const HtmlInput: FC<HtmlInputProps> = ({ value, error, handleChange, name, label }) => {
  const onChange = (newContent: string) => {
    handleChange({ target: { value: newContent, name: name }, type: "change" });
  };

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ font: [] }],
      [{ align: [] }],
      ["image"],
      ["clean"], // remove formatting button
    ],
  };

  const formats = [
    "header",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "script",
    "link",
    "direction",
    "background",
    "font",
    "align",
    "image",
    "code-block",
    "clean",
  ];

  return (
    <Box>
      <Typography variant="body1" sx={{ color: error ? "#d32f2f" : "inherit" }}>
        {label}
      </Typography>
      <FormControl fullWidth variant="standard" error={Boolean(error)}>
        <ReactQuill
          className="HtmlInput-reactQuill"
          theme="snow"
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
        />
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </Box>
  );
};
