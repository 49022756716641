import { Store } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { HTTP_CODES } from "../constants/httpstatus.const";
import { logout } from "../stores/Authentication.store";
import AuthService from "./Auth.service";

export const API_URL = process.env.REACT_APP_API_ROOT ? `${process.env.REACT_APP_API_ROOT}` : "";

export const apiClient = axios.create({
  baseURL: API_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
});

export const setupInterceptors = (store: Store) => {
  apiClient.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err.response.status === HTTP_CODES.FORBIDDEN) {
        const { dispatch } = store;
        dispatch(logout({}));
      }
      return Promise.reject(err);
    }
  );
};

export const getAuthConfig = (): AxiosRequestConfig => {
  return {
    headers: {
      Authorization: `Bearer ${AuthService.getToken()}`,
    },
  };
};

export const buildConfig = (token: string): AxiosRequestConfig => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const buildConfigAmazonS3 = (): AxiosRequestConfig => {
  return {
    headers: {
      Authorization: `Bearer ${AuthService.getToken()}`,
      "Content-type": "multipart/form-data",
    },
  };
};

export const buildConfigTimeReport = (from: string, to: string): AxiosRequestConfig => {
  return {
    headers: {
      Authorization: `Bearer ${AuthService.getToken()}`,
    },
    params: {
      from: from,
      to: to,
    },
  };
};

export const buildConfigVacation = (
  from: string,
  to: string,
  page: number,
  size: number
): AxiosRequestConfig => {
  return {
    headers: {
      Authorization: `Bearer ${AuthService.getToken()}`,
      "Content-Type": "application/json;charset=utf-8",
    },
    params: {
      from: from,
      to: to,
      page: page,
      size: size,
    },
  };
};

export const buildConfigCV = (): AxiosRequestConfig => {
  return {
    headers: {
      Authorization: `Bearer ${AuthService.getToken()}`,
    },
    params: {},
  };
};

export const buildConfigDatesAndPagination = (
  from: string,
  to: string,
  page: number | null,
  size: number | null
): AxiosRequestConfig => {
  return {
    headers: {
      Authorization: `Bearer ${AuthService.getToken()}`,
    },
    params: {
      from: from,
      to: to,
      page: page,
      size: size,
    },
  };
};

export const buildConfigEmployeeAndDatesAndPaginationAndSort = (
  employee: string,
  from: string,
  to: string,
  page: number | null,
  size: number | null,
  sort: string | null,
  order: string | null
): AxiosRequestConfig => {
  return {
    headers: {
      Authorization: `Bearer ${AuthService.getToken()}`,
    },
    params: {
      from: from,
      employee: employee,
      to: to,
      page: page,
      size: size,
      sort: sort,
      order: order,
    },
  };
};

export const buildConfigWithPagination = (page: number, size: number): AxiosRequestConfig => {
  return {
    headers: {
      Authorization: `Bearer ${AuthService.getToken()}`,
      "Content-Type": "application/json;charset=utf-8",
    },
    params: {
      page: page,
      size: size,
    },
  };
};
