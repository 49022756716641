import React, { FC, useEffect, useState } from "react";
import { PageCreate } from "../../../../components/mkt/Global/Pages/PageCreate";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
  editTestimonial,
  getTestimonial,
  setEdited,
} from "../../../../stores/mkt/Testimonial.store";
import { testimonialValidationSchema } from "../../../../utils/mkt/testimonial";
import { TestimonialCreateEdit } from "../../../../components/mkt/Testimonial/TestimonialCreateEdit/TestimonialCreateEdit";

export const TestimonialEditPage: FC = () => {
  const dispatch = useTypedDispatch();
  const { testimonial, loading, error, edited } = useTypedSelector(
    (state: RootState) => state.testimonials
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchTestimonial = async () => {
      await dispatch(getTestimonial({ id: id!! })).then(() => {
        setLoaded(true);
      });
    };
    // call the function
    fetchTestimonial();
  }, []);

  useEffect(() => {
    if (edited) {
      dispatch(setEdited(false));
      navigate(`/testimonials`);
    }
  }, [edited]);

  const createAction = async () => {
    formik.errors = await formik.validateForm(formik.values);

    if (Object.keys(formik.errors).length > 0) {
      return;
    }

    dispatch(editTestimonial({ testimonial: formik.values }));
  };

  const formik = useFormik({
    initialValues: testimonial,
    onSubmit: (userInputData) => {},
    validationSchema: testimonialValidationSchema,
    enableReinitialize: true,
  });

  return (
    <PageCreate
      pageContent={{
        pageTitle: "Edit Testimonial",
        previousPage: `/testimonials`,
        action: createAction,
        type: "Edit",
        tabs: null,
        switchTabAction() {},
      }}
    >
      {loaded ? <TestimonialCreateEdit formik={formik} /> : null}
    </PageCreate>
  );
};
