export enum SubmissionStatus {
  SUBMITTED = "SUBMITTED",
  PENDING_APPROVAL = "PENDING_APPROVAL",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  PAID = "PAID",
}

export const getSubmissionStatusFromString = (status: string): SubmissionStatus => {
  const indexOfS = Object.values(SubmissionStatus).indexOf(status as unknown as SubmissionStatus);
  const key = Object.keys(SubmissionStatus)[indexOfS] as SubmissionStatus;
  return key;
};

export const isEditOrDeleteEnabledAccordingToSubmissionStatus = (status: string): boolean => {
  if (status == null) {
    return true;
  }
  switch (status) {
    case SubmissionStatus.SUBMITTED:
    case SubmissionStatus.PENDING_APPROVAL:
    case SubmissionStatus.APPROVED:
    case SubmissionStatus.PAID:
      return false;
    default:
      return true;
  }
};

export const getSubmissionStatusText = (status: string): string => {
  if (status == undefined) {
    return "Not submitted";
  }
  switch (status) {
    case SubmissionStatus.SUBMITTED:
      return "Submitted";
    case SubmissionStatus.PENDING_APPROVAL:
      return "Pending Approval";
    case SubmissionStatus.APPROVED:
      return "Approved";
    case SubmissionStatus.REJECTED:
      return "Rejected";
    case SubmissionStatus.PAID:
      return "Approved - Paid";
    default:
      return "Not submitted";
  }
};

export const getSubmissionStatusTextColor = (status: string) => {
  switch (getSubmissionStatusFromString(status)) {
    case SubmissionStatus.SUBMITTED:
      return "#000000";
    case SubmissionStatus.PAID:
      return "#529F46";
    case SubmissionStatus.APPROVED:
      return "#529F46";
    case SubmissionStatus.REJECTED:
      return "#FF0000";
    default:
      return "#000000";
  }
};
