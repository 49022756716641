import React, { FC } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Input } from "../../../../Inputs/Input";
import { CollapseExpand } from "../../../../CollapseExpand/CollapseExpand";
import { formikTrimStrings } from "../../../../../utils/string.utils";
import "./CVTechnology.css";

interface CVTechnologyProps {
  formik: any;
  title: string;
  length: number;
  index: number;
  handleDelete: (index: number) => void;
}

export const CVTechnology: FC<CVTechnologyProps> = ({
  formik,
  title,
  length,
  index,
  handleDelete,
}) => {
  return (
    <CollapseExpand title={title} index={index} length={length}>
      <Box display="flex" className="CVTechnology-box">
        <div className="CVTechnology-inputs">
          <Input
            error={formik.errors?.techStack && formik.errors.techStack[index]?.technologyArea}
            value={formik.values.techStack[index]?.technologyArea}
            handleChange={formik.handleChange}
            handleBlur={(event) => formikTrimStrings(formik, event)}
            label="Technology Area"
            name={`techStack[${index}].technologyArea`}
            type="text"
            width="20%"
            height="2rem"
          />
          <Input
            error={formik.errors?.techStack && formik.errors.techStack[index]?.technologies}
            value={formik.values.techStack[index]?.technologies}
            handleChange={formik.handleChange}
            handleBlur={(e) => formikTrimStrings(formik, e)}
            label="Technologies"
            name={`techStack[${index}].technologies`}
            type="text"
            multiline
            maxRows={4}
            width="50%"
            height="6em"
          />
        </div>
        <div className="CVTechnology-deleteButton CV-deleteButton">
          <Tooltip title="Delete Technology" onClick={() => handleDelete(index)}>
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Box>
    </CollapseExpand>
  );
};
