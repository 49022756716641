import React, { FC } from "react";
import { HtmlInput } from "../../../../Inputs/HtmlInput";

import "./BlogPostCreateEditContent.css";

interface BlogPostCreateEditContentProps {
  formik: any;
}

export const BlogPostCreateEditContent: FC<BlogPostCreateEditContentProps> = ({ formik }) => {
  return (
    <div className="BlogPostCreateEditChallenge-container">
      <HtmlInput
        error={formik.errors ? (formik.errors.content ? formik.errors.content : "") : ""}
        value={formik.values.content}
        handleChange={formik.handleChange}
        label="Description"
        name="content"
      />
    </div>
  );
};
