import React, { FC } from "react";
import { Box } from "@mui/material";
import { ClientCard } from "../ClientCard/ClientCard";
import { Client } from "../../../../../types/mkt/Client";

import "./ClientGrid.css";

interface ClientGridProps {
  clients: Client[];
  registerPublish: any;
  registerDelete: any;
}

export const ClientGrid: FC<ClientGridProps> = ({ clients, registerPublish, registerDelete }) => {
  return (
    <Box className="ClientGrid-container">
      {clients.map((client, index) => (
        <div key={index}>
          <ClientCard
            client={client}
            registerPublish={registerPublish}
            registerDelete={registerDelete}
            showHoverShadow={false}
          />
        </div>
      ))}
    </Box>
  );
};
