import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { emptyService } from "../../data/mkt/service";
import ServiceService from "../../services/mkt/Service.service";
import { Service } from "../../types/mkt/Service";
import { ServiceState } from "../../types/mkt/ServiceState";

const servicesSlice = createSlice({
  name: "services",
  initialState: {
    loading: false,
    error: null,
    services: [],
    service: emptyService,
    created: false,
    edited: false,
    deleted: false,
    publishChange: false,
  } as ServiceState,
  reducers: {
    setCreated(state, action) {
      state.created = action.payload;
    },
    setEdited(state, action) {
      state.edited = action.payload;
    },
    setDeleted(state, action) {
      state.deleted = action.payload;
    },
    setPublishState(state, action) {
      state.publishChange = action.payload;
    },
    setServices(state, action) {
      state.services = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getServices.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getServices.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.services = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getServices.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(getService.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getService.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.service = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getService.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(createService.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.created = false;
      }
    });
    builder.addCase(createService.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.created = true;
      }
    });
    builder.addCase(createService.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(editService.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.edited = false;
      }
    });
    builder.addCase(editService.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.edited = true;
      }
    });
    builder.addCase(editService.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(deleteService.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.deleted = false;
      }
    });
    builder.addCase(deleteService.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.deleted = true;
      }
    });
    builder.addCase(deleteService.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(publishService.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.publishChange = false;
      }
    });
    builder.addCase(publishService.fulfilled, (state, action) => {
      state.loading = false;
      state.publishChange = true;
      state.service = action.payload;
    });
    builder.addCase(publishService.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

export const getServices = createAsyncThunk("services/getServices", async () => {
  const response = await ServiceService.getServices();
  return response.services.sort((a, b) => a.order - b.order);
});

export const getService = createAsyncThunk(
  "services/service/getById",
  async (service: { id: string }) => {
    const response = await ServiceService.getService(service.id);
    return response.service;
  }
);

export const createService = createAsyncThunk(
  "service/service/create",
  async (data: { service: Service }) => {
    const response = await ServiceService.createService(data.service);
    return response.service;
  }
);

export const editService = createAsyncThunk(
  "services/service/edit",
  async (data: { service: Service }) => {
    const response = await ServiceService.editService(data.service);
    return response.service;
  }
);

export const deleteService = createAsyncThunk(
  "services/service/delete",
  async (data: { id: string }) => {
    await ServiceService.deleteService(data.id);
    return;
  }
);

export const publishService = createAsyncThunk(
  "services/service/publish",
  async (data: { id: string; publish: boolean }) => {
    const response = data.publish
      ? await ServiceService.unpublishService(data.id)
      : await ServiceService.publishService(data.id);
    return response.service;
  }
);

export default servicesSlice.reducer;
export const { setCreated, setEdited, setDeleted, setServices } = servicesSlice.actions;
