import { HTTP_CODES } from "../../constants/httpstatus.const";
import { emptyService } from "../../data/mkt/service";
import { Service } from "../../types/mkt/Service";
import { apiClient, getAuthConfig } from "../HttpClient";

const SERVICE_URL = "/service";
const SERVICE_BY_ID = (id: string) => SERVICE_URL + "/" + id;
const SERVICE_PUBLISH = (id: string) => `${SERVICE_URL}/${id}/publish`;
const SERVICE_UNPUBLISH = (id: string) => `${SERVICE_URL}/${id}/unpublish`;

export default class ServiveService {
  static getServices = async () => {
    try {
      const response = await apiClient.get(SERVICE_URL, getAuthConfig());
      const services: Service[] = response.data;
      return {
        services,
        code: response.status,
      };
    } catch (err: any) {
      return {
        services: [],
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static getService = async (id: string) => {
    try {
      const response = await apiClient.get(SERVICE_BY_ID(id), getAuthConfig());
      const service: Service = response.data;
      return {
        service,
        code: response.status,
      };
    } catch (err: any) {
      return {
        service: emptyService,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static createService = async (service: Service) => {
    try {
      const response = await apiClient.post(SERVICE_URL, service, getAuthConfig());
      return {
        service,
        code: response.status,
      };
    } catch (err: any) {
      return {
        service: emptyService,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static editService = async (service: Service) => {
    try {
      const response = await apiClient.put(SERVICE_URL, service, getAuthConfig());
      return {
        service: response.data as Service,
        code: response.status,
      };
    } catch (err: any) {
      return {
        service: emptyService,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static deleteService = async (id: string) => {
    try {
      const response = await apiClient.delete(SERVICE_BY_ID(id), getAuthConfig());
      return {
        code: response.status,
      };
    } catch (err: any) {
      return {
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static publishService = async (id: string) => {
    try {
      const response = await apiClient.put(SERVICE_PUBLISH(id), null, getAuthConfig());
      const service: Service = response.data;
      return {
        service,
        code: response.status,
      };
    } catch (err: any) {
      return {
        service: emptyService,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static unpublishService = async (id: string) => {
    try {
      const response = await apiClient.put(SERVICE_UNPUBLISH(id), null, getAuthConfig());
      const service: Service = response.data;
      return {
        service,
        code: response.status,
      };
    } catch (err: any) {
      return {
        service: emptyService,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };
}
