import { HTTP_CODES } from "../../constants/httpstatus.const";
import { emptyCategory } from "../../data/mkt/category";
import { Category } from "../../types/mkt/Category";
import { apiClient, buildConfig, getAuthConfig } from "../HttpClient";

const BLOG_POST_URL = "/blogPost";
const BLOG_POST_CATEGORY = BLOG_POST_URL + "/category";
const BLOG_POST_CATEGORY_SEARCH = (name: string) => `${BLOG_POST_CATEGORY}/${name}`;

export default class CategoryService {
  static getCategories = async () => {
    try {
      const response = await apiClient.get(BLOG_POST_CATEGORY, getAuthConfig());
      const categories: Category[] = response.data;
      return {
        categories,
        code: response.status,
      };
    } catch (err: any) {
      return {
        categories: [],
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static searchCategory = async (value: string) => {
    try {
      const response = await apiClient.get(BLOG_POST_CATEGORY_SEARCH(value), getAuthConfig());
      const categories: Category[] = response.data;
      return {
        categories,
        code: response.status,
      };
    } catch (err: any) {
      return {
        categories: [],
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static createCategory = async (category: Category) => {
    try {
      const response = await apiClient.post(BLOG_POST_CATEGORY, category, getAuthConfig());
      return {
        category: response.data as Category,
        code: response.status,
      };
    } catch (err: any) {
      return {
        category: emptyCategory,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };
}
