import React, { FC, useEffect, useReducer, useState } from "react";
import { observer } from "mobx-react-lite";
import { Page } from "../../../components/mkt/Global/Pages/Page";
import { pages } from "../../../data/mkt/pageContent";
import {
  deleteClient,
  editClient,
  getClients,
  publishClient,
} from "../../../stores/mkt/Client.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../stores/store";
import { ClientGrid } from "../../../components/mkt/Client/ClientLists/ClientGrid/ClientGrid";
import { ClientsDnD } from "../../../components/mkt/Client/ClientLists/ClientDnD/ClientDnD";

export const ClientsPage: FC = observer(() => {
  const dispatch = useTypedDispatch();
  const { open } = useTypedSelector((state: RootState) => state.sidebar);
  const { clients, loading, error, edited, deleted, publishChange } = useTypedSelector(
    (state: RootState) => state.clients
  );
  const [dndEnabled, setDndEnabled] = useState<boolean>(false);
  const [loaded, setLoaded] = useState(false);

  const dndAction = () => {
    for (let index = 0; index < clients.length; index++) {
      dispatch(
        editClient({
          client: { ...clients[index], order: index },
        })
      );
    }
    setDndEnabled(false);
  };

  const fetchClient = async () => {
    await dispatch(getClients()).then(() => {
      setLoaded(true);
    });
  };

  async function cancelDndAction() {
    setDndEnabled(false);
    setLoaded(false);
    fetchClient();
  }

  useEffect(() => {
    fetchClient();
  }, [publishChange, deleted]);

  function registerPublish(id: string, publish: boolean) {
    dispatch(publishClient({ id: id, publish: publish }));
  }

  function registerDelete(id: string) {
    dispatch(deleteClient({ id: id }));
  }

  return (
    <Page
      pageContent={pages.clients}
      search={(term) => {}}
      dndEnabled={dndEnabled}
      dndAction={dndAction}
      cancelDndAction={cancelDndAction}
      setDndEnabled={setDndEnabled}
    >
      {loaded ? (
        dndEnabled ? (
          <ClientsDnD clients={clients} />
        ) : (
          <ClientGrid
            clients={clients}
            registerPublish={registerPublish}
            registerDelete={registerDelete}
          />
        )
      ) : null}
    </Page>
  );
});
function setPublishPressed(arg0: boolean) {
  throw new Error("Function not implemented.");
}
