import React, { FC } from "react";
import { Vacancy } from "../../../../../../types/mkt/Vacancy";
import { VacancyCard } from "../../VacancyCard/VacancyCard";
import "./VacancyDnDSingle.css";

interface VacancyDnDSingleProps {
  vacancy: Vacancy;
}

export const VacancyDnDSingle: FC<VacancyDnDSingleProps> = ({ vacancy }) => {
  return (
    <div className="VacancyDnDSingle-link">
      <VacancyCard
        icon={false}
        vacancy={vacancy}
        editAction={null}
        publishAction={null}
        deleteAction={null}
        duplicateAction={null}
      />
      <br />
    </div>
  );
};
