import React, { FC } from "react";

export const CaseStudies: FC = () => {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.8334 25.3038V10.3333L18.0834 2.58333H7.75002C6.32919 2.58333 5.1796 3.74583 5.1796 5.16667L5.16669 25.8333C5.16669 27.2542 6.31627 28.4167 7.7371 28.4167H23.25C23.8313 28.4167 24.3479 28.2229 24.7871 27.9L19.065 22.1779C18.0317 22.8496 16.8175 23.25 15.5 23.25C11.935 23.25 9.04169 20.3567 9.04169 16.7917C9.04169 13.2267 11.935 10.3333 15.5 10.3333C19.065 10.3333 21.9584 13.2267 21.9584 16.7917C21.9584 18.1092 21.5579 19.3233 20.8863 20.3438L25.8334 25.3038ZM11.625 16.7917C11.625 18.9358 13.3559 20.6667 15.5 20.6667C17.6442 20.6667 19.375 18.9358 19.375 16.7917C19.375 14.6475 17.6442 12.9167 15.5 12.9167C13.3559 12.9167 11.625 14.6475 11.625 16.7917Z"
        fill="white"
      />
    </svg>
  );
};
