import React, { FC, useEffect, useState } from "react";
import { BlogPostCreateEditAuthor } from "./BlogPostCreateEditAuthor/BlogPostCreateEditAuthor";
import { BlogPostCreateEditCategories } from "./BlogPostCreateEditCategories/BlogPostCreateEditCategories";
import { BlogPostCreateEditContent } from "./BlogPostCreateEditContent/BlogPostCreateEditContent";
import { BlogPostCreateEditSummary } from "./BlogPostCreateEditSummary/BlogPostCreateEditSummary";
import { PageCreateEditSEO } from "../../Global/Pages/PageCreateEditSEO";

interface BlogPostCreateEditProps {
  formik: any;
  activeTab: number;
}

export const BlogPostCreateEdit: FC<BlogPostCreateEditProps> = ({ formik, activeTab }) => {
  const renderTab = (index: number) => {
    switch (index) {
      case 0:
        return <BlogPostCreateEditSummary formik={formik} />;
      case 1:
        return <BlogPostCreateEditAuthor formik={formik} />;
      case 2:
        return <BlogPostCreateEditCategories formik={formik} />;
      case 3:
        return <BlogPostCreateEditContent formik={formik} />;
      case 4:
        return <PageCreateEditSEO formik={formik} />;
    }
  };

  return <div>{renderTab(activeTab)}</div>;
};
