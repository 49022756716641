import React, { FC } from "react";
import { DEFAULT_CURRENCY } from "../../../../../constants/expenses.const";
import { ExpenseSummary } from "../../../../../types/internal/expenses/ExpenseSummary";

import "./ExpensesSummaryCard.css";

interface ExpensesSummaryCardProp {
  expensesSummary: ExpenseSummary;
}

export const ExpensesSummaryCard: FC<ExpensesSummaryCardProp> = ({ expensesSummary }) => {
  return (
    <div className="ExpensesSummaryCard-container">
      <div className="ExpensesSummaryCard-headerTitle">Expenses</div>
      <div className="ExpensesSummaryCard-reviewSection">
        <div className="ExpensesSummaryCard-reviewItem">
          <div className="ExpensesSummaryCard-reviewItemKey">In Review</div>
          <div className="ExpensesSummaryCard-reviewItemValue">
            {expensesSummary.inReview + " " + DEFAULT_CURRENCY}
          </div>
        </div>
        <div className="ExpensesSummaryCard-reviewItem">
          <div className="ExpensesSummaryCard-reviewItemKey">Paid</div>
          <div className="ExpensesSummaryCard-reviewItemValue">
            {expensesSummary.paid + " " + DEFAULT_CURRENCY}
          </div>
        </div>
      </div>
    </div>
  );
};
