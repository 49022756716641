import React, { FC } from "react";
import { Author } from "../../../../../../../types/mkt/Author";

import "./BlogPostAuthor.css";

interface BlogPostAuthorProps {
  author: Author;
}

export const BlogPostAuthor: FC<BlogPostAuthorProps> = ({ author }) => {
  return (
    <div className="BlogPostAuthor-container">
      <img className="BlogPostAuthor-image" src={author.photo} alt="Author" />
      <div className="BlogPostAuthor-footer">
        <span className="BlogPostAuthor-name">{author.name}</span>
      </div>
    </div>
  );
};
