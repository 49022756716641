import React, { FC } from "react";
import { Link } from "react-router-dom";
import { BlogPost } from "../../../../../types/mkt/BlogPost";
import { InfoCard } from "../../../Global/Cards/InfoCard/InfoCard";

interface BlogPostCardProps {
  blogPost: BlogPost;
}

export const BlogPostCard: FC<BlogPostCardProps> = ({ blogPost }) => {
  return (
    <InfoCard
      id={blogPost.id}
      image={blogPost.thumbnail}
      title={blogPost.title}
      publish={blogPost.publish}
      author={blogPost.author}
      editAction={null}
      publishAction={null}
      deleteAction={null}
      duplicateAction={null}
      toCropImage={true}
    />
  );
};
