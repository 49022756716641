import { HTTP_CODES } from "../../constants/httpstatus.const";
import { emptyClient } from "../../data/mkt/client";
import { Client } from "../../types/mkt/Client";
import { apiClient, buildConfig, getAuthConfig } from "../HttpClient";

const CLIENTS_URL = "/client";
const CLIENTS_URL_BY_ID = (id: string) => CLIENTS_URL + "/" + id;
const CLIENTS_PUBLISH = (id: string) => `${CLIENTS_URL}/${id}/publish`;
const CLIENTS_UNPUBLISH = (id: string) => `${CLIENTS_URL}/${id}/unpublish`;

export default class ClientService {
  static getClients = async () => {
    try {
      const response = await apiClient.get(CLIENTS_URL, getAuthConfig());
      const clients: Client[] = response.data;
      return {
        clients,
        code: response.status,
      };
    } catch (err: any) {
      return {
        clients: [],
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static getClient = async (id: string) => {
    try {
      const response = await apiClient.get(CLIENTS_URL_BY_ID(id), getAuthConfig());
      const client: Client = response.data;
      return {
        client,
        code: response.status,
      };
    } catch (err: any) {
      return {
        client: emptyClient,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static createClient = async (client: Client) => {
    try {
      const response = await apiClient.post(CLIENTS_URL, client, getAuthConfig());
      return {
        client: response.data as Client,
        code: response.status,
      };
    } catch (err: any) {
      return {
        client: emptyClient,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static editClient = async (client: Client) => {
    try {
      const response = await apiClient.put(CLIENTS_URL, client, getAuthConfig());
      return {
        client: response.data as Client,
        code: response.status,
      };
    } catch (err: any) {
      return {
        client: emptyClient,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static deleteClient = async (id: string) => {
    try {
      const response = await apiClient.delete(CLIENTS_URL_BY_ID(id), getAuthConfig());
      return {
        code: response.status,
      };
    } catch (err: any) {
      return {
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static publishClient = async (id: string) => {
    try {
      const response = await apiClient.put(CLIENTS_PUBLISH(id), {}, getAuthConfig());
      return {
        client: response.data as Client,
        code: response.status,
      };
    } catch (err: any) {
      return {
        client: emptyClient,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static unpublishClient = async (id: string) => {
    try {
      const response = await apiClient.put(CLIENTS_UNPUBLISH(id), {}, getAuthConfig());
      return {
        client: response.data as Client,
        code: response.status,
      };
    } catch (err: any) {
      return {
        client: emptyClient,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };
}
