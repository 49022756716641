import { Box, Grid } from "@mui/material";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { BlogPost } from "../../../../../types/mkt/BlogPost";
import { BlogPostCard } from "../BlogPostCard/BlogPostCard";

import "./BlogPostGrid.css";

interface BlogPostGridProps {
  blogPosts: BlogPost[];
}

export const BlogPostGrid: FC<BlogPostGridProps> = ({ blogPosts }) => {
  return (
    <Box className="BlogPostGrid-container">
      {blogPosts.map((blogPost, index) => (
        <div key={index}>
          <Link to={`/blog_posts/${blogPost.id}`} className="BlogPostGrid-link">
            <BlogPostCard blogPost={blogPost} />
          </Link>
        </div>
      ))}
    </Box>
  );
};
