import React, { FC } from "react";

export const Testimonials: FC = () => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.125 14.625C12.6103 14.625 14.625 12.6103 14.625 10.125C14.625 7.63972 12.6103 5.625 10.125 5.625C7.63972 5.625 5.625 7.63972 5.625 10.125C5.625 12.6103 7.63972 14.625 10.125 14.625Z"
        fill="white"
      />
      <path
        d="M10.125 16.875C7.12125 16.875 1.125 18.3825 1.125 21.375V23.625H19.125V21.375C19.125 18.3825 13.1287 16.875 10.125 16.875ZM18.855 6.03L16.965 7.93125C17.91 9.25875 17.91 10.98 16.965 12.3075L18.855 14.2087C21.1275 11.9362 21.1275 8.505 18.855 6.03ZM22.5788 2.25L20.745 4.08375C23.8612 7.48125 23.8612 12.5887 20.745 16.1663L22.5788 18C26.9662 13.6237 26.9775 6.80625 22.5788 2.25Z"
        fill="white"
      />
    </svg>
  );
};
