import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { SidebarItem as SidebarItemType } from "../../../../types/SidebarItem";

interface SidebarItemProps {
  item: SidebarItemType;
}
export const SidebarItem: FC<SidebarItemProps> = observer(({ item }) => {
  const dispatch = useTypedDispatch();
  const { open } = useTypedSelector((state: RootState) => state.sidebar);
  const navigate = useNavigate();

  const pathArgs = useLocation().pathname.split("/");

  return (
    <ListItem
      key={item.text}
      disablePadding
      sx={{ display: "block" }}
      className={
        pathArgs.length > 1 && pathArgs[1] === item.text.toLowerCase().replace(" ", "_")
          ? "activeLink"
          : "notActiveLink"
      }
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: "flex-start",
          px: 2.5,
        }}
        onClick={() => navigate("/" + item.text.toLowerCase().replace(" ", "_"))}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "flex-start",
            width: 32,
            height: 32,
          }}
        >
          {item.icon}
        </ListItemIcon>
        {open && <ListItemText primary={item.text} />}
      </ListItemButton>
    </ListItem>
  );
});
