import { Service } from "../../types/mkt/Service";

export const emptyService: Service = {
  id: "",
  order: 0,
  title: "",
  subTitle: "",
  description: "",
  icon: "",
  url: "",
  linkText: "",
  image: "",
  publish: false,
};
