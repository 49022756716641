import { HTTP_CODES } from "../../constants/httpstatus.const";
import { emptyCV } from "../../data/internal/cv/cv";
import { CV } from "../../types/internal/cv/CV";
import { apiClient, buildConfigCV, getAuthConfig } from "../HttpClient";

const CV_BY_EMPLOYEE = (email: string) => `/employee/${email}/cv`;

export default class CVService {
  static getCV = async (userEmail: any) => {
    try {
      const response = await apiClient.get(CV_BY_EMPLOYEE(userEmail), buildConfigCV());
      const cv: CV = response.data;
      return {
        cv,
        code: response.status,
      };
    } catch (err: any) {
      return {
        cv: emptyCV,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static createCV = async (cv: CV) => {
    try {
      const response = await apiClient.put(CV_BY_EMPLOYEE(cv.email), cv, getAuthConfig());
      return {
        cv: response.data as CV,
        code: response.status,
      };
    } catch (err: any) {
      return {
        cv: emptyCV,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static editCV = async (cv: CV) => {
    try {
      const response = await apiClient.put(CV_BY_EMPLOYEE(cv.email), cv, getAuthConfig());
      return {
        cv: response.data as CV,
        code: response.status,
      };
    } catch (err: any) {
      return {
        cv: emptyCV,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };
}
