export const ROLES = {
  ADMIN: "ROLE_ADMIN",
  INTERNAL: "ROLE_INTERNAL",
  HR: "ROLE_HR",
  FINANCE: "ROLE_FINANCE",
  KODLY_IO: "ROLE_KODLY_IO",
  APPROVER: "ROLE_APPROVER",
};

export const FINANCE_ROLES = [ROLES.ADMIN, ROLES.FINANCE];

export const KODLY_IO_ROLES = [ROLES.ADMIN, ROLES.KODLY_IO];

export const HR_ROLES = [ROLES.ADMIN, ROLES.HR];

export const INTERNAL_ROLES = [ROLES.INTERNAL];

export const APPROVER_ROLES = [ROLES.APPROVER];
