import { Box, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { Service } from "../../../../../types/mkt/Service";
import { InfoCard } from "../../../Global/Cards/InfoCard/InfoCard";

interface ServiceCardProps {
  service: Service;
  editAction: any;
  publishAction: any;
  deleteAction: any;
}

export const ServiceCard: FC<ServiceCardProps> = ({
  service,
  editAction,
  publishAction,
  deleteAction,
}) => {
  return (
    <InfoCard
      id={service.id}
      image={service.image}
      title={service.title}
      publish={service.publish}
      author={null}
      editAction={editAction}
      publishAction={publishAction}
      deleteAction={deleteAction}
      duplicateAction={null}
    />
  );
};
