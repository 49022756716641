import React, { FC } from "react";
import { Author } from "../../../../../types/mkt/Author";
import { InfoCardSubBodySection } from "./InfoCardSubBodySection/InfoCardSubBodySection";
import { InfoCardSubImageSection } from "./InfoCardSubImageSection/InfoCardSubImageSection";

import "./InfoCard.css";

interface InfoCardProps {
  id: string;
  title: string;
  publish: boolean;
  image: string;
  author: Author | null;
  editAction: any;
  publishAction: any;
  deleteAction: any;
  duplicateAction: any;
  toCropImage?: boolean;
}

export const InfoCard: FC<InfoCardProps> = ({
  id,
  title,
  publish,
  image,
  author,
  editAction,
  publishAction,
  deleteAction,
  duplicateAction,
  toCropImage,
}) => {
  return (
    <div className="InfoCard-withIconContainer">
      <InfoCardSubImageSection
        id={id}
        publish={publish}
        editAction={editAction}
        publishAction={publishAction}
        deleteAction={deleteAction}
        duplicateAction={duplicateAction}
      >
        <div className="InfoCard-withIconItem">
          <img className={toCropImage ? "InfoCard-image" : ""} src={image} alt="Icon" />
        </div>
      </InfoCardSubImageSection>
      <InfoCardSubBodySection author={author} title={title} />
    </div>
  );
};
