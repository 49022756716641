import React, { FC } from "react";

export const TimeReport: FC = () => {
  return (
    <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.8041 20.2142C18.9339 19.8468 18.8581 19.4188 18.5746 19.12C18.1312 18.653 17.3679 18.704 16.9932 19.281C16.7691 19.6269 16.7715 20.0947 16.9994 20.439C17.2752 20.8574 17.7603 20.9944 18.1744 20.8502L19.4646 21.9333C19.7103 22.1413 20.0363 21.8074 19.8385 21.5507L18.8041 20.2142Z"
        fill="white"
      />
      <path
        d="M21.8711 16.2462L22.0009 16.3801C22.2288 16.6152 22.5996 16.6152 22.829 16.3801C23.0569 16.1434 23.0569 15.7617 22.829 15.5258L22.0171 14.6882C21.7884 14.4515 21.4184 14.4515 21.1889 14.6882C20.9595 14.9249 20.9595 15.3066 21.1889 15.5425L21.3187 15.6764L21.0607 15.9426C20.336 15.3122 19.4344 14.8922 18.4432 14.7718V13.9454H19.0543C19.3773 13.9454 19.6407 13.6752 19.6407 13.3413C19.6407 13.0074 19.378 12.7356 19.0543 12.7356H16.6617C16.3372 12.7356 16.0761 13.0074 16.0761 13.3413C16.0761 13.6752 16.3372 13.9454 16.6617 13.9454H17.2713V14.7718C14.8045 15.0715 12.8816 17.2408 12.8816 19.8668C12.8816 22.6985 15.1135 25.0001 17.8576 25.0001C20.6018 25.0001 22.8337 22.6961 22.8337 19.8668C22.8337 18.5837 22.3709 17.4122 21.6146 16.5108L21.8711 16.2462ZM21.6625 19.8668C21.6625 22.0306 19.9567 23.7911 17.8584 23.7911C15.7601 23.7911 14.0536 22.0306 14.0536 19.8668C14.0536 17.7023 15.7601 15.941 17.8584 15.941C19.9567 15.941 21.6625 17.7023 21.6625 19.8668Z"
        fill="white"
      />
      <path
        d="M4.56822 6.05454C5.42499 6.05454 6.12029 5.33807 6.12029 4.45344V1.6019C6.12029 0.717269 5.42499 0 4.56822 0C3.71068 0 3.01538 0.716472 3.01538 1.6019V4.45344C3.01615 5.33807 3.71145 6.05454 4.56822 6.05454Z"
        fill="white"
      />
      <path
        d="M15.6382 6.05454C16.4949 6.05454 17.191 5.33807 17.191 4.45344V1.6019C17.191 0.717269 16.4957 0 15.6382 0C14.7806 0 14.0853 0.716472 14.0853 1.6019V4.45344C14.0853 5.33807 14.7814 6.05454 15.6382 6.05454Z"
        fill="white"
      />
      <path
        d="M7.81519 20.8429V15.9998H12.3903V18.3907C12.8546 16.5624 14.1448 15.0642 15.8621 14.3987H13.9431V9.61929H18.6549V12.0221H19.0559C19.565 12.0221 20.0031 12.3345 20.207 12.78V3.02759H17.9689V4.45336C17.9689 5.77791 16.9229 6.85541 15.6397 6.85541C14.3557 6.85541 13.3104 5.77791 13.3104 4.45336V3.02759H6.89817V4.45336C6.89817 5.77791 5.8529 6.85541 4.56968 6.85541C3.28569 6.85541 2.24042 5.77791 2.24042 4.45336V3.02759H0V22.4448H12.7804C12.5402 21.9467 12.3702 21.4096 12.2775 20.8437L7.81519 20.8429ZM7.81519 9.62008H12.3903V14.3995H7.81519V9.62008ZM6.26235 20.8429H1.55284V15.9998H6.26235V20.8429ZM6.26235 14.3995H1.55284V9.62008H6.26235V14.3995Z"
        fill="white"
      />
    </svg>
  );
};
