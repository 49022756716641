export const DAY_OF_WEEK_INDEX = {
  SUNDAY: 0,
  SATURDAY: 6,
};

export const MONTH_INDEX = {
  JANUARY: 0,
  DECEMBER: 11,
};

export const MONTH_SHORT_NAME = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const SPECIFIC_DATES = {
  FIRST_DAY_OF_JANUARY: 1,
  LAST_DAY_OF_DECEMBER: 31,
};

export const WORKING_HOURS = {
  FULL_DAY: 8,
  DAY_OFF: 0,
};
