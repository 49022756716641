import React, { FC, useEffect, useState } from "react";
import { PageCreate } from "../../../../components/mkt/Global/Pages/PageCreate";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { blogPostValidationSchema } from "../../../../utils/mkt/blogPost";
import { editBlogPost, getBlogPost, setEdited } from "../../../../stores/mkt/BlogPost.store";
import { BlogPostCreateEdit } from "../../../../components/mkt/BlogPost/BlogPostCreateEdit/BlogPostCreateEdit";

export const BlogPostEditPage: FC = () => {
  const dispatch = useTypedDispatch();
  const { blogPost, loading, error, edited } = useTypedSelector(
    (state: RootState) => state.blogPosts
  );
  const { id } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    dispatch(getBlogPost({ id: id!! }));
  }, []);

  const swithTabAction = (index: number) => {
    setActiveTab(index);
  };

  useEffect(() => {
    if (edited) {
      dispatch(setEdited(false));
      navigate(`/blog_posts/${id}`);
    }
  }, [edited]);

  const createAction = async () => {
    formik.errors = await formik.validateForm(formik.values);

    if (Object.keys(formik.errors).length > 0) {
      return;
    }

    dispatch(editBlogPost({ blogPost: formik.values }));
  };

  const formik = useFormik({
    initialValues: blogPost,
    onSubmit: (userInputData) => {},
    validationSchema: blogPostValidationSchema,
  });

  return (
    <div className="BlogPostCreatePage-pageEditDiv">
      <PageCreate
        pageContent={{
          pageTitle: `Edit Blog Post`,
          previousPage: `/blog_posts/${id}`,
          action: createAction,
          type: "Edit",
          tabs: ["Summary", "Author", "Categories", "Content"], //["Summary", "Author", "Categories", "Content", "SEO"],
          switchTabAction: swithTabAction,
        }}
      >
        <BlogPostCreateEdit formik={formik} activeTab={activeTab} />
      </PageCreate>
    </div>
  );
};
