import React, { FC } from "react";
import { Input } from "../../../../Inputs/Input";

interface ServiceSubTitleCreateProps {
  formik: any;
}

export const ServiceCreateEditSubTitle: FC<ServiceSubTitleCreateProps> = ({ formik }) => {
  return (
    <Input
      error={formik.errors.subTitle}
      value={formik.values.subTitle}
      handleChange={formik.handleChange}
      label="SubTitle"
      name="subTitle"
      type="text"
      width="95%"
      height="2rem"
    />
  );
};
