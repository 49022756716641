import React, { FC } from "react";
import { TeamMember } from "../../../../../types/mkt/TeamMember";
import { Box, Grid } from "@mui/material";
import { TeamMemberCard } from "../TeamMemberCard/TeamMemberCard";
import { Link } from "react-router-dom";

import "./TeamMemberGrid.css";

interface TeamMemberGridProps {
  teamMembers: TeamMember[];
  editAction: any;
  publishAction: any;
  deleteAction: any;
}

export const TeamMemberGrid: FC<TeamMemberGridProps> = ({
  teamMembers,
  editAction,
  publishAction,
  deleteAction,
}) => {
  return (
    <Box className="TeamMemberGrid-container">
      {teamMembers.map((teamMember, index) => (
        <div className={`TeamMemberGrid-link`} key={index}>
          <TeamMemberCard
            teamMember={teamMember}
            editAction={editAction}
            publishAction={publishAction}
            deleteAction={deleteAction}
            hoverEffectEnabled={true}
          />
        </div>
      ))}
    </Box>
  );
};
