import React, { FC } from "react";
import { Box, Button } from "@mui/material";
import { Solution } from "../../../../../types/mkt/Solution";
import { renderHtml } from "../../../../../utils/renderHtml";
import "./CaseStudyDetailsSolution.css";

interface CaseStudyDetailsSolutionProps {
  solution: Solution;
}

export const CaseStudyDetailsSolution: FC<CaseStudyDetailsSolutionProps> = ({ solution }) => {
  return (
    <div className="CaseStudyDetailsSolution-container">
      <Box display="flex" alignSelf="center">
        <Box width="50%">
          <div>
            <h2 className="CaseStudyDetailsSolution-title">
              <span>Kodly&apos;s</span> Solution
            </h2>
            <p
              className="CaseStudyDetailsSolution-text"
              dangerouslySetInnerHTML={renderHtml(solution.text)}
            />
          </div>
        </Box>
        <Box width="50%">
          <h2 className="CaseStudyDetailsSolution-stackTitle">Technology Stack</h2>
          <img
            className="CaseStudyDetailsSolution-stackImage"
            src={solution.stack}
            alt="Solution Stack"
          />
        </Box>
      </Box>
    </div>
  );
};
