import { Box } from "@mui/material";
import React, { FC } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { setCaseStudies } from "../../../../../stores/mkt/CaseStudy.store";
import { useTypedDispatch } from "../../../../../stores/store";
import { CaseStudy } from "../../../../../types/mkt/CaseStudy";
import { reorder } from "../../../../../utils/array";
import { CaseStudyDnDSingle } from "./CaseStudyDnDSingle/CaseStudyDnDSingle";

interface CaseStudyDnDProps {
  caseStudies: CaseStudy[];
}

export const CaseStudyDnD: FC<CaseStudyDnDProps> = ({ caseStudies }) => {
  const dispatch = useTypedDispatch();

  // Function to update list on drop
  const handleDrop = (result: any) => {
    // Ignore drop outside droppable container
    if (!result.destination) {
      return;
    }

    const items = reorder(caseStudies, result.source.index, result.destination.index);
    dispatch(setCaseStudies(items));
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId={"dndVacancies"}>
            {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
              <Box ref={dropProvided.innerRef}>
                {caseStudies.map((caseStudy, index: number) => (
                  <Draggable index={index} draggableId={caseStudy.id} key={caseStudy.id}>
                    {(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => (
                      <div
                        ref={dragProvided.innerRef}
                        key={caseStudy.id}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                      >
                        <CaseStudyDnDSingle caseStudy={caseStudy} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {dropProvided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
};
