import React, { FC } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Input } from "../../../../Inputs/Input";
import DeleteIcon from "@mui/icons-material/Delete";
import { InputDate } from "../../../../Inputs/InputDate";
import { CollapseExpand } from "../../../../CollapseExpand/CollapseExpand";
import { formikTrimStrings } from "../../../../../utils/string.utils";
import "./CVEducationCertification.css";
interface CVEducationCertificationProps {
  formik: any;
  title: string;
  index: number;
  length: number;
  handleDelete: (index: number) => void;
}

export const CVEducationCertification: FC<CVEducationCertificationProps> = ({
  formik,
  title,
  index,
  length,
  handleDelete,
}) => {
  return (
    <CollapseExpand title={title} index={index} length={length}>
      <div className="CVEduCert-box">
        <div className="CVEduCert-inputs">
          <Box display="flex" className="CVEduCert-1stRow">
            <InputDate
              error={formik.errors.certifications && formik.errors.certifications[index]?.startDate}
              value={formik.values.certifications[index]?.startDate}
              handleChange={formik.handleChange}
              label="Start Date"
              name={`certifications[${index}].startDate`}
              width="100%"
            />
            <InputDate
              error={formik.errors.certifications && formik.errors.certifications[index]?.endDate}
              value={formik.values.certifications[index]?.endDate}
              handleChange={formik.handleChange}
              label="End Date"
              name={`certifications[${index}].endDate`}
              width="100%"
            />
          </Box>
          <Box display="flex" className="CVEduCert-2ndRow">
            <Input
              error={
                formik.errors.certifications && formik.errors.certifications[index]?.institutionName
              }
              value={formik.values.certifications[index]?.institutionName}
              handleChange={formik.handleChange}
              handleBlur={(e) => formikTrimStrings(formik, e)}
              label="Institution Name"
              name={`certifications[${index}].institutionName`}
              type="text"
              width="100%"
              height="2rem"
            />
            <Input
              error={formik.errors.certifications && formik.errors.certifications[index]?.degree}
              value={formik.values.certifications[index]?.degree}
              handleChange={formik.handleChange}
              handleBlur={(e) => formikTrimStrings(formik, e)}
              label="Degree"
              name={`certifications[${index}].degree`}
              type="text"
              width="100%"
              height="2rem"
            />
            <Input
              error={formik.errors.certifications && formik.errors.certifications[index]?.area}
              value={formik.values.certifications[index]?.area}
              handleChange={formik.handleChange}
              handleBlur={(e) => formikTrimStrings(formik, e)}
              label="Area"
              name={`certifications[${index}].area`}
              type="text"
              width="100%"
              height="2rem"
            />
          </Box>
        </div>
        <div className="CVEduCert-deleteButton CV-deleteButton">
          <Tooltip title="Delete Education or Certification" onClick={() => handleDelete(index)}>
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </CollapseExpand>
  );
};
