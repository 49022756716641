import { Box } from "@mui/material";
import React, { FC } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { setTestimonials } from "../../../../../stores/mkt/Testimonial.store";
import { useTypedDispatch } from "../../../../../stores/store";
import { Testimonial } from "../../../../../types/mkt/Testimonial";
import { reorder } from "../../../../../utils/array";
import { TestimonialDnDSingle } from "./TestimonialDnDSingle/TestimonialDnDSingle";
import "./TestimonialDnD.css";

interface TestimonialDnDProps {
  testimonials: Testimonial[];
}

export const TestimonialDnD: FC<TestimonialDnDProps> = ({ testimonials }) => {
  const dispatch = useTypedDispatch();

  // Function to update list on drop
  const handleDrop = (result: any) => {
    // Ignore drop outside droppable container
    if (!result.destination) {
      return;
    }

    const items = reorder(testimonials, result.source.index, result.destination.index);
    dispatch(setTestimonials(items));
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId={"dndVacancies"}>
            {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
              <Box ref={dropProvided.innerRef}>
                {testimonials.map((testimonials, index: number) => (
                  <div className="TestimonialDnD-card" key={testimonials.id}>
                    <Draggable index={index} draggableId={testimonials.id}>
                      {(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => (
                        <div
                          ref={dragProvided.innerRef}
                          key={testimonials.id}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                        >
                          <TestimonialDnDSingle testimonial={testimonials} />
                        </div>
                      )}
                    </Draggable>
                  </div>
                ))}
                {dropProvided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
};
