import React, { FC } from "react";
import { PageCreateEditSEO } from "../../Global/Pages/PageCreateEditSEO";
import { VacancyCreateEditBasicInformation } from "./VacancyCreateEditBasicInformation/VacancyCreateEditBasicInformation";

interface VacancyCreateEditProps {
  formik: any;
  locations: string[];
  activeTab: number;
}

export const VacancyCreateEdit: FC<VacancyCreateEditProps> = ({ formik, locations, activeTab }) => {
  const renderTab = (index: number) => {
    switch (index) {
      case 0:
        return <VacancyCreateEditBasicInformation formik={formik} locations={locations} />;
      case 1:
        return <PageCreateEditSEO formik={formik} />;
    }
  };

  return <div>{renderTab(activeTab)}</div>;
};
