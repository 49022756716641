import { Testimonial } from "../../types/mkt/Testimonial";

export const emptyTestimonial: Testimonial = {
  id: "",
  order: 0,
  image: "",
  text: "",
  name: "",
  position: "",
  publish: false,
};
