import React, { FC } from "react";
import { ImageInputSquare } from "../../../../ImageInputs/ImageInputSquare/ImageInputSquare";
import { Input } from "../../../../Inputs/Input";

import "./BlogPostCreateEditAuthor.css";

interface BlogPostCreateEditAuthorProps {
  formik: any;
}

export const BlogPostCreateEditAuthor: FC<BlogPostCreateEditAuthorProps> = ({ formik }) => {
  return (
    <div className="BlogPostCreateEditAuthor-container">
      <div className="BlogPostCreateEditAuthor-container_leftSide">
        <ImageInputSquare
          title="Image"
          formik={formik}
          buttonTitle="Upload image"
          image={formik.values.author.photo}
          error={formik.errors ? (formik.errors.author ? formik.errors.author.photo : "") : ""}
          target="author.photo"
          folder="blogPosts"
          height="14em"
          width="25em"
          acceptType={null}
        />
      </div>

      <div className="BlogPostCreateEditAuthor-container_rightSide">
        <Input
          error={formik.errors ? (formik.errors.author ? formik.errors.author.name : "") : ""}
          value={formik.values.author.name}
          handleChange={formik.handleChange}
          label="Author Name"
          name="author.name"
          type="text"
          width="auto"
          height="2rem"
          maxLength={30}
        />
        <Input
          error={formik.errors ? (formik.errors.author ? formik.errors.author.role : "") : ""}
          value={formik.values.author.role}
          handleChange={formik.handleChange}
          label="Author Role"
          name="author.role"
          type="text"
          width="auto"
          height="2rem"
          maxLength={30}
        />
      </div>
    </div>
  );
};
