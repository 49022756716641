import React, { FC } from "react";
import { Testimonial } from "../../../../../types/mkt/Testimonial";
import { ImageHoverCard } from "../../../Global/Cards/ImageHoverCard/ImageHoverCard";

interface TestimonialCardProps {
  testimonials: Testimonial;
  editAction: any;
  publishAction: any;
  deleteAction: any;
  hoverEffectEnabled: boolean;
}

export const TestimonialCard: FC<TestimonialCardProps> = ({
  testimonials,
  editAction,
  publishAction,
  deleteAction,
  hoverEffectEnabled,
}) => {
  return (
    <ImageHoverCard
      imageURL={testimonials.image}
      title={testimonials.name}
      description={testimonials.text}
      publish={testimonials.publish}
      id={testimonials.id}
      editAction={editAction}
      publishAction={publishAction}
      deleteAction={deleteAction}
      hoverEffectEnabled={hoverEffectEnabled}
    />
  );
};
