import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserState } from "../../types/internal/UserState";
import UserService from "../../services/internal/User.service";
import { User } from "../../types/internal/User";
import { emptyUser } from "../../data/internal/user";
import { HTTP_CODES } from "../../constants/httpstatus.const";

const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    error: null,
    user: emptyUser,
  } as UserState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.user = emptyUser;
      }
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.user = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getUser.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(editUser.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(editUser.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.user = action.payload.user;
        state.loading = false;
      }
    });
    builder.addCase(editUser.rejected, (state, action) => {
      if (state.loading === true) {
        state.error = "Error occured, please try again later.";
        state.loading = false;
      }
    });
  },
});

export const getUser = createAsyncThunk("employee/getByEmail", async (user: { email: string }) => {
  const response = await UserService.getUser(user.email);
  return response.user;
});

export const getEmployeeDetails = createAsyncThunk(
  "employee/getDetails",
  async (user: { email: string }) => {
    const response = await UserService.getEmployeeDetails(user.email);
    return response.user;
  }
);

export const editUser = createAsyncThunk("employee/amenduser", async (user: { user: User }) => {
  const response = await UserService.patchUser(user.user);
  return {
    user: response.user,
    code: response.code || HTTP_CODES.INTERNAL_SERVER_ERROR,
  };
});

export default userSlice.reducer;
