import * as yup from "yup";
import { MAX_EXPENSE_VALUE, MIN_EXPENSE_VALUE } from "../../constants/expenses.const";

import { REGEX } from "../../constants/regex.const";

export const expenseValidationSchema = yup.object({
  description: yup
    .string()
    .strict()
    .trim("Description should not end in an empty space")
    .required("Description is required"),
  cost: yup
    .string()
    .test("is-decimal", "Expense Value is not valid", (val: any) => {
      if (!val) return false;
      if (REGEX.NUMBERS_TWO_DECIMAL_PLACES_TEXT.test(val)) {
        return Number(val) >= MIN_EXPENSE_VALUE && Number(val) <= MAX_EXPENSE_VALUE;
      }
      return false;
    })
    .strict()
    .required("Expense Value is required"),
  submitted: yup.string().required("Date is required"),
  expensesType: yup.object().shape({
    code: yup.string().required("Expense Type is required"),
  }),
  wbs: yup.object().shape({
    code: yup.string().required("WBS is required"),
  }),
});
