import React, { FC } from "react";
import "./CaseStudyCreateEditResults.css";
import { HtmlInput } from "../../../../Inputs/HtmlInput";

interface CaseStudyCreateEditResultsProps {
  formik: any;
}

export const CaseStudyCreateEditResults: FC<CaseStudyCreateEditResultsProps> = ({ formik }) => {
  return (
    <div className="CaseStudyCreateEditChallenge-container">
      <HtmlInput
        error={formik.errors ? (formik.errors.results ? formik.errors.results : "") : ""}
        value={formik.values.results}
        handleChange={formik.handleChange}
        label="Results"
        name="results"
      />
    </div>
  );
};
