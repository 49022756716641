import { AppBar, Toolbar, Box } from "@mui/material";
import React, { FC, ReactNode } from "react";
import "./TopBar.css";

interface TopBarProps {
  children: ReactNode;
}

export const TopBar: FC<TopBarProps> = ({ children }) => {
  return (
    <Box className="Topbar-borderBottom">
      <AppBar
        color="secondary"
        position="static"
        sx={{
          backgroundColor: "white",
          boxShadow: "0",
          color: "black",
          height: "90px",
        }}
      >
        <Toolbar
          sx={{
            height: "100%",
            verticalAlign: "center",
            paddingLeft: "24px",
            paddingRight: "24px",
          }}
        >
          {children}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
