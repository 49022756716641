import { Box } from "@mui/material";
import React, { FC } from "react";
import { GoogleSignInButton } from "../../components/GoogleSignInButton/GoogleSignInButton";
import "./LoginPage.css";

export const LoginPage: FC = () => {
  return (
    <Box
      className="LoginPage-container"
      sx={{
        backgroundImage: `url(https://kodly-assets.s3.eu-west-1.amazonaws.com/global/blackBackground.png)`,
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <img
        src="https://kodly-assets.s3.eu-west-1.amazonaws.com/global/kodly.png"
        alt="Kodly Innovative Thinking"
        className="LoginPage-kodly"
      />
      <GoogleSignInButton />
    </Box>
  );
};
