import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CategoryService from "../../services/mkt/Category.service";
import { Category } from "../../types/mkt/Category";
import { CategoryState } from "../../types/mkt/CategoryState";

const categorySlice = createSlice({
  name: "categories",
  initialState: {
    loading: false,
    categories: [],
    error: null,
    created: false,
  } as CategoryState,
  reducers: {
    setCreated(state, action) {
      state.created = action.payload;
    },
    setCategories(state, action) {
      state.categories = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.categories = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getCategories.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(searchCategory.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(searchCategory.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.categories = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(searchCategory.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(createCategory.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.created = false;
      }
    });
    builder.addCase(createCategory.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.created = true;
      }
    });
    builder.addCase(createCategory.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

export const getCategories = createAsyncThunk("blogPost/categories", async () => {
  const response = await CategoryService.getCategories();
  return response.categories;
});

export const searchCategory = createAsyncThunk(
  "blogPost/category/search",
  async (data: { name: string }) => {
    const response = await CategoryService.searchCategory(data.name);
    return response.categories;
  }
);

export const createCategory = createAsyncThunk(
  "blogPost/category/create",
  async (data: { category: Category }) => {
    const response = await CategoryService.createCategory(data.category);
    return response.category;
  }
);

export default categorySlice.reducer;
export const { setCreated, setCategories } = categorySlice.actions;
