import * as yup from "yup";
import { REGEX } from "../../constants/regex.const";

export const userValidation = yup.object({
  biography: yup
    .string()
    .required("Biography is required")
    .matches(REGEX.NO_FULL_WHITE_SPACE, "Biography is not valid"),
  secondaryEmail: yup
    .string()
    .matches(REGEX.EMAIL, "Please insert a valid email")
    .optional()
    .nullable(),
  image: yup.string().required("Profile image is required"),
  phoneNumber: yup
    .string()
    .matches(REGEX.ONLY_NUMBERS, "Only numbers are valid")
    .min(9, "Min 9 numbers")
    .max(9, "Max 9 numbers")
    .optional()
    .nullable(),
});
