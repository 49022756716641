import React, { FC, useEffect, useState } from "react";
import { deleteImage } from "../../../stores/Image.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../stores/store";
import { FileDownloadButton } from "./FileDownloadButton/FileDownloadButton";
import { FileUploadButtonAddMode } from "./FileUploadButtonAddMode/FileUploadButtonAddMode";

interface FileUploadButtonProps {
  title: string;
  buttonTitle: string;
  name?: string;
  image: string;
  formik: any;
  folder: string;
  target: string;
}

export const FileUploadButton: FC<FileUploadButtonProps> = ({
  title,
  buttonTitle,
  name,
  image,
  formik,
  target,
  folder,
}) => {
  const dispatch = useTypedDispatch();
  const [uploadedFileURL, setUploadedFileURL] = useState<string>();
  const [fileName, setFileName] = useState<string>();

  useEffect(() => {
    if (image) {
      setUploadedFileURL(image);
      return;
    }
    setUploadedFileURL(undefined);
  }, [image, fileName]);

  useEffect(() => {}, [fileName]);

  const onFileUpload = async (url: string) => {
    setUploadedFileURL(url);
    const name = url.substring(url.lastIndexOf("/") + 1);
    setFileName(name);
  };

  const onDeleteButtonPress = async (e: any) => {
    await deleteFile();
  };

  const deleteFile = async () => {
    if (uploadedFileURL) {
      dispatch(
        deleteImage({
          key: folder + "/" + name,
        })
      );
      formik.setFieldValue(name || target, undefined);
      setUploadedFileURL(undefined);
      setFileName(undefined);
    }
  };

  return uploadedFileURL == "" || uploadedFileURL == undefined ? (
    <div className="FileUploadButton-container">
      <FileUploadButtonAddMode
        title={title}
        buttonTitle={buttonTitle}
        formik={formik}
        folder={folder}
        target={target}
        onFileUpload={onFileUpload}
      />
    </div>
  ) : (
    <FileDownloadButton
      title={title}
      fileURL={uploadedFileURL}
      onDeleteButtonPress={onDeleteButtonPress}
    />
  );
};
