import React, { FC } from "react";
import { Box } from "@mui/material";
import "./CVPersonalInfo.css";
import { Input } from "../../../Inputs/Input";
import { ImageInputSquare } from "../../../ImageInputs/ImageInputSquare/ImageInputSquare";
import { FormikValues } from "formik";
import { formikTrimStrings } from "../../../../utils/string.utils";
import { useTypedSelector, RootState } from "../../../../stores/store";

interface PersonalInfoProps {
  formik: FormikValues;
}

export const CVPersonalInfo: FC<PersonalInfoProps> = ({ formik }) => {
  const { email } = useTypedSelector((state: RootState) => state.auth);
  return (
    <Box id="container" className="CV-formContainer">
      <div className="CVCreateEdit-image">
        <ImageInputSquare
          title="Image"
          formik={formik}
          buttonTitle="Upload your profile image"
          name="personalInfo.profileImage"
          image={formik.values.personalInfo?.profileImage}
          error={formik.errors.personalInfo?.profileImage}
          target="image"
          folder="team"
          height="60vh"
          width="20vw"
          acceptType={null}
        />
      </div>
      <div className="CVCreateEdit-containerRightSide">
        <div className="CVCreateEdit-row">
          <Input
            error={formik.errors?.personalInfo?.firstName}
            value={formik.values.personalInfo?.firstName}
            handleChange={formik.handleChange}
            handleBlur={(event) => formikTrimStrings(formik, event)}
            label="First Name"
            name="personalInfo.firstName"
            type="text"
            width="100%"
            height="2rem"
          />
          <Input
            error={formik.errors?.personalInfo?.lastName}
            value={formik.values.personalInfo?.lastName}
            handleChange={formik.handleChange}
            handleBlur={(e) => formikTrimStrings(formik, e)}
            label="Last Name"
            name="personalInfo.lastName"
            type="text"
            width="100%"
            height="2rem"
          />
        </div>
        <div className="CVCreateEdit-row">
          <Input
            error={formik.errors?.personalInfo?.professionalTitle}
            value={formik.values.personalInfo?.professionalTitle}
            handleChange={formik.handleChange}
            handleBlur={(e) => formikTrimStrings(formik, e)}
            label="Professional Title"
            name="personalInfo.professionalTitle"
            type="text"
            width="100%"
            height="2rem"
          />
          <Input
            error={formik.errors.email}
            value={email}
            handleChange={formik.handleChange}
            handleBlur={(e) => formikTrimStrings(formik, e)}
            label="Email"
            name="email"
            type="text"
            width="100%"
            height="2rem"
            disabled={true}
          />
        </div>
        <div className="CVCreateEdit-row">
          <Input
            error={formik.errors?.personalInfo?.summary}
            value={formik.values.personalInfo?.summary}
            handleChange={formik.handleChange}
            handleBlur={(e) => formikTrimStrings(formik, e)}
            label="Summary"
            name="personalInfo.summary"
            type="text"
            multiline
            maxRows={6}
            width="100%"
            height="9em"
            placeholder="Describe what you have been doing for the last few years. If you are a senior or if you have professional experience describe the main projects you were involved in. If you are a Junior, describe your academic experiences, academic works, and aspirations."
          />
        </div>
        <div className="CVCreateEdit-row">
          <Input
            error={formik.errors?.interest?.description}
            value={formik.values?.interest?.description}
            handleChange={formik.handleChange}
            handleBlur={(e) => formikTrimStrings(formik, e)}
            label="Interests"
            name="interest.description"
            type="text"
            multiline={true}
            maxRows={6}
            width="100%"
            height="9em"
            placeholder="Describe other personal experiences that you had, for example, erasmus experience, associative experiences. Also describe your hobbies."
          />
        </div>
      </div>
    </Box>
  );
};
