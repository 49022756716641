import React, { FC } from "react";

export const Vacations: FC = () => {
  return (
    <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.7742 10.1562H20.0323V1.5625C20.4774 1.5625 20.7742 1.25 20.7742 0.78125C20.7742 0.3125 20.4774 0 20.0323 0H19.2903H14.8387H14.0968C13.6516 0 13.3548 0.3125 13.3548 0.78125C13.3548 1.25 13.6516 1.5625 14.0968 1.5625V10.1562H13.3548C12.0935 10.1562 11.129 11.1719 11.129 12.5V14.8438H23V12.5C23 11.1719 22.0355 10.1562 20.7742 10.1562ZM15.5806 1.5625H18.5484V10.1562H15.5806V1.5625Z"
        fill="white"
      />
      <path
        d="M11.129 22.6562C11.129 23.9844 12.0935 25 13.3548 25H20.7742C22.0355 25 23 23.9844 23 22.6562V16.4062H11.129V22.6562Z"
        fill="white"
      />
      <path
        d="M0 15.625V22.6562C0 23.9844 0.964516 25 2.22581 25H3.70968V13.2812H2.22581C0.964516 13.2812 0 14.2969 0 15.625Z"
        fill="white"
      />
      <path d="M9.64522 13.2812H5.1936V25H9.64522V13.2812Z" fill="white" />
      <path
        d="M8.90328 8.59375H5.93554C5.49038 8.59375 5.1936 8.90625 5.1936 9.375V11.7188H6.67747V10.1562H8.16135V11.7188H9.64522V9.375C9.64522 8.90625 9.34844 8.59375 8.90328 8.59375Z"
        fill="white"
      />
    </svg>
  );
};
