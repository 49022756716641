import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { emptyClient } from "../../data/mkt/client";
import ClientService from "../../services/mkt/Client.service";
import { Client } from "../../types/mkt/Client";
import { ClientState } from "../../types/mkt/ClientState";

const clientSlice = createSlice({
  name: "clients",
  initialState: {
    loading: false,
    error: null,
    clients: [],
    client: emptyClient,
    created: false,
    edited: false,
    deleted: false,
    publishChange: false,
  } as ClientState,
  reducers: {
    setCreated(state, action) {
      state.created = action.payload;
    },
    setEdited(state, action) {
      state.edited = action.payload;
    },
    setDeleted(state, action) {
      state.deleted = action.payload;
    },
    setPublishState(state, action) {
      state.publishChange = action.payload;
    },
    setClients(state, action) {
      state.clients = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClients.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getClients.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.clients = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getClients.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(getClient.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getClient.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.client = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getClient.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(createClient.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.created = false;
      }
    });
    builder.addCase(createClient.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.created = true;
      }
    });
    builder.addCase(createClient.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(editClient.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.edited = false;
      }
    });
    builder.addCase(editClient.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.edited = true;
      }
    });
    builder.addCase(editClient.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(deleteClient.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.deleted = false;
      }
    });
    builder.addCase(deleteClient.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.deleted = true;
      }
    });
    builder.addCase(deleteClient.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(publishClient.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.publishChange = false;
      }
    });
    builder.addCase(publishClient.fulfilled, (state, action) => {
      state.loading = false;
      state.publishChange = true;
      state.client = action.payload;
    });
    builder.addCase(publishClient.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

export const getClients = createAsyncThunk("clients/getClients", async () => {
  const response = await ClientService.getClients();
  return response.clients.sort((a, b) => a.order - b.order);
});

export const getClient = createAsyncThunk("clients/getById", async (client: { id: string }) => {
  const response = await ClientService.getClient(client.id);
  return response.client;
});

export const createClient = createAsyncThunk("clients/create", async (data: { client: Client }) => {
  const response = await ClientService.createClient(data.client);
  return response.client;
});

export const editClient = createAsyncThunk("clients/edit", async (data: { client: Client }) => {
  const response = await ClientService.editClient(data.client);
  return response.client;
});

export const deleteClient = createAsyncThunk("clients/delete", async (data: { id: string }) => {
  await ClientService.deleteClient(data.id);
  return;
});

export const publishClient = createAsyncThunk(
  "clients/publish",
  async (data: { id: string; publish: boolean }) => {
    const response = data.publish
      ? await ClientService.unpublishClient(data.id)
      : await ClientService.publishClient(data.id);
    return response.client;
  }
);

export default clientSlice.reducer;
export const { setCreated, setEdited, setDeleted, setClients } = clientSlice.actions;
