import React, { FC } from "react";
import { Box, Button } from "@mui/material";
import "./CVProfessionalExperiences.css";
import { CVProfessionalExperience } from "./CVProfessionalExperience/CVProfessionalExperience";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { FormikValues } from "formik";
import { ProfessionalExperience } from "../../../../types/internal/cv/ProfessionalExperience";
import { emptyProfessionalExperienceSingle } from "../../../../data/internal/cv/professionalExperience";

interface CVProfessionalExperiences {
  formik: FormikValues;
}

export const CVProfessionalExperiences: FC<CVProfessionalExperiences> = ({ formik }) => {
  let formikProfessionalExperiences = [...formik.values.experience];

  const addProfessionalExperience = () => {
    formikProfessionalExperiences = [
      ...formik.values.experience,
      emptyProfessionalExperienceSingle,
    ];
    formik.setValues(
      {
        ...formik.values,
        experience: formikProfessionalExperiences,
      },
      true
    );
  };

  const handleDelete = (index: number) => {
    let aux = [...formik.values.experience];
    aux.splice(index, 1);
    formik.setFieldValue("experience", aux);
    formikProfessionalExperiences = aux;
  };

  const handleChangeCurrentProject = (indexProjectSelected: number, isCurrent: boolean) => {
    formik.setValues(
      {
        ...formik.values,
        experience: formikProfessionalExperiences.map(
          (prof: ProfessionalExperience, index: number) => {
            if (indexProjectSelected === index) {
              return { ...prof, actualProject: isCurrent, endDate: undefined };
            } else {
              return { ...prof };
            }
          }
        ),
      },
      true
    );
  };

  return (
    <Box id="container" className="ProfessionalExperiences-formContainer">
      <Box display={"flex"} justifyContent="center">
        <Button
          sx={{
            backgroundColor: "white",
            color: "black",
            border: "1px solid black",
            margin: "0em 0em 2em 0em",
          }}
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            addProfessionalExperience();
          }}
        >
          Add a New Experience
        </Button>
      </Box>
      <Box className="CVProfessionalExperiences-dropdowns">
        {formikProfessionalExperiences.map(
          (professionalExp: ProfessionalExperience, index: number) => (
            <CVProfessionalExperience
              formik={formik}
              title={
                formik.values.experience[index]?.professionalTitle +
                " - " +
                formik.values.experience[index]?.companyName
              }
              key={index}
              index={index}
              handleDelete={handleDelete}
              length={formikProfessionalExperiences.length}
              handleChangeCurrentProject={handleChangeCurrentProject}
            />
          )
        )}
      </Box>
    </Box>
  );
};
