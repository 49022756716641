import React, { FC } from "react";
import { BlogPost } from "../../../../types/mkt/BlogPost";
import { renderHtml } from "../../../../utils/renderHtml";
import { BlogPostDetailsHeader } from "./BlogPostDetailsHeader/BlogPostDetailsHeader";

import "./BlogPostDetails.css";

interface BlogPostDetailsProps {
  blogPost: BlogPost;
}

export const BlogPostDetails: FC<BlogPostDetailsProps> = ({ blogPost }) => {
  return (
    <div>
      <BlogPostDetailsHeader
        title={blogPost.title}
        subtitle={blogPost.subtitle}
        image={blogPost.thumbnail}
        date={blogPost.date}
        readTime={blogPost.readTime + " min."}
        author={blogPost.author}
        categories={blogPost.categories}
      />

      <section className="BlogPostDetails-container">
        <div
          dangerouslySetInnerHTML={renderHtml(blogPost.content)}
          className="BlogPostDetails-contentContainer"
        />
      </section>
    </div>
  );
};
