import React, { FC } from "react";
import "./ExpenseDetailsKeyValueItem.css";

interface ExpenseDetailsKeyValueItemProps {
  keyTitle: string;
  value: any;
  maxLines?: number;
}

export const ExpenseDetailsKeyValueItem: FC<ExpenseDetailsKeyValueItemProps> = ({
  keyTitle,
  value,
  maxLines,
}) => {
  const getValueClassName = () => {
    if (maxLines == null || maxLines == 0) {
      return "ExpenseDetailsKeyValueItem-value";
    } else {
      return "ExpenseDetailsKeyValueItem-multiLineValue";
    }
  };

  return (
    <div className="ExpenseDetailsKeyValueItem-container">
      <div className="ExpenseDetailsKeyValueItem-key">{keyTitle}</div>
      <div className={getValueClassName()}>{value}</div>
    </div>
  );
};
