import React from "react";
import { SidebarItem } from "../../types/SidebarItem";
import { Blog } from "../../assets/svg/Blog";
import { Careers } from "../../assets/svg/Careers";
import { CaseStudies } from "../../assets/svg/CaseStudies";
import { Persons } from "../../assets/svg/Persons";
import { Services } from "../../assets/svg/Services";
import { Testimonials } from "../../assets/svg/Testimonials";

export const sidebarItems: SidebarItem[] = [
  {
    text: "Careers",
    icon: <Careers />,
  },
  {
    text: "Services",
    icon: <Services />,
  },
  {
    text: "Team Members",
    icon: <Persons />,
  },
  {
    text: "Blog Posts",
    icon: <Blog />,
  },
  {
    text: "Case Studies",
    icon: <CaseStudies />,
  },
  {
    text: "Testimonials",
    icon: <Testimonials />,
  },
  {
    text: "Clients",
    icon: <Persons />,
  },
];
