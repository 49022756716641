import React, { FC } from "react";

export const CurriculumVitae: FC = () => {
  return (
    <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8329 23.7492C15.6102 23.7492 15.6102 25 14.8329 25H0.5909C0.264531 25 0 24.7198 0 24.3745V5.54176C0 5.36909 0.0661327 5.21247 0.172918 5.09948L4.81938 0.183271C4.93476 0.0611914 5.08592 0 5.23708 0H18.6036C18.93 0 19.1948 0.280208 19.1948 0.625545V11.5721C19.1948 12.3949 18.0127 12.3949 18.0127 11.5721V1.25079H5.48186L1.18209 5.80076V23.7492H14.8329ZM4.77816 18.2365C4.0006 18.2365 4.0006 16.9858 4.77816 16.9858H13.5578C14.3353 16.9858 14.3353 18.2365 13.5578 18.2365H4.77816ZM4.77816 9.91573C4.0006 9.91573 4.0006 8.66494 4.77816 8.66494H13.5578C14.3353 8.66494 14.3353 9.91573 13.5578 9.91573H4.77816ZM4.77816 12.6893C4.0006 12.6893 4.0006 11.4385 4.77816 11.4385H13.5578C14.3353 11.4385 14.3353 12.6893 13.5578 12.6893H4.77816ZM4.77816 15.4629C4.0006 15.4629 4.0006 14.2121 4.77816 14.2121H13.5578C14.3353 14.2121 14.3353 15.4629 13.5578 15.4629H4.77816ZM4.77816 7.14212C4.0006 7.14212 4.0006 5.89133 4.77816 5.89133H13.5578C14.3353 5.89133 14.3353 7.14212 13.5578 7.14212H4.77816ZM18.9944 14.3594C20.4213 14.3594 21.5779 15.5832 21.5779 17.093C21.5779 17.6413 21.4253 18.1517 21.1627 18.5795C22.2383 19.3347 22.9226 20.5812 22.9987 21.948C23.0442 22.7677 21.8664 22.8407 21.8212 22.0213C21.7608 20.9402 21.1739 19.9772 20.2715 19.4695C19.8948 19.6966 19.459 19.8263 18.9944 19.8263C18.4917 19.8263 18.0224 19.6745 17.6256 19.4116C16.6683 19.8999 16.0339 20.8972 15.9712 22.0213C15.9257 22.8407 14.7482 22.7677 14.7937 21.948C14.8736 20.5188 15.6168 19.2253 16.7714 18.4862C16.5426 18.0781 16.4112 17.6019 16.4112 17.093C16.4112 15.5835 17.5678 14.3594 18.9944 14.3594ZM18.9944 15.6102C18.2206 15.6102 17.5933 16.2742 17.5933 17.093C17.5933 17.9118 18.2206 18.5755 18.9944 18.5755C19.7682 18.5755 20.3958 17.9118 20.3958 17.093C20.3958 16.2742 19.7685 15.6102 18.9944 15.6102Z"
        fill="white"
      />
    </svg>
  );
};
