import { HTTP_CODES } from "../../constants/httpstatus.const";
import { emptyCaseStudy } from "../../data/mkt/caseStudy";
import { emptyTeamMember } from "../../data/mkt/teamMember";
import { CaseStudy } from "../../types/mkt/CaseStudy";
import { TeamMember } from "../../types/mkt/TeamMember";
import { apiClient, getAuthConfig } from "../HttpClient";

const CASE_STUDY_URL = "/caseStudy";
const CASE_STUDY_BY_ID = (id: string) => CASE_STUDY_URL + "/" + id;
const CASE_STUDY_PUBLISH = (id: string) => `${CASE_STUDY_URL}/${id}/publish`;
const CASE_STUDY_UNPUBLISH = (id: string) => `${CASE_STUDY_URL}/${id}/unpublish`;

export default class CaseStudyService {
  static getCaseStudies = async () => {
    try {
      const response = await apiClient.get(CASE_STUDY_URL, getAuthConfig());
      const caseStudies: CaseStudy[] = response.data;
      return {
        caseStudies,
        code: response.status,
      };
    } catch (err: any) {
      return {
        caseStudies: [],
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static getCaseStudy = async (id: string) => {
    try {
      const response = await apiClient.get(CASE_STUDY_BY_ID(id), getAuthConfig());
      const caseStudy: CaseStudy = response.data;
      return {
        caseStudy,
        code: response.status,
      };
    } catch (err: any) {
      return {
        caseStudy: emptyCaseStudy,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static createCaseStudy = async (caseStudy: CaseStudy) => {
    try {
      const response = await apiClient.post(CASE_STUDY_URL, caseStudy, getAuthConfig());
      return {
        caseStudy: response.data as CaseStudy,
        code: response.status,
      };
    } catch (err: any) {
      return {
        caseStudy: emptyCaseStudy,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static editCaseStudy = async (caseStudy: CaseStudy) => {
    try {
      const response = await apiClient.put(CASE_STUDY_URL, caseStudy, getAuthConfig());
      return {
        caseStudy: response.data as CaseStudy,
        code: response.status,
      };
    } catch (err: any) {
      return {
        caseStudy: emptyCaseStudy,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static deleteCaseStudy = async (id: string) => {
    try {
      const response = await apiClient.delete(CASE_STUDY_BY_ID(id), getAuthConfig());
      return {
        code: response.status,
      };
    } catch (err: any) {
      return {
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static publishCaseStudy = async (id: string) => {
    try {
      const response = await apiClient.put(CASE_STUDY_PUBLISH(id), {}, getAuthConfig());
      return {
        caseStudy: response.data as CaseStudy,
        code: response.status,
      };
    } catch (err: any) {
      return {
        caseStudy: emptyCaseStudy,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static unpublishCaseStudy = async (id: string) => {
    try {
      const response = await apiClient.put(CASE_STUDY_UNPUBLISH(id), {}, getAuthConfig());
      return {
        caseStudy: response.data as CaseStudy,
        code: response.status,
      };
    } catch (err: any) {
      return {
        caseStudy: emptyCaseStudy,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };
}
