import { HTTP_CODES } from "../constants/httpstatus.const";
import { emptyImage } from "../data/mkt/image";
import { Image } from "../types/Image";
import { apiClient, buildConfig, buildConfigAmazonS3, getAuthConfig } from "./HttpClient";

const AMAZONS3_URL = "/aws?";
const AMAZONS3_URL_PUT = (folder: string) => AMAZONS3_URL + "folder=" + folder;
const AMAZONS3_BY_KEY = (key: string) => AMAZONS3_URL + "key=" + key;

export default class AmazonS3Service {
  static uploadImage = async (file: FormData, folder: string) => {
    try {
      const response = await apiClient.put(AMAZONS3_URL_PUT(folder), file, buildConfigAmazonS3());
      return {
        image: response.data as Image,
        code: response.status,
      };
    } catch (err: any) {
      return {
        image: emptyImage,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static deleteImage = async (key: string) => {
    try {
      const response = await apiClient.delete(AMAZONS3_BY_KEY(key), getAuthConfig());
      return {
        code: response.status,
      };
    } catch (err: any) {
      return {
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };
}
