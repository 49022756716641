import { Grid } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Page } from "../../../components/mkt/Global/Pages/Page";
import { pages } from "../../../data/mkt/pageContent";
import {
  editTestimonial,
  getTestimonials,
  deleteTestimonial,
  publishTestimonial,
} from "../../../stores/mkt/Testimonial.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../stores/store";
import { TestimonialDnD } from "../../../components/mkt/Testimonial/TestimonialLists/TestimonialDnD/TestimonialDnD";
import { TestimonialGrid } from "../../../components/mkt/Testimonial/TestimonialLists/TestimonialGrid/TestimonialGrid";
import { useNavigate } from "react-router-dom";

export const TestimonialsPage: FC = observer(() => {
  const dispatch = useTypedDispatch();
  const { open } = useTypedSelector((state: RootState) => state.sidebar);
  const { testimonials, loading, error, deleted } = useTypedSelector(
    (state: RootState) => state.testimonials
  );
  const [dndEnabled, setDndEnabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  const dndAction = () => {
    for (let index = 0; index < testimonials.length; index++) {
      dispatch(
        editTestimonial({
          testimonial: { ...testimonials[index], order: index },
        })
      );
    }
    setDndEnabled(false);
  };

  const fetchTestimonial = async () => {
    await dispatch(getTestimonials()).then(() => {
      setLoaded(true);
    });
  };

  async function cancelDndAction() {
    setDndEnabled(false);
    setLoaded(false);
    fetchTestimonial();
  }

  useEffect(() => {
    fetchTestimonial();
  }, [deleted]);

  function editAction(id: string) {
    navigate(`/testimonials/${id}/edit`);
  }

  async function publishAction(id: string, publish: boolean) {
    await dispatch(publishTestimonial({ id: id!!, publish: publish }));
    dispatch(getTestimonials());
  }

  async function deleteAction(id: string) {
    await dispatch(deleteTestimonial({ id: id!! }));
  }

  return (
    <Page
      pageContent={pages.testimonials}
      search={(term) => {}}
      dndEnabled={dndEnabled}
      dndAction={dndAction}
      cancelDndAction={cancelDndAction}
      setDndEnabled={setDndEnabled}
    >
      {loaded ? (
        dndEnabled ? (
          <TestimonialDnD testimonials={testimonials} />
        ) : (
          <TestimonialGrid
            testimonials={testimonials}
            editAction={editAction}
            publishAction={publishAction}
            deleteAction={deleteAction}
          />
        )
      ) : null}
    </Page>
  );
});
