import React, { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { deleteClient, publishClient } from "../../../../../stores/mkt/Client.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../../stores/store";
import { Client } from "../../../../../types/mkt/Client";
import { ImageOnlyCard } from "../../../Global/Cards/ImageOnlyCard/ImageOnlyCard";

interface ClientCardProps {
  client: Client;
  registerPublish: any;
  registerDelete: any;
  showHoverShadow: boolean;
}

export const ClientCard: FC<ClientCardProps> = ({ client, registerPublish, registerDelete }) => {
  return (
    <ImageOnlyCard
      id={client.id}
      imageURL={client.image}
      publish={client.publish}
      registerPublish={registerPublish}
      registerDelete={registerDelete}
      enableHover={false}
      duplicateAction={null}
    />
  );
};
