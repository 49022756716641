import Box from "@mui/material/Box";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import React, { FC, useEffect } from "react";
import { Page } from "../../../components/internal/Global/Pages/Page";
import { VacationsControlTable } from "../../../components/internal/Vacations/VacationsControl/VacationsControlTable";
import { API_REQUEST_SETTINGS } from "../../../constants/internal/apiRequestSettings.const";
import { SubmissionStatus } from "../../../data/common/SubmissionStatus";
import { getVacationsByApprovers, updateStatus } from "../../../stores/internal/Vacation.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../stores/store";
import { Status } from "../../../types/common/Status";
import { Vacation } from "../../../types/internal/vacation/Vacation";

export var vacationsByApproverPreviousTablePage = 0;

export const VacationsControlPage: FC = () => {
  const dispatch = useTypedDispatch();
  const { email } = useTypedSelector((state: RootState) => state.auth);
  const { vacationsByApprover, statusUpdated } = useTypedSelector(
    (state: RootState) => state.vacation
  );
  const [tablePage, setTablePage] = React.useState(0);
  const [statusChanged, setStatusChanged] = React.useState(false);

  useEffect(() => {
    triggerSetupRequests();
  }, []);

  useEffect(() => {
    if (checkIsNotStarterPageFirstVisualization()) {
      triggerSetupRequests();
    }
  }, [tablePage]);

  useEffect(() => {
    if (statusChanged == true) {
      triggerSetupRequests();
      setStatusChanged(false);
    }
  }, [statusChanged]);

  const triggerSetupRequests = async () => {
    await requestGetVacationsByApprover();
  };

  const checkIsNotStarterPageFirstVisualization = () => {
    return tablePage != 0 || vacationsByApproverPreviousTablePage > tablePage;
  };

  const requestGetVacationsByApprover = async () => {
    await dispatch(
      getVacationsByApprovers({
        employeeEmail: email,
        page: tablePage,
        size: API_REQUEST_SETTINGS.GET_VACATIONS_BY_APPROVERS_TABLE_SIZE,
      })
    );
  };

  const requestUpdateVacationStatus = async (vacationId: string, status: Status) => {
    await dispatch(
      updateStatus({
        vacationId,
        status,
      })
    ).then(() => {
      setStatusChanged(true);
    });
  };

  const onPaginationChange = (newPage: number) => {
    const numberOfItemsPerPage = API_REQUEST_SETTINGS.GET_VACATIONS_TABLE_SIZE;
    if (newPage > tablePage && vacationsByApprover.vacations.length < numberOfItemsPerPage) {
      return;
    }
    vacationsByApproverPreviousTablePage = tablePage;
    setTablePage(newPage);
  };

  const onApproveVacationPress = (vacationId: string) => {
    requestUpdateVacationStatus(vacationId, { status: SubmissionStatus.APPROVED });
  };

  const onRejectVacationPress = (vacationId: string) => {
    requestUpdateVacationStatus(vacationId, { status: SubmissionStatus.REJECTED });
  };

  return (
    <Page pageContent={null} backgroundColor={"#fff"}>
      <Box paddingTop="2em">
        <VacationsControlTable
          vacationsPaginated={vacationsByApprover}
          onPaginationChange={onPaginationChange}
          tablePage={tablePage}
          onApproveVacationPress={onApproveVacationPress}
          onRejectVacationPress={onRejectVacationPress}
        />
      </Box>
    </Page>
  );
};
