export const emptyProfessionalExperienceSingle = {
  startDate: undefined,
  actualProject: false,
  endDate: undefined,
  companyName: "",
  professionalTitle: "",
  description: "",
  technologiesUsed: "",
};

export const emptyProfessionalExperience = [emptyProfessionalExperienceSingle];
