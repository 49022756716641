import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { emptyImage } from "../data/mkt/image";
import AmazonS3Service from "../services/AmazonS3.service";
import { ImageState } from "../types/ImageState";

const imageSlice = createSlice({
  name: "image",
  initialState: {
    loading: false,
    image: emptyImage,
    error: null,
    created: false,
    deleted: false,
  } as ImageState,
  reducers: {
    setCreated(state, action) {
      state.created = action.payload;
    },
    setDeleted(state, action) {
      state.deleted = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadImage.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.created = false;
      }
    });
    builder.addCase(uploadImage.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.created = true;
        state.image = action.payload;
      }
    });
    builder.addCase(uploadImage.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(deleteImage.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.deleted = false;
      }
    });
    builder.addCase(deleteImage.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.deleted = true;
        state.image = emptyImage;
      }
    });
    builder.addCase(deleteImage.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

export const uploadImage = createAsyncThunk(
  "amazonS3/upload",
  async (data: { file: File; folder: string }) => {
    let image = new FormData();
    image.append("file", data.file);
    const response = await AmazonS3Service.uploadImage(image, data.folder);
    return response.image;
  }
);

export const deleteImage = createAsyncThunk("amazonS3/delete", async (data: { key: string }) => {
  await AmazonS3Service.deleteImage(data.key);
  return;
});

export default imageSlice.reducer;
export const { setCreated, setDeleted } = imageSlice.actions;
