import React, { FC } from "react";

export const Blog: FC = () => {
  return (
    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3848 9.37521H21.9832C21.1251 9.37521 20.3722 8.64856 20.3131 7.81261C20.3131 3.35312 16.7178 0 12.2273 0H8.13002C3.64241 0 0.00320301 3.61425 0 8.0733V16.9313C0 21.3908 3.64245 25.0019 8.13002 25.0019H16.8797C21.3701 25.0019 25 21.3908 25 16.9313V11.2096C25 10.3187 24.2812 9.37611 23.3839 9.37611L23.3848 9.37517L23.3848 9.37521ZM7.81218 6.25044H12.5C13.3591 6.25044 14.0626 6.95401 14.0626 7.81304C14.0626 8.67211 13.3591 9.37568 12.5 9.37568L7.81218 9.37521C6.95315 9.37521 6.24958 8.67164 6.24958 7.81261C6.24958 6.95307 6.95315 6.24997 7.81218 6.24997V6.25044ZM17.1879 18.75H7.81218C6.95315 18.75 6.24958 18.0469 6.24958 17.1874C6.24958 16.3283 6.95315 15.6248 7.81218 15.6248L17.1879 15.6257C18.0469 15.6257 18.7505 16.3288 18.7505 17.1883C18.7505 18.0474 18.0469 18.7509 17.1879 18.7509V18.75Z"
        fill="white"
      />
    </svg>
  );
};
