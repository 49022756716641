import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { emptyBlogPost } from "../../data/mkt/blogpost";
import BlogPostService from "../../services/mkt/BlogPost.service";
import { BlogPost } from "../../types/mkt/BlogPost";
import { BlogPostState } from "../../types/mkt/BlogPostState";
import { fullDate } from "../../utils/date";

const blogPostsSlice = createSlice({
  name: "blogPosts",
  initialState: {
    loading: false,
    error: null,
    blogPosts: [],
    blogPost: emptyBlogPost,
    created: false,
    edited: false,
    deleted: false,
  } as BlogPostState,
  reducers: {
    setCreated(state, action) {
      state.created = action.payload;
    },
    setEdited(state, action) {
      state.edited = action.payload;
    },
    setDeleted(state, action) {
      state.deleted = action.payload;
    },
    setBlogPosts(state, action) {
      state.blogPosts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBlogPosts.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getBlogPosts.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.blogPosts = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getBlogPosts.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(getBlogPost.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getBlogPost.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.blogPost = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getBlogPost.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(createBlogPost.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.created = false;
      }
    });
    builder.addCase(createBlogPost.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.created = true;
      }
    });
    builder.addCase(createBlogPost.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(editBlogPost.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.edited = false;
      }
    });
    builder.addCase(editBlogPost.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.edited = true;
      }
    });
    builder.addCase(editBlogPost.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(deleteBlogPost.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.deleted = false;
      }
    });
    builder.addCase(deleteBlogPost.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.deleted = true;
      }
    });
    builder.addCase(deleteBlogPost.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(publishBlogPost.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.deleted = false;
      }
    });
    builder.addCase(publishBlogPost.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.blogPost = action.payload;
      }
    });
    builder.addCase(publishBlogPost.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

export const getBlogPosts = createAsyncThunk("blogPost/get", async () => {
  const response = await BlogPostService.getBlogPosts();
  return response.blogPosts.sort((a, b) => a.order - b.order);
});

export const getBlogPost = createAsyncThunk("blogPost/getById", async (data: { id: string }) => {
  const response = await BlogPostService.getBlogPost(data.id);
  response.blogPost.readTime = response.blogPost.readTime.replace(" min.", "");
  return response.blogPost;
});

export const createBlogPost = createAsyncThunk(
  "blogPost/create",
  async (data: { blogPost: BlogPost }) => {
    const blogPost = {
      ...data.blogPost,
      readTime: data.blogPost.readTime + " min.",
      date: fullDate(new Date(data.blogPost.date)),
    };
    const response = await BlogPostService.createBlogPost(blogPost);
    return response.blogPost;
  }
);

export const editBlogPost = createAsyncThunk(
  "blogPost/edit",
  async (data: { blogPost: BlogPost }) => {
    const blogPost = {
      ...data.blogPost,
      readTime: data.blogPost.readTime + " min.",
      date: fullDate(new Date(data.blogPost.date)),
    };
    const response = await BlogPostService.editBlogPost(blogPost);
    return response.blogPost;
  }
);

export const deleteBlogPost = createAsyncThunk("blogPost/delete", async (data: { id: string }) => {
  await BlogPostService.deleteBlogPost(data.id);
  return;
});

export const publishBlogPost = createAsyncThunk(
  "blogPost/publish",
  async (data: { id: string; publish: boolean }) => {
    const response = data.publish
      ? await BlogPostService.unpublishBlogPost(data.id)
      : await BlogPostService.publishBlogPost(data.id);
    return response.blogPost;
  }
);

export default blogPostsSlice.reducer;
export const { setCreated, setEdited, setDeleted, setBlogPosts } = blogPostsSlice.actions;
