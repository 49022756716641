import React, { FC } from "react";
import { Tooltip } from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  Delete,
  Edit,
  MoreHoriz,
  ContentCopy,
  MoreVert,
} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import "./ButtonsCard.css";

interface ButtonsCardProps {
  id: string;
  publish: boolean;
  editAction: any;
  publishAction: any;
  deleteAction: any;
  duplicateAction: any;
}

export const ButtonsCard: FC<ButtonsCardProps> = ({
  id,
  publish,
  editAction,
  publishAction,
  deleteAction,
  duplicateAction,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="CardButtons-buttons">
      {editAction && (
        <Tooltip title="Edit">
          <div
            className="CardButtons-mainButtons"
            onClick={() => {
              editAction(id);
            }}
          >
            <Edit />
          </div>
        </Tooltip>
      )}
      {(publishAction || deleteAction || duplicateAction) && (
        <Tooltip
          title=""
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(event: any) => {
            handleClick(event);
          }}
        >
          <div className="CardButtons-mainButtons">
            <MoreVert />
          </div>
        </Tooltip>
      )}
      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {publishAction != null && (
          <MenuItem
            onClick={() => {
              publishAction(id, publish);
              handleClose();
            }}
          >
            {publish ? (
              <ListItemIcon className="CardButtons-unPublishButton">
                <VisibilityOff />
              </ListItemIcon>
            ) : (
              <ListItemIcon className="CardButtons-publishButton">
                <Visibility />
              </ListItemIcon>
            )}
            <Typography variant="inherit">{publish ? "Unpublish" : "Publish"}</Typography>
          </MenuItem>
        )}
        {duplicateAction && (
          <MenuItem
            className="CardButtons-menuItem"
            onClick={() => {
              duplicateAction(id);
              handleClose();
            }}
          >
            <ListItemIcon className="CardButtons-duplicateButton">
              <ContentCopy />
            </ListItemIcon>
            <Typography variant="inherit">Duplicate</Typography>
          </MenuItem>
        )}
        {deleteAction && (
          <MenuItem
            className="CardButtons-menuItem"
            onClick={() => {
              deleteAction(id);
              handleClose();
            }}
          >
            <ListItemIcon className="CardButtons-deleteButton">
              <Delete />
            </ListItemIcon>
            <Typography variant="inherit">Delete</Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
