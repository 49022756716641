import { set } from "date-fns";
import { useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Page } from "../../../../components/internal/Global/Pages/Page";
import { VacationsCreate } from "../../../../components/internal/Vacations/VacationsCreate/VacationsCreate";
import { SubmissionStatus } from "../../../../data/common/SubmissionStatus";
import { emptyVacation } from "../../../../data/internal/vacation";
import { getApprovers } from "../../../../stores/hr/Approver.store";
import { createVacation } from "../../../../stores/internal/Vacation.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { Vacation } from "../../../../types/internal/vacation/Vacation";
import { vacationSchema } from "../../../../utils/internal/vacation";

export const VacationsCreatePage: FC = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { email } = useTypedSelector((state: RootState) => state.auth);
  const { vacationsByYear } = useTypedSelector((state: RootState) => state.vacation);
  const [overlappingVacationErrorShow, setOverlappingVacationErrorShow] = useState(false);
  var createdInternalState = false;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...emptyVacation },
    onSubmit: () => {},
    validationSchema: vacationSchema,
  });

  useEffect(() => {
    dispatch(
      getApprovers({
        email: email,
      })
    );
  }, []);

  const navigateToVacationsSummary = () => {
    navigate(`/vacations`);
  };

  const validateVacation = () => {
    vacationsByYear.map((vacation) => {
      let vacationStartDate = new Date(vacation.startDate).getTime();
      let vacationEndDate = new Date(vacation.endDate).getTime();
      let newVacationStartDate = new Date(formik.values.startDate).getTime();
      let newVacationEndDate = new Date(formik.values.endDate).getTime();
      if (
        newVacationStartDate <= vacationEndDate &&
        newVacationEndDate >= vacationStartDate &&
        vacation.status != SubmissionStatus.REJECTED
      ) {
        setOverlappingVacationErrorShow(true);
      }
    });
  };

  const createVacationRequest = async () => {
    const newVacation: Vacation = {
      id: null,
      employee: email,
      startDate: formik.values.startDate,
      endDate: formik.values.endDate,
      status: formik.values.status,
      approvers: formik.values.approvers,
      total: formik.values.total,
    };
    formik.errors = await formik.validateForm(formik.values);
    validateVacation();
    if (!overlappingVacationErrorShow) {
      if (Object.keys(formik.errors).length == 0) {
        createdInternalState = true;
        await dispatch(createVacation({ vacation: newVacation })).then(() => {
          navigateToVacationsSummary();
        });
      }
    }
  };

  useEffect(() => {
    setOverlappingVacationErrorShow(false);
    validateVacation();
  }, [formik.values]);

  return (
    <Page pageContent={null} backgroundColor={"#fff"}>
      <VacationsCreate
        formik={formik}
        onCreateVacation={createVacationRequest}
        onCancel={navigateToVacationsSummary}
        overlappingVacationErrorShow={overlappingVacationErrorShow}
      />
    </Page>
  );
};
