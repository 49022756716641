import { emptyExpensesType } from "../internal/expensesType";
import { emptyWBS } from "./wbs";

export const emptyExpense = {
  id: "",
  employee: "",
  description: "",
  cost: "",
  attachment: "",
  submitted: "",
  status: "",
  approvers: [],
  wbs: emptyWBS,
  expensesType: emptyExpensesType,
};
