import { emptyChallenge } from "./challenge";
import { emptySolution } from "./solution";

export const emptyCaseStudy = {
  id: "",
  order: 0,
  title: "",
  subtitle: "",
  date: "",
  readTime: "",
  thumbnail: "",
  challenge: emptyChallenge,
  solution: emptySolution,
  results: "",
  publish: false,
  url: "",
  metaInfo: "",
  pageTitle: "",
  h1: "",
  h2: "",
};
