import React, { FC, useEffect } from "react";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { createClient, setCreated } from "../../../../stores/mkt/Client.store";
import { emptyClient } from "../../../../data/mkt/client";
import { clientValidationSchema } from "../../../../utils/mkt/client";
import { ClientCreateEdit } from "../../../../components/mkt/Client/ClientCreateEdit/ClientCreateEdit";
import { PageCreate } from "../../../../components/mkt/Global/Pages/PageCreate";

export const ClientCreatePage: FC = () => {
  const dispatch = useTypedDispatch();
  const { token } = useTypedSelector((state: RootState) => state.auth);
  const { created } = useTypedSelector((state: RootState) => state.clients);
  const navigate = useNavigate();

  useEffect(() => {
    if (created) {
      dispatch(setCreated(false));
      navigate("/clients");
    }
  }, [created]);

  const createAction = async () => {
    formik.errors = await formik.validateForm(formik.values);

    if (Object.keys(formik.errors).length > 0) {
      return;
    }

    dispatch(createClient({ client: formik.values }));
  };

  const formik = useFormik({
    initialValues: { ...emptyClient, order: 100 },
    onSubmit: (userInputData) => {},
    validationSchema: clientValidationSchema,
  });

  return (
    <PageCreate
      pageContent={{
        pageTitle: "Create Client",
        previousPage: "/clients",
        action: createAction,
        type: "Create",
        tabs: null,
        switchTabAction() {},
      }}
    >
      <ClientCreateEdit formik={formik} />
    </PageCreate>
  );
};
