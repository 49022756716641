import React, { FC } from "react";
import { HtmlInput } from "../../../../../../Inputs/HtmlInput";
import "./VacancyBenefitsCreate.css";

interface VacancyBenefitsCreateProps {
  formik: any;
}

export const VacancyBenefitsCreate: FC<VacancyBenefitsCreateProps> = ({ formik }) => {
  return (
    <div className="VacancyBenefitsCreate-benefitsInput">
      <HtmlInput
        error={formik.errors && formik.errors.benefits ? formik.errors.benefits : ""}
        value={formik.values.benefits}
        handleChange={formik.handleChange}
        label="Main Benefits"
        name="benefits"
      />
    </div>
  );
};
