import {
  getSubmissionStatusFromString,
  SubmissionStatus,
} from "../../data/common/SubmissionStatus";
import { User } from "../../types/internal/User";
import { Vacation } from "../../types/internal/vacation/Vacation";
import { differenceBetweenDates } from "../../utils/date";
import { MONTH_INDEX, SPECIFIC_DATES } from "../dates.const";

export const currentYear = new Date().getFullYear().toString();

export const VACATION_WBS_ID = "63904f42649ba1f410ff54bb";
export const VACATION_WBS_CODE_ALTERNATIVE = "Vacations";

export const APPROVED_DAYS_CATEGORIES = [SubmissionStatus.APPROVED];
export const SCHEDULED_DAYS_CATEGORIES = [
  SubmissionStatus.APPROVED,
  SubmissionStatus.PENDING_APPROVAL,
  SubmissionStatus.SUBMITTED,
];

export const computeVacationDaysGroupedByCategory = (
  vacationsForCurrentYear: Vacation[],
  category: SubmissionStatus[]
) => {
  if (!Array.isArray(vacationsForCurrentYear)) {
    return 0;
  }
  let sumDays = 0;
  vacationsForCurrentYear.map((vacation) => {
    if (doesVacationBelongToCategory(vacation, category)) {
      if (
        new Date(vacation.startDate).getUTCFullYear() != new Date(vacation.endDate).getUTCFullYear()
      ) {
        if (currentYear == new Date(vacation.startDate).getUTCFullYear().toString()) {
          sumDays =
            sumDays +
            differenceBetweenDates(
              new Date(vacation.startDate),
              new Date(
                new Date(vacation.startDate).getUTCFullYear(),
                MONTH_INDEX.DECEMBER,
                SPECIFIC_DATES.LAST_DAY_OF_DECEMBER
              )
            ).length;
        } else if (currentYear == new Date(vacation.endDate).getUTCFullYear().toString()) {
          sumDays =
            sumDays +
            differenceBetweenDates(
              new Date(
                new Date(vacation.endDate).getUTCFullYear(),
                MONTH_INDEX.JANUARY,
                SPECIFIC_DATES.FIRST_DAY_OF_JANUARY
              ),
              new Date(vacation.endDate)
            ).length;
        }
      } else sumDays = sumDays + vacation.total;
    }
  });
  return sumDays;
};

export const doesVacationBelongToCategory = (vacation: Vacation, category: SubmissionStatus[]) => {
  const currentYear = new Date().getFullYear().toString();
  const vacationSubmissionStatus = getSubmissionStatusFromString(vacation.status);
  return (
    category.includes(vacationSubmissionStatus) &&
    new Date(vacation.startDate).getFullYear().toString() == currentYear
  );
};

export const getAssignedVacationDaysForCurrentYear = (user: User) => {
  if (Array.isArray(user.vacations) && user.vacations.length) {
    let assignedVacationForCurrentYear = user.vacations.find(
      (vacation) => vacation.year == new Date().getFullYear().toString()
    );
    return assignedVacationForCurrentYear != undefined
      ? assignedVacationForCurrentYear.assignedDays
      : 0;
  }
  return 0;
};
