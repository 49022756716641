import * as yup from "yup";
import { TimeReportedByProject } from "../../types/internal/timeReport/TimeReportedByProject";

const timeReportedByDateValidationSchema = yup.object({
  date: yup.string(),
  reportedTime: yup.number().required("Reported Time is required"),
});

const timeReportedByProjectValidation = yup
  .object({
    wbs: yup.object().shape({
      code: yup
        .string()
        .required("WBS Code is required")
        .max(30, "Author name must be at most 30 characters"),
    }),
    timeReported: yup.array().of(timeReportedByDateValidationSchema),
  })
  .test("unique", "Project must be unique", function validateUnique(currentReportedTime) {
    const otherReportedTime = this.parent.filter(
      (reportedTime: TimeReportedByProject) =>
        reportedTime.timeReported !== currentReportedTime.timeReported
    );

    const isDuplicate = otherReportedTime.some(
      (otherReportedTime: TimeReportedByProject) =>
        otherReportedTime.wbs.code === currentReportedTime.wbs.code
    );

    return isDuplicate ? this.createError({ path: `${this.path}.name` }) : true;
  });

export const timeReportValidationSchema = yup.object({
  reportedTime: yup.array().of(timeReportedByProjectValidation),
});
