import { Box, IconButton } from "@mui/material";
import React, { FC } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Player } from "@lottiefiles/react-lottie-player";

import "./ImageInputSquareAfterAddIcon.css";

interface ImageInputSquareAfterAddIconProps {
  formik: any;
  image: string;
  name?: string;
  folder: string;
  target: string;
  height: string;
  width: string;
}

export const ImageInputSquareAfterAddIcon: FC<ImageInputSquareAfterAddIconProps> = ({
  formik,
  image,
  name,
  target,
  height,
  width,
}) => {
  const deleteFile = () => {
    if (image) {
      formik.setFieldValue(name || target, undefined);
    }
  };

  return (
    <Box>
      <Box
        className="ImageInputSquareAfterAddIcon-uploadContainer"
        style={{
          height: height,
          width: width,
        }}
      >
        <IconButton className="ImageInputSquareAfterAddIcon-deleteButton" onClick={deleteFile}>
          <ClearIcon className="ImageInputSquareAfterAddIcon-deleteIcon" />
        </IconButton>
        <Player src={image} loop autoplay />
      </Box>
    </Box>
  );
};
