import React, { FC } from "react";
import { Input } from "../../../../Inputs/Input";
import { InputDate } from "../../../../Inputs/InputDate";

import { ImageInputSquare } from "../../../../ImageInputs/ImageInputSquare/ImageInputSquare";

import "./BlogPostCreateEditSummary.css";

interface BlogPostCreateEditSummaryProps {
  formik: any;
}

export const BlogPostCreateEditSummary: FC<BlogPostCreateEditSummaryProps> = ({ formik }) => {
  return (
    <div className="BlogPostCreateEditSummary-container">
      <div className="BlogPostCreateEditSummary-container_leftSide">
        <ImageInputSquare
          title="Image"
          formik={formik}
          buttonTitle="Upload image"
          image={formik.values.thumbnail}
          error={formik.errors.thumbnail}
          target="thumbnail"
          folder="blogPosts"
          height="14em"
          width="25em"
          acceptType={null}
        />
      </div>

      <div className="BlogPostCreateEditSummary-container_rightSide">
        <Input
          error={formik.errors.title}
          value={formik.values.title}
          handleChange={formik.handleChange}
          label="Title"
          name="title"
          type="text"
          width="auto"
          height="2rem"
          maxLength={100}
        />

        <Input
          error={formik.errors.subtitle}
          value={formik.values.subtitle}
          handleChange={formik.handleChange}
          label="Subtitle"
          name="subtitle"
          type="text"
          width="auto"
          height="2rem"
          maxLength={200}
        />

        <div className="BlogPostCreateEditSummary-dateContainer">
          <Input
            error={formik.errors.readTime}
            value={formik.values.readTime}
            handleChange={formik.handleChange}
            label="Read Time (min.)"
            name="readTime"
            type="text"
            width="auto"
            height="2rem"
            maxLength={4}
          />

          <InputDate
            error={formik.errors.date}
            value={formik.values.date}
            handleChange={formik.handleChange}
            label="Date"
            name="date"
          />
        </div>
      </div>
    </div>
  );
};
