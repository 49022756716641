import React, { FC, useEffect, useState } from "react";
import { PageCreate } from "../../../../components/mkt/Global/Pages/PageCreate";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { editTeamMember, getTeamMember, setEdited } from "../../../../stores/mkt/TeamMember.store";
import { teamMemberValidationSchema } from "../../../../utils/mkt/teamMember";
import { TeamMemberCreateEdit } from "../../../../components/mkt/TeamMember/TeamMemberCreateEdit/TeamMemberCreateEdit";

export const TeamMemberEditPage: FC = () => {
  const dispatch = useTypedDispatch();
  const { teamMember, loading, error, edited } = useTypedSelector(
    (state: RootState) => state.teamMembers
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchTeamMember = async () => {
      await dispatch(getTeamMember({ id: id!! })).then(() => {
        setLoaded(true);
      });
    };
    // call the function
    fetchTeamMember();
  }, []);

  useEffect(() => {
    if (edited) {
      dispatch(setEdited(false));
      navigate(`/team_members`);
    }
  }, [edited]);

  const createAction = async () => {
    formik.errors = await formik.validateForm(formik.values);

    if (Object.keys(formik.errors).length > 0) {
      return;
    }

    dispatch(editTeamMember({ teamMember: formik.values }));
  };

  const formik = useFormik({
    initialValues: teamMember,
    onSubmit: (userInputData) => {},
    validationSchema: teamMemberValidationSchema,
    enableReinitialize: true,
  });

  return (
    <PageCreate
      pageContent={{
        pageTitle: "Edit Team Member",
        previousPage: `/team_members`,
        action: createAction,
        type: "Edit",
        tabs: null,
        switchTabAction() {},
      }}
    >
      {loaded ? <TeamMemberCreateEdit formik={formik} /> : null}
    </PageCreate>
  );
};
