import React, { FC } from "react";

export const CollapseLeft: FC = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5417 7.29166C13.7486 7.29046 13.9512 7.35089 14.1236 7.46527C14.296 7.57965 14.4304 7.74278 14.5098 7.93386C14.5891 8.12495 14.6098 8.33534 14.5691 8.5382C14.5284 8.74106 14.4282 8.92721 14.2813 9.07291L10.8438 12.5L14.2813 15.9271C14.4519 16.1263 14.5411 16.3827 14.531 16.6448C14.5208 16.907 14.4122 17.1557 14.2267 17.3412C14.0411 17.5267 13.7925 17.6354 13.5303 17.6455C13.2681 17.6557 13.0118 17.5665 12.8125 17.3958L8.64586 13.2292C8.45185 13.034 8.34295 12.77 8.34295 12.4948C8.34295 12.2196 8.45185 11.9556 8.64586 11.7604L12.8125 7.59374C13.0065 7.4013 13.2684 7.29281 13.5417 7.29166Z"
        fill="white"
      />
      <path
        d="M9.37498 11.4583L19.7916 11.4583C20.0679 11.4583 20.3329 11.5681 20.5282 11.7634C20.7236 11.9588 20.8333 12.2237 20.8333 12.5C20.8333 12.7763 20.7236 13.0412 20.5282 13.2366C20.3329 13.4319 20.0679 13.5417 19.7916 13.5417L9.37498 13.5417C9.09871 13.5417 8.83376 13.4319 8.63841 13.2366C8.44306 13.0412 8.33331 12.7763 8.33331 12.5C8.33331 12.2237 8.44306 11.9588 8.63841 11.7634C8.83376 11.5681 9.09871 11.4583 9.37498 11.4583ZM5.20831 4.16668C5.48458 4.16668 5.74953 4.27642 5.94488 4.47177C6.14023 4.66712 6.24998 4.93208 6.24998 5.20834L6.24998 19.7917C6.24998 20.0679 6.14023 20.3329 5.94488 20.5282C5.74953 20.7236 5.48458 20.8333 5.20831 20.8333C4.93205 20.8333 4.66709 20.7236 4.47174 20.5282C4.27639 20.3329 4.16665 20.0679 4.16665 19.7917L4.16665 5.20834C4.16665 4.93208 4.27639 4.66712 4.47174 4.47177C4.66709 4.27642 4.93205 4.16668 5.20831 4.16668Z"
        fill="white"
      />
    </svg>
  );
};
