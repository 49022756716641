import React, { FC, useRef } from "react";
import { Box, Button } from "@mui/material";
import "./CVTechnologyStack.css";
import { CVTechnology } from "./CVTechnology/CVTechnology";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { FormikValues } from "formik";
import { Technology } from "../../../../types/internal/cv/Technology";
interface CVTechnologyStackProps {
  formik: FormikValues;
}

export const CVTechnologyStack: FC<CVTechnologyStackProps> = ({ formik }) => {
  let formikTechStack = [...formik.values.techStack];
  const addTechnology = () => {
    formikTechStack = [
      ...formik.values.techStack,
      {
        technologyArea: "",
        technologies: "",
      },
    ];
    formik.setValues(
      {
        ...formik.values,
        techStack: formikTechStack,
      },
      true
    );
  };

  const handleDelete = (index: number) => {
    let aux = [...formik.values.techStack];
    aux.splice(index, 1);
    formik.setFieldValue("techStack", aux);
    formikTechStack = aux;
  };

  return (
    <Box className="Technology-formContainer">
      <Box display={"flex"} justifyContent="center">
        <Button
          sx={{
            backgroundColor: "white",
            color: "black",
            border: "1px solid black",
            margin: "0em 0em 2em 0em",
          }}
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            addTechnology();
          }}
        >
          Add a new Tech Stack
        </Button>
      </Box>
      <Box className="CVTechnology-dropdowns">
        {formikTechStack.map((tech: Technology, index: number) => (
          <CVTechnology
            formik={formik}
            title={formik.values.techStack[index]?.technologyArea}
            key={index}
            index={index}
            handleDelete={handleDelete}
            length={formikTechStack.length}
          />
        ))}
      </Box>
    </Box>
  );
};
