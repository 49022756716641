import { Box } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import "./ClientCreateEdit.css";
import { Input } from "../../../Inputs/Input";
import { ImageInputSquare } from "../../../ImageInputs/ImageInputSquare/ImageInputSquare";

interface ClientCreateEditProps {
  formik: any;
}

export const ClientCreateEdit: FC<ClientCreateEditProps> = ({ formik }) => {
  return (
    <div className="ClientCreateEdit-container">
      <div className="ClientCreateEdit-image">
        <ImageInputSquare
          title="Image"
          formik={formik}
          buttonTitle="Upload logo"
          image={formik.values.image}
          error={formik.errors.image}
          target="image"
          folder="clients"
          height="14em"
          width="25em"
          acceptType={null}
        />
      </div>
      <div className="ClientCreateEdit-containerRightSide">
        <Input
          error={formik.errors.name}
          value={formik.values.name}
          handleChange={formik.handleChange}
          label="Name"
          name="name"
          type="text"
          width="auto"
          height="2rem"
        />
      </div>
    </div>
  );
};
