export const USER_DETAILS_FORM_CONFIG = {
  email: {
    name: "secondaryEmail",
    label: "Secondary Email",
    type: "text",
  },
  phone: {
    name: "phoneNumber",
    label: "Phone Number",
    type: "text",
  },
  biography: {
    name: "biography",
    label: "",
    type: "text",
  },
};

export const CURSOR_CARRET_TRANSPARENT = "transparent";

export const CURSOR_CARRET_BLACK = "black";
