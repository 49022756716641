import React, { FC, useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FormHelperText } from "@mui/material";
import { useTypedDispatch } from "../../../../stores/store";
import { uploadImage } from "../../../../stores/Image.store";

import "./ImageInputSquareAdd.css";
import { FormikValues } from "formik";

interface ImageInputSquareAddProps {
  title: string;
  buttonTitle: string;
  formik: FormikValues;
  image: string;
  name?: string;
  folder: string;
  target: string;
  error: any;
  width: string;
  height: string;
  acceptType: string | null;
}

export const ImageInputSquareAdd: FC<ImageInputSquareAddProps> = ({
  buttonTitle,
  formik,
  name,
  target,
  folder,
  error,
  width,
  height,
  acceptType,
}) => {
  const dispatch = useTypedDispatch();
  const [onHover, setOnHover] = useState<boolean>(false);

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      var data = dispatch(uploadImage({ file: e.target.files[0]!!, folder }));
      data.then((data: any) => {
        formik.setFieldValue(name || target, data.payload.url);
      });
    }
    e.target.value = null;
  };

  const inputFile = useRef<HTMLInputElement | null>(null);

  const onButtonClick = () => {
    inputFile.current?.click();
  };

  return (
    <div
      className="ImageInputSquareAdd-uploadContainer"
      style={{
        height: height,
        width: width,
        backgroundColor: onHover ? "#b3e8dc" : "#e6f7f3",
      }}
      onMouseEnter={() => {
        setOnHover(true);
      }}
      onMouseLeave={() => {
        setOnHover(false);
      }}
      onMouseDown={() => {
        onButtonClick();
      }}
    >
      <div className="ImageInputSquareAdd-uploadContainerContent">
        <CloudUploadIcon
          sx={{
            color: onHover ? "#007c60" : "#00B189",
            maxWidth: "5px",
            maxHeight: "5px",
            minWidth: "3em",
            minHeight: "3em",
          }}
        />
        <div style={{ color: onHover ? "#007c60" : "#00B189", marginTop: "1em" }}>
          {buttonTitle}
        </div>
        <FormHelperText sx={{ color: error ? "#d32f2f" : "inherit" }}>{error}</FormHelperText>
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={onSelectFile}
          accept={acceptType == null ? "image/*" : acceptType}
        />
      </div>
    </div>
  );
};
