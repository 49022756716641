import React, { FC, useEffect, useState } from "react";
import { ExpensesSummaryCard } from "./ExpensesSummaryCard/ExpensesSummaryCard";

import "./ExpensesSummary.css";
import { AddCircleOutlineButton } from "../../../common/buttons/AddCircleOutlineButton/AddCircleOutlineButton";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import {
  getExpensesByEmployee,
  getExpensesSummaryByEmployee,
} from "../../../../stores/internal/Expense.store";
import { dateToYYYMMDD } from "../../../../utils/date";
import { ExpensesSummaryTable } from "./ExpensesSummaryTable/ExpensesSummaryTable";
import { API_REQUEST_SETTINGS } from "../../../../constants/internal/apiRequestSettings.const";

interface ExpensesSummaryProps {
  navigateToCreate: () => void;
  navigateToDetail: (expenseId: string) => void;
}

export const ExpensesSummary: FC<ExpensesSummaryProps> = ({
  navigateToCreate,
  navigateToDetail,
}) => {
  const dispatch = useTypedDispatch();
  const { expensesPaginated, expensesSummary } = useTypedSelector(
    (state: RootState) => state.expenses
  );
  const { token, email } = useTypedSelector((state: RootState) => state.auth);
  const [dateFilter, setDateFilter] = useState<Date>(new Date());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(API_REQUEST_SETTINGS.GET_EXPENSES_TABLE_SIZE);

  useEffect(() => {
    setPage(0);
  }, [dateFilter]);

  useEffect(() => {
    triggerAPIRequests();
  }, [page, rowsPerPage, dateFilter]);

  const triggerAPIRequests = async () => {
    await getExpensesSummary();
    await getExpensesByEmployeeAndDate();
  };

  const getExpensesSummary = async () => {
    await dispatch(
      getExpensesSummaryByEmployee({
        employee: email,
      })
    );
  };

  const getExpensesByEmployeeAndDate = async () => {
    const dateFilterYear = dateFilter?.getFullYear();
    const dateFilterMonth = dateFilter?.getMonth();
    const firstDate = getFirstDateOfMonth(dateFilterYear, dateFilterMonth);
    const lastDate = getLastDateOfMonth(dateFilterYear, dateFilterMonth);

    await dispatch(
      getExpensesByEmployee({
        employee: email,
        from: dateToYYYMMDD(firstDate),
        to: dateToYYYMMDD(lastDate),
        token: token!!,
        page: page,
        size: rowsPerPage,
        sort: "submitted",
        order: "desc",
      })
    );
  };

  const getFirstDateOfMonth = (year: any, month: any) => {
    return new Date(year, month, 1);
  };

  const getLastDateOfMonth = (year: any, month: any) => {
    var date = new Date(year, month + 1, 1);
    date.setDate(date.getDate() - 1);
    return date;
  };

  const onAddNewExpenseClick = () => {
    navigateToCreate();
  };

  const onDateFilterChange = (date: Date) => {
    setDateFilter(date);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="ExpensesSummary-container">
      <div className="ExpensesSummary-newExpenseButton">
        <AddCircleOutlineButton
          width="170px"
          height="42px"
          text="New Expense"
          enabled={true}
          onClickAction={onAddNewExpenseClick}
        />
      </div>
      <ExpensesSummaryCard expensesSummary={expensesSummary} />
      <ExpensesSummaryTable
        expensesPaginated={expensesPaginated}
        dateFilter={dateFilter}
        onDateFilterChange={onDateFilterChange}
        onTableRowPress={navigateToDetail}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </div>
  );
};
