import { Box, Button } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import "./AddCircleOutlineButton.css";

interface AddCircleOutlineButtonProp {
  width: string;
  height: string;
  enabled: boolean;
  text: string;
  onClickAction: () => void;
}

export const AddCircleOutlineButton: FC<AddCircleOutlineButtonProp> = ({
  width,
  height,
  enabled,
  text,
  onClickAction,
}) => {
  return (
    <div className="AddCircleOutlineButton-container">
      <Box display="flex" justifyContent="center">
        <Button
          className={enabled ? "AddCircleOutlineButton-enabled" : "AddCircleOutlineButton-disabled"}
          style={{ width: width, height: height }}
          onClick={onClickAction}
          endIcon={<AddCircleOutlineIcon />}
          disabled={!enabled}
          sx={{ textTransform: "none" }}
        >
          {text}
        </Button>
      </Box>
    </div>
  );
};
