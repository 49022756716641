import React, { FC, useEffect, useState } from "react";
import { BlogPostDnD } from "../../../components/mkt/BlogPost/BlogPostLists/BlogPostDnd/BlogPostDnD";
import { BlogPostGrid } from "../../../components/mkt/BlogPost/BlogPostLists/BlogPostGrid/BlogPostGrid";
import { Page } from "../../../components/mkt/Global/Pages/Page";
import { pages } from "../../../data/mkt/pageContent";
import { editBlogPost, getBlogPosts } from "../../../stores/mkt/BlogPost.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../stores/store";

export const BlogPostsPage: FC = () => {
  const dispatch = useTypedDispatch();
  const { blogPosts, loading, error } = useTypedSelector((state: RootState) => state.blogPosts);
  const [dndEnabled, setDndEnabled] = useState<boolean>(false);
  const [loaded, setLoaded] = useState(false);

  const dndAction = () => {
    for (let index = 0; index < blogPosts.length; index++) {
      dispatch(editBlogPost({ blogPost: { ...blogPosts[index], order: index } }));
    }
    setDndEnabled(false);
  };

  const fetchBlogPost = async () => {
    await dispatch(getBlogPosts()).then(() => {
      setLoaded(true);
    });
  };

  async function cancelDndAction() {
    setDndEnabled(false);
    setLoaded(false);
    fetchBlogPost();
  }

  useEffect(() => {
    fetchBlogPost();
  }, []);

  return (
    <Page
      pageContent={pages.blogPosts}
      search={(term) => {}}
      dndEnabled={dndEnabled}
      dndAction={dndAction}
      cancelDndAction={cancelDndAction}
      setDndEnabled={setDndEnabled}
    >
      {loaded ? (
        dndEnabled ? (
          <BlogPostDnD blogPosts={blogPosts} />
        ) : (
          <BlogPostGrid blogPosts={blogPosts} />
        )
      ) : null}
    </Page>
  );
};
