import { Box } from "@mui/material";
import React, { FC } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { setClients } from "../../../../../stores/mkt/Client.store";
import { useTypedDispatch } from "../../../../../stores/store";
import { Client } from "../../../../../types/mkt/Client";
import { reorder } from "../../../../../utils/array";
import { ClientCard } from "../ClientCard/ClientCard";
import "./ClientDnD.css";

interface ClientsDnDProps {
  clients: Client[];
}

export const ClientsDnD: FC<ClientsDnDProps> = ({ clients }) => {
  const dispatch = useTypedDispatch();

  // Function to update list on drop
  const handleDrop = (result: any) => {
    // Ignore drop outside droppable container
    if (!result.destination) {
      return;
    }

    const items = reorder(clients, result.source.index, result.destination.index);
    dispatch(setClients(items));
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId={"dndClients"}>
            {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
              <Box ref={dropProvided.innerRef}>
                {clients.map((client, index: number) => (
                  <div className="ClientDnD-card" key={client.id}>
                    <Draggable index={index} draggableId={client.id}>
                      {(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => (
                        <div
                          ref={dragProvided.innerRef}
                          key={client.id}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                        >
                          <ClientCard
                            client={client}
                            registerPublish={null}
                            registerDelete={null}
                            showHoverShadow={false}
                          />
                          <br />
                        </div>
                      )}
                    </Draggable>
                  </div>
                ))}
                {dropProvided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
};
