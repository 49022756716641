import React, { FC, useState } from "react";
import { ExpensesCreateEdit } from "../../../components/internal/Expense/ExpensesCreateEdit/ExpensesCreateEdit";
import { ExpensesDetails } from "../../../components/internal/Expense/ExpensesDetails/ExpensesDetails";
import { ExpensesSummary } from "../../../components/internal/Expense/ExpensesSummary/ExpensesSummary";
import { Page } from "../../../components/internal/Global/Pages/Page";
import { TimeReport } from "../../../components/internal/TimeReport/TimeReport";

interface TimeReportPageProps {}

export const TimeReportPage: FC<TimeReportPageProps> = () => {
  enum TimeReportPageTabs {
    TIME_REPORT_SUMMARY = 0,
    EXPENSES_SUMMARY = 1,
    EXPENSES_CREATE = 2,
    EXPENSE_DETAILS = 3,
  }

  const action = () => {};
  const [activeTab, setActiveTab] = useState<number>(TimeReportPageTabs.TIME_REPORT_SUMMARY);
  const [expenseIdToShow, setExpenseIdToShow] = useState<string | null>(null);
  const swithTabAction = (tab: TimeReportPageTabs) => {
    setActiveTab(tab);
  };

  const renderPageTab = (tab: TimeReportPageTabs) => {
    switch (tab) {
      case TimeReportPageTabs.EXPENSES_SUMMARY:
        return (
          <ExpensesSummary
            navigateToCreate={navigateToExpensesCreationTab}
            navigateToDetail={navigateToExpenseDetails}
          />
        );
      case TimeReportPageTabs.EXPENSES_CREATE:
        return <ExpensesCreateEdit navigateToSummary={navigateToExpensesSummaryTab} />;
      case TimeReportPageTabs.EXPENSE_DETAILS:
        return (
          <ExpensesDetails
            navigateToSummary={navigateToExpensesSummaryTab}
            expenseId={expenseIdToShow!!}
          />
        );
        break;
      default:
        return <TimeReport />;
    }
  };

  const navigateToExpensesSummaryTab = () => {
    setActiveTab(TimeReportPageTabs.EXPENSES_SUMMARY);
  };

  const navigateToExpensesCreationTab = () => {
    setActiveTab(TimeReportPageTabs.EXPENSES_CREATE);
  };

  const navigateToExpenseDetails = (expenseId: string) => {
    setExpenseIdToShow(expenseId);
    if (expenseId != null) {
      setActiveTab(TimeReportPageTabs.EXPENSE_DETAILS);
    }
  };

  return (
    <Page
      pageContent={{
        tabs: ["Report Hours", "Expenses"],
        action: action,
        switchTabAction: swithTabAction,
      }}
      backgroundColor="#fff"
    >
      {renderPageTab(activeTab)}
    </Page>
  );
};
