import React, { FC } from "react";

export const Careers: FC = () => {
  return (
    <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.4278 26.9097C22.9508 27.5236 23.8725 27.5974 24.4866 27.0743C25.1005 26.5513 25.1743 25.6296 24.6512 25.0155L24.2724 24.5707L22.0487 26.465L22.4278 26.9097Z"
        fill="white"
      />
      <path
        d="M16.9182 3.37332C13.4827 -0.660027 7.4062 -1.14551 3.37354 2.29C-0.659802 5.72552 -1.14583 11.802 2.29023 15.8347C5.52974 19.6374 11.116 20.2849 15.1239 17.4668L15.9746 18.4651L15.5454 18.8308L21.4807 25.798L23.7044 23.9037L17.7688 16.9365L17.3396 17.3021L16.489 16.3038C19.9089 12.794 20.1571 7.17608 16.9182 3.37333L16.9182 3.37332ZM4.10444 14.2895C1.52117 11.2567 1.88634 6.68742 4.91887 4.10422C7.95166 1.52046 12.5209 1.88612 15.1041 4.91864C17.6879 7.95144 17.3222 12.5207 14.2897 15.1039C11.2569 17.6879 6.68765 17.3223 4.10444 14.2895Z"
        fill="white"
      />
      <path
        d="M11.8822 6.53987C11.8822 7.77159 10.8837 8.77032 9.65178 8.77032C8.42006 8.77032 7.42133 7.77159 7.42133 6.53987C7.42133 5.30798 8.42006 4.30942 9.65178 4.30942C10.8837 4.30942 11.8822 5.30798 11.8822 6.53987Z"
        fill="white"
      />
      <path
        d="M9.58881 10.0056C7.25111 10.0056 5.34631 11.6074 4.82671 13.772C4.74015 14.2049 5.08638 14.6379 5.51933 14.6379H13.7013C14.1775 14.6379 14.5238 14.2049 14.3939 13.772C13.8312 11.6074 11.9263 10.0056 9.58838 10.0056H9.58881Z"
        fill="white"
      />
    </svg>
  );
};
