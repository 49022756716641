import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { observer } from "mobx-react-lite";
import { Page } from "../../../components/mkt/Global/Pages/Page";
import { pages } from "../../../data/mkt/pageContent";
import {
  editService,
  getServices,
  deleteService,
  publishService,
} from "../../../stores/mkt/Service.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../stores/store";
import { ServiceDnd } from "../../../components/mkt/Service/ServiceLists/ServiceDnD/ServiceDnd";
import { ServiceGrid } from "../../../components/mkt/Service/ServiceLists/ServiceGrid/ServiceGrid";
import { useNavigate } from "react-router-dom";

export const ServicesPage: FC = observer(() => {
  const dispatch = useTypedDispatch();
  const { services, loading, error, created, edited, deleted, publishChange } = useTypedSelector(
    (state: RootState) => state.services
  );
  const [dndEnabled, setDndEnabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  const dndAction = () => {
    for (let index = 0; index < services.length; index++) {
      dispatch(editService({ service: { ...services[index], order: index } }));
    }
    setDndEnabled(false);
  };

  const fetchService = async () => {
    await dispatch(getServices()).then(() => {
      setLoaded(true);
    });
  };

  async function cancelDndAction() {
    setDndEnabled(false);
    setLoaded(false);
    fetchService();
  }

  useEffect(() => {
    fetchService();
  }, [deleted]);

  function editAction(id: string) {
    navigate(`/services/${id}/edit`);
  }

  async function publishAction(id: string, publish: boolean) {
    await dispatch(publishService({ id: id, publish: publish }));
    dispatch(getServices());
  }

  async function deleteAction(id: string) {
    await dispatch(deleteService({ id: id!! }));
  }

  return (
    <Page
      pageContent={pages.services}
      search={(term) => {}}
      dndEnabled={dndEnabled}
      dndAction={dndAction}
      cancelDndAction={cancelDndAction}
      setDndEnabled={setDndEnabled}
    >
      {loaded ? (
        dndEnabled ? (
          <ServiceDnd services={services} />
        ) : (
          <ServiceGrid
            services={services}
            editAction={editAction}
            publishAction={publishAction}
            deleteAction={deleteAction}
          />
        )
      ) : null}
    </Page>
  );
});
