import { TablePagination, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { FC } from "react";
import { DEFAULT_CURRENCY } from "../../../../../constants/expenses.const";
import {
  getSubmissionStatusText,
  getSubmissionStatusTextColor,
} from "../../../../../data/common/SubmissionStatus";
import { ExpensePaginated } from "../../../../../types/internal/expenses/ExpensePaginated";
import { dateToDDMMYYYY } from "../../../../../utils/date";
import "./ExpensesSummaryTable.css";

interface ExpensesSummaryTableProps {
  expensesPaginated: ExpensePaginated;
  dateFilter: Date;
  onDateFilterChange: (date: any) => void;
  onTableRowPress: (expenseId: string) => void;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  page: number;
  rowsPerPage: number;
}

export const ExpensesSummaryTable: FC<ExpensesSummaryTableProps> = ({
  expensesPaginated,
  dateFilter,
  onDateFilterChange,
  onTableRowPress,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
}) => {
  const areThereMoreThanOneResultsPage = () => {
    return expensesPaginated && expensesPaginated.totalPages > 1;
  };

  const areThereExpensesToShow = () => {
    return (
      expensesPaginated != undefined &&
      expensesPaginated.expenses != undefined &&
      expensesPaginated.expenses.length > 0
    );
  };

  return (
    <div className="ExpensesSummaryTable-container">
      <div className="ExpensesSummaryTable-header">
        <div className="ExpensesSummaryTable-datePicker">
          <DatePicker
            views={["month", "year"]}
            label="Time Period"
            value={dateFilter}
            onChange={(newValue) => {
              onDateFilterChange(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} helperText={null} sx={{ svg: { fill: "#209973" } }} />
            )}
          />
        </div>
        {areThereMoreThanOneResultsPage() && (
          <div className="ExpensesSummaryTable-pagination">
            <TablePagination
              component="div"
              count={expensesPaginated ? expensesPaginated.totalElements : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              labelDisplayedRows={({}) => {
                return ``;
              }}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ display: "flex", width: "100%", justifyContent: "center" }}
            />
          </div>
        )}
      </div>
      {areThereExpensesToShow() && (
        <div className="ExpensesSummaryTable-tableContainer">
          <table className="ExpensesSummaryTable-table">
            <thead>
              <tr className="ExpensesSummaryTable-headRow">
                <th>WBS Code</th>
                <th>Date</th>
                <th>Value</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {expensesPaginated.expenses.map((expense, index) => {
                return (
                  <tr
                    key={index}
                    className={
                      index % 2 == 0
                        ? "ExpensesSummaryTable-tableRowOdd"
                        : "ExpensesSummaryTable-tableRowEven"
                    }
                    onClick={() => onTableRowPress(expense.id)}
                  >
                    <td>{expense.wbs?.code}</td>
                    <td>{dateToDDMMYYYY(new Date(expense.submitted))}</td>
                    <td>{expense.cost + " " + DEFAULT_CURRENCY}</td>
                    <td style={{ color: getSubmissionStatusTextColor(expense.status) }}>
                      {getSubmissionStatusText(expense.status)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {!areThereExpensesToShow() && (
        <div className="ExpensesSummaryTable-noContentText">No expenses to show</div>
      )}
    </div>
  );
};
