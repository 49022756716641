import React from "react";
import { SidebarItem } from "../../types/SidebarItem";
import { TimeReport } from "../../assets/svg/TimeReport";
import { Vacations } from "../../assets/svg/Vacations";
import { CurriculumVitae } from "../../assets/svg/CurriculumVitae";
import { VacationsControl } from "../../assets/svg/VacationsControl";
import { ROLES } from "../../constants/roles";

export const sidebarItems: SidebarItem[] = [
  {
    text: "Time Report",
    icon: <TimeReport />,
  },
  {
    text: "Vacations",
    icon: <Vacations />,
  },
  {
    text: "Curriculum Vitae",
    icon: <CurriculumVitae />,
  },
  {
    text: "Vacations Control",
    icon: <VacationsControl />,
    requiredRole: ROLES.APPROVER,
  },
];
