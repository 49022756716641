import React, { FC } from "react";
import { Box } from "@mui/material";
import { CaseStudy } from "../../../../../types/mkt/CaseStudy";
import { CaseStudyCard } from "../CaseStudyCard/CaseStudyCard";
import { Link } from "react-router-dom";

import "./CaseStudyGrid.css";

import "./CaseStudyGrid.css";

interface CaseStudyGridProps {
  caseStudies: CaseStudy[];
}

export const CaseStudyGrid: FC<CaseStudyGridProps> = ({ caseStudies }) => {
  return (
    <Box className="CaseStudyGrid-container">
      {caseStudies.map((caseStudy, index) => (
        <div key={index}>
          <Link to={`/case_studies/${caseStudy.id}`} className="CaseStudyGrid-link">
            <CaseStudyCard caseStudy={caseStudy} />
          </Link>
        </div>
      ))}
    </Box>
  );
};
