import { Typography } from "@mui/material";
import React, { FC, ReactElement } from "react";
import "./InfoCardSubImageSection.css";
import { ButtonsCard } from "../../../ButtonsCard/ButtonsCard";

interface InfoCardSubImageSectionProps {
  children: ReactElement;
  id: string;
  publish: boolean;
  editAction: any;
  publishAction: any;
  deleteAction: any;
  duplicateAction: any;
}

export const InfoCardSubImageSection: FC<InfoCardSubImageSectionProps> = ({
  children,
  id,
  publish,
  editAction,
  publishAction,
  deleteAction,
  duplicateAction,
}) => {
  return (
    <div className="InfoCardSubImageSection-cardItemImage">
      <ButtonsCard
        id={id}
        publish={publish}
        editAction={editAction}
        publishAction={publishAction}
        deleteAction={deleteAction}
        duplicateAction={duplicateAction}
      />
      <Typography
        sx={{
          position: "absolute",
          justifyContent: "center",
          marginLeft: "25px",
          marginTop: "8px",
          borderRadius: "8px",
          fontSize: "14px",
          width: "100px",
          marginBottom: "23px",
        }}
        className={
          publish ? "InfoCardSubImageSection-published" : "InfoCardSubImageSection-notPublished"
        }
        align="center"
      >
        {publish ? "Published" : "Unpublished"}
      </Typography>
      {children}

      <div className="InfoCardSubImageSection-cardLink">
        <img
          src="https://kodly-assets.s3.eu-west-1.amazonaws.com/global/arrow-white.png"
          className="InfoCardSubImageSection-cardLinkArrow"
          alt="Arrow"
        />
      </div>
    </div>
  );
};
