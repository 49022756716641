import React, { FC } from "react";
import { ServiceCreateEditTitle } from "./ServiceCreateEditTitle/ServiceCreateEditTitle";
import { ServiceCreateEditButtonDestination } from "./ServiceCreateEditButtonDestination/ServiceCreateEditButtonDestination";
import { ServiceCreateEditDescription } from "./ServiceCreateEditDescription/ServiceCreateEditDescription";
import { ServiceCreateEditUploadIcon } from "./ServiceCreateEditUploadIcon/ServiceCreateEditUploadIcon";
import "./ServiceCreateEdit.css";
import { ImageInputSquare } from "../../../ImageInputs/ImageInputSquare/ImageInputSquare";
import { ServiceCreateEditSubTitle } from "./ServiceCreateEditSubtitle/ServiceCreateEditSubTitle";

interface ServiceCreateEditProps {
  formik: any;
}

export const ServiceCreateEdit: FC<ServiceCreateEditProps> = ({ formik }) => {
  return (
    <div className="ServiceCreateEdit-container">
      <div className="ServiceCreateEdit-leftSide">
        <div className="ServiceCreateEdit-marginLeft">
          <ServiceCreateEditButtonDestination formik={formik} />
        </div>
        <ServiceCreateEditTitle formik={formik} />
        <ServiceCreateEditSubTitle formik={formik} />
        <div className="ServiceCreateEdit-marginLeft">
          <ServiceCreateEditDescription formik={formik} />
        </div>
      </div>
      <div className="ServiceCreateEdit-rightSide">
        <ServiceCreateEditUploadIcon formik={formik} />
        <div className="ServiceCreateEdit-righSide_item">
          <ImageInputSquare
            title="Image"
            formik={formik}
            buttonTitle="Upload cover image"
            image={formik.values.image}
            error={formik.errors.image}
            target="image"
            folder="caseStudies"
            height="14em"
            width="25em"
            acceptType={null}
          />
        </div>
      </div>
    </div>
  );
};
