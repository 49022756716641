import React, { FC, useEffect, useState } from "react";
import { Box, FormHelperText, TextField, Autocomplete, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { createCategory, searchCategory } from "../../../../../../../stores/mkt/Category.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../../../../stores/store";
import { Category } from "../../../../../../../types/mkt/Category";
import { emptyCategory } from "../../../../../../../data/mkt/category";
import "./AutoComplete.css";

interface AutoCompleteInputProps {
  category: Category;
  index: number;
  handleInputChange: (value: Category, index: number) => void;
  handleDelete: (index: number) => void;
  error: any;
}

export const AutoComplete: FC<AutoCompleteInputProps> = ({
  category,
  index,
  handleInputChange,
  handleDelete,
  error,
}) => {
  const dispatch = useTypedDispatch();
  const [value, setValue] = useState<Category>(emptyCategory);
  const [searchList, setSearchList] = useState<Category[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  var newCategory = { id: "-1", name: `(Add)` };
  const removeExtraSpace = (s: string) => s.trim().split(/ +/).join(" ");

  useEffect(() => {
    setValue(category);
  }, [category]);

  const handleAutocompleteTextChange = async (event: any) => {
    var search = event.target.value;
    search = search.trimLeft();
    setValue({ id: "-1", name: search });
    if (search && search.length > 2) {
      await searchCategories(search.trim());
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const searchCategories = async (name: string) => {
    await dispatch(searchCategory({ name: name })).then((data: any) => {
      if (data.payload.filter((e: Category) => e.name === name).length > 0) {
        setSearchList(data.payload);
      } else {
        newCategory.name = `${name} (Add)`;
        setSearchList(() => [...data.payload, newCategory]);
      }
    });
  };

  const addCategory = async (name: string) => {
    await dispatch(createCategory({ category: { id: "", name: name } })).then((data: any) => {
      searchCategories(data.payload.name);
      handleChange(data.payload);
    });
  };

  const onChange = (event: any, autocompleteValue: any) => {
    if (event.type == "click" || event.type == "keydown") {
      if (autocompleteValue && autocompleteValue.id == "-1") {
        var ret = autocompleteValue.name.replace(" (Add)", "");
        addCategory(removeExtraSpace(ret));
      } else {
        handleChange(autocompleteValue);
      }
    }
  };

  const handleChange = (category: Category) => {
    handleInputChange(category, index);
    setValue(category);
  };

  return (
    <Box>
      <Box className="AutoComplete-backgroundItemContainer">
        <IconButton className="AutoComplete-deleteButton">
          <ClearIcon
            className="AutoComplete-clearIcon"
            onClick={() => handleDelete(index)}
          ></ClearIcon>
        </IconButton>
        <Autocomplete
          className="AutoComplete-container"
          value={value}
          options={searchList}
          open={open}
          onClose={() => setOpen(false)}
          filterOptions={(options) => options}
          getOptionLabel={(option) => option.name}
          autoHighlight={true}
          autoSelect={true}
          onChange={onChange}
          disableClearable={true}
          openOnFocus={true}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search category"
              onChange={handleAutocompleteTextChange.bind(this)}
            />
          )}
        />
      </Box>
      {error && error[index] ? (
        <Box>
          <FormHelperText sx={{ color: error ? "#d32f2f" : "inherit" }}>
            {error[index].name}
          </FormHelperText>
        </Box>
      ) : null}
    </Box>
  );
};
