import { observer } from "mobx-react-lite";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { RootState, useTypedSelector } from "../../stores/store";

export const PublicRoute = observer(({ ...props }) => {
  const { token } = useTypedSelector((state: RootState) => state.auth);

  return !token ? <Outlet /> : <Navigate to="/" replace />;
});
