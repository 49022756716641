import React, { FC } from "react";

export const Persons: FC = () => {
  return (
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.96879 7.08151C8.96879 8.40034 7.89975 9.46938 6.58092 9.46938C5.26209 9.46938 4.19305 8.40034 4.19305 7.08151C4.19305 5.76287 5.26209 4.69365 6.58092 4.69365C7.89975 4.69365 8.96879 5.76287 8.96879 7.08151Z"
        fill="white"
      />
      <path
        d="M10.8975 14.7043C9.88722 14.1532 9.24433 13.0511 9.24433 11.8572C9.24433 11.7193 9.24433 11.5357 9.29032 11.3979C8.50966 10.9387 7.59133 10.6632 6.62702 10.6632C4.14737 10.6632 2.12691 12.3622 1.52968 14.6583C1.39187 15.1175 1.80512 15.5767 2.26435 15.5767H9.06049C9.56557 15.1635 10.2086 14.8879 10.8973 14.7042L10.8975 14.7043Z"
        fill="white"
      />
      <path
        d="M20.8162 7.08151C20.8162 8.40034 19.747 9.46938 18.4283 9.46938C17.1095 9.46938 16.0405 8.40034 16.0405 7.08151C16.0405 5.76287 17.1095 4.69365 18.4283 4.69365C19.747 4.69365 20.8162 5.76287 20.8162 7.08151Z"
        fill="white"
      />
      <path
        d="M23.5254 14.6126C22.9744 12.3165 20.908 10.6175 18.4742 10.6175C17.5099 10.6175 16.5914 10.893 15.8109 11.3522C15.8569 11.49 15.8569 11.6277 15.8569 11.8115C15.8569 13.0514 15.168 14.1075 14.2037 14.6586C14.8926 14.8422 15.4894 15.1178 16.0405 15.531H22.8366C23.2497 15.5309 23.6171 15.0718 23.5253 14.6126L23.5254 14.6126Z"
        fill="white"
      />
      <path
        d="M14.8922 11.8112C14.8922 13.1298 13.8232 14.199 12.5044 14.199C11.1857 14.199 10.1165 13.1298 10.1165 11.8112C10.1165 10.4923 11.1857 9.42331 12.5044 9.42331C13.8232 9.42331 14.8922 10.4923 14.8922 11.8112Z"
        fill="white"
      />
      <path
        d="M12.5046 15.3931C10.025 15.3931 8.00453 17.0921 7.45337 19.3881C7.36156 19.8474 7.72881 20.3066 8.18805 20.3066H16.8668C17.3719 20.3066 17.7393 19.8473 17.6015 19.3881C17.0046 17.0921 14.9841 15.3931 12.5042 15.3931H12.5046Z"
        fill="white"
      />
    </svg>
  );
};
