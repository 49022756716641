import React, { FC } from "react";
import { HtmlInput } from "../../../../../../Inputs/HtmlInput";
import "./VacancyResponsibilitiesCreate.css";

interface VacancyResponsibilitiesCreateProps {
  formik: any;
}

export const VacancyResponsibilitiesCreate: FC<VacancyResponsibilitiesCreateProps> = ({
  formik,
}) => {
  return (
    <div className="VacancyResponsibilitiesCreate-responsibilitiesInput">
      <HtmlInput
        error={
          formik.errors && formik.errors.responsibilities ? formik.errors.responsibilities : ""
        }
        value={formik.values.responsibilities}
        handleChange={formik.handleChange}
        label="Responsibilities"
        name="responsibilities"
      />
    </div>
  );
};
