import React, { FC } from "react";
import { dateToString } from "../../../../../../../utils/date";

import "./BlogPostInfo.css";

interface BlogPostInfoProps {
  date: string;
  readTime: string;
}

export const BlogPostInfo: FC<BlogPostInfoProps> = ({ readTime, date }) => {
  return (
    <div className="BlogPostInfo-footerInfo">
      <div className="BlogPostInfo-date">
        <img
          src="https://kodly-assets.s3.eu-west-1.amazonaws.com/global/calendar.svg"
          alt="Calendar"
        />
        <span className="BlogPostInfo-dateFooter">{dateToString(date)}</span>
      </div>
      <div className="BlogPostInfo-read">
        <img src="https://kodly-assets.s3.eu-west-1.amazonaws.com/global/read.svg" alt="Read" />
        <span className="BlogPostInfo-readFooter">{readTime}</span>
      </div>
    </div>
  );
};
