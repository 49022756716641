import React, { FC } from "react";
import "./ImageInputSquareAfterAdd.css";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

interface ImageInputSquareAfterAddProps {
  title: string;
  buttonTitle: string;
  formik: any;
  image: string;
  name?: string;
  folder: string;
  target: string;
  width: string;
  height: string;
}

export const ImageInputSquareAfterAdd: FC<ImageInputSquareAfterAddProps> = ({
  formik,
  image,
  name,
  target,
  width,
  height,
}) => {
  const deleteFile = () => {
    if (image) {
      formik.setFieldValue(name || target, undefined);
    }
  };

  return (
    <div
      className="ImageInputSquareAfterAdd-uploadContainer"
      style={{
        height: height,
        width: width,
        backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "#BEBEBE",
        backgroundSize: "contain",
      }}
    >
      <IconButton className="ImageInputSquareAfterAdd-deleteButton" onClick={deleteFile}>
        <ClearIcon className="ImageInputSquareAfterAdd-deleteIcon"></ClearIcon>
      </IconButton>
    </div>
  );
};
