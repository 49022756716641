import React, { FC } from "react";
import { Box } from "@mui/material";
import { TestimonialCard } from "../TestimonialCard/TestimonialCard";
import { Testimonial } from "../../../../../types/mkt/Testimonial";
import { Link } from "react-router-dom";

import "./TestimonialGrid.css";

interface TestimonialGridProps {
  testimonials: Testimonial[];
  editAction: any;
  publishAction: any;
  deleteAction: any;
}

export const TestimonialGrid: FC<TestimonialGridProps> = ({
  testimonials,
  editAction,
  publishAction,
  deleteAction,
}) => {
  return (
    <Box className="TestimonialGrid-container">
      {testimonials.map((testimonial, index) => (
        <div className={`TestimonialGrid-link`} key={index}>
          <TestimonialCard
            testimonials={testimonial}
            editAction={editAction}
            publishAction={publishAction}
            deleteAction={deleteAction}
            hoverEffectEnabled={true}
          />
        </div>
      ))}
    </Box>
  );
};
