import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { emptyCaseStudy } from "../../data/mkt/caseStudy";
import CaseStudyService from "../../services/mkt/CaseStudy.service";
import { CaseStudy } from "../../types/mkt/CaseStudy";
import { CaseStudyState } from "../../types/mkt/CaseStudyState";

const caseStudiesSlice = createSlice({
  name: "caseStudy",
  initialState: {
    loading: false,
    error: null,
    caseStudies: [],
    caseStudy: emptyCaseStudy,
    created: false,
    edited: false,
    deleted: false,
  } as CaseStudyState,
  reducers: {
    setCreated(state, action) {
      state.created = action.payload;
    },
    setEdited(state, action) {
      state.edited = action.payload;
    },
    setDeleted(state, action) {
      state.deleted = action.payload;
    },
    setCaseStudies(state, action) {
      state.caseStudies = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCaseStudies.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getCaseStudies.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.caseStudies = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getCaseStudies.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(getCaseStudy.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getCaseStudy.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.caseStudy = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getCaseStudy.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(createCaseStudy.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.created = false;
      }
    });
    builder.addCase(createCaseStudy.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.created = true;
      }
    });
    builder.addCase(createCaseStudy.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(editCaseStudy.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.edited = false;
      }
    });
    builder.addCase(editCaseStudy.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.edited = true;
      }
    });
    builder.addCase(editCaseStudy.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(deleteCaseStudy.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.deleted = false;
      }
    });
    builder.addCase(deleteCaseStudy.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.deleted = true;
      }
    });
    builder.addCase(deleteCaseStudy.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(publishCaseStudy.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.deleted = false;
      }
    });
    builder.addCase(publishCaseStudy.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.caseStudy = action.payload;
      }
    });
    builder.addCase(publishCaseStudy.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

export const getCaseStudies = createAsyncThunk("caseStudies/get", async () => {
  const response = await CaseStudyService.getCaseStudies();
  return response.caseStudies.sort((a, b) => a.order - b.order);
});

export const getCaseStudy = createAsyncThunk(
  "caseStudies/getById",
  async (vacancy: { id: string }) => {
    const response = await CaseStudyService.getCaseStudy(vacancy.id);
    response.caseStudy.readTime = response.caseStudy.readTime.replace(" min.", "");
    return response.caseStudy;
  }
);

export const createCaseStudy = createAsyncThunk(
  "caseStudies/create",
  async (data: { caseStudy: CaseStudy }) => {
    const caseStudy = {
      ...data.caseStudy,
      readTime: data.caseStudy.readTime + " min.",
    };
    const response = await CaseStudyService.createCaseStudy(caseStudy);
    return response.caseStudy;
  }
);

export const editCaseStudy = createAsyncThunk(
  "caseStudies/edit",
  async (data: { caseStudy: CaseStudy }) => {
    const caseStudy = {
      ...data.caseStudy,
      readTime: data.caseStudy.readTime + " min.",
    };
    const response = await CaseStudyService.editCaseStudy(caseStudy);
    return response.caseStudy;
  }
);

export const deleteCaseStudy = createAsyncThunk(
  "caseStudies/delete",
  async (data: { id: string }) => {
    await CaseStudyService.deleteCaseStudy(data.id);
    return;
  }
);

export const publishCaseStudy = createAsyncThunk(
  "caseStudies/publish",
  async (data: { id: string; publish: boolean }) => {
    const response = data.publish
      ? await CaseStudyService.unpublishCaseStudy(data.id)
      : await CaseStudyService.publishCaseStudy(data.id);
    return response.caseStudy;
  }
);

export default caseStudiesSlice.reducer;
export const { setCreated, setEdited, setDeleted, setCaseStudies } = caseStudiesSlice.actions;
