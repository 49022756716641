import React, { FC } from "react";

export const KodlyIo: FC = () => {
  return (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.4444 0H2.55556C1.13722 0 0 1.35 0 3V21C0 22.65 1.13722 24 2.55556 24H20.4444C21.85 24 23 22.65 23 21V3C23 1.35 21.8628 0 20.4444 0ZM20.4444 21H2.55556V6H20.4444V21Z"
        fill="white"
      />
    </svg>
  );
};
