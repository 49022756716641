import React, { FC } from "react";

export const VacationsControl: FC = () => {
  return (
    <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.74248 0C3.59232 0 0.216797 3.364 0.216797 7.5C0.216797 11.636 3.59232 15 7.74248 15C11.8926 15 15.2682 11.636 15.2682 7.5C15.2682 3.364 11.8926 0 7.74248 0ZM7.74248 14C4.14621 14 1.22022 11.084 1.22022 7.5C1.22022 3.916 4.14621 1 7.74248 1C11.3388 1 14.2647 3.916 14.2647 7.5C14.2647 11.084 11.3388 14 7.74248 14Z"
        fill="white"
      />
      <path
        d="M10.3975 4.14648L7.74244 6.79348L5.08738 4.14648L4.37695 4.85448L7.03302 7.50048L4.37695 10.1465L5.08738 10.8545L7.74244 8.20748L10.3975 10.8545L11.1079 10.1465L8.45186 7.50048L11.1079 4.85448L10.3975 4.14648Z"
        fill="white"
      />
      <path
        d="M18.7796 24C17.7909 24.0016 16.8117 23.8087 15.8981 23.4323C14.9845 23.0559 14.1544 22.5034 13.4557 21.8066C10.52 18.8816 10.52 14.1226 13.4557 11.1976C14.8778 9.78076 16.7684 9 18.7796 9C20.7907 9 22.6814 9.78016 24.1035 11.1976C27.0392 14.1226 27.0392 18.8816 24.1035 21.8066C23.4047 22.5034 22.5747 23.0559 21.6611 23.4323C20.7475 23.8087 19.7682 24.0016 18.7796 24ZM18.7796 10.2002C17.9487 10.198 17.1255 10.3599 16.3579 10.6768C15.5902 10.9937 14.8932 11.4591 14.3074 12.0462C11.8421 14.5031 11.8421 18.5005 14.3074 20.958C16.7733 23.4143 20.7853 23.4143 23.2518 20.958C25.7171 18.5011 25.7171 14.5037 23.2518 12.0462C22.6659 11.4592 21.9689 10.9938 21.2012 10.677C20.4336 10.3601 19.6105 10.1981 18.7796 10.2002ZM23.6777 21.3823H23.6837H23.6777Z"
        fill="white"
      />
      <path
        d="M17.9778 19.434C17.9077 19.4341 17.8383 19.4177 17.7736 19.3858C17.7088 19.3539 17.65 19.307 17.6005 19.248L15.336 16.5541C15.2864 16.4952 15.2471 16.4252 15.2203 16.3482C15.1935 16.2711 15.1797 16.1886 15.1797 16.1052C15.1797 16.0219 15.1935 15.9393 15.2203 15.8623C15.2471 15.7853 15.2864 15.7153 15.336 15.6564C15.3855 15.5974 15.4444 15.5506 15.5091 15.5187C15.5738 15.4868 15.6432 15.4704 15.7133 15.4704C15.7834 15.4704 15.8528 15.4868 15.9175 15.5187C15.9823 15.5506 16.0411 15.5974 16.0906 15.6564L17.9778 17.9014L21.4672 13.7504C21.5168 13.6914 21.5756 13.6447 21.6403 13.6128C21.7051 13.5809 21.7745 13.5645 21.8445 13.5645C21.9146 13.5645 21.984 13.5809 22.0488 13.6128C22.1135 13.6447 22.1723 13.6914 22.2219 13.7504C22.2714 13.8093 22.3107 13.8793 22.3375 13.9563C22.3644 14.0333 22.3782 14.1159 22.3782 14.1993C22.3782 14.2826 22.3644 14.3652 22.3375 14.4422C22.3107 14.5192 22.2714 14.5892 22.2219 14.6481L18.3552 19.248C18.3057 19.307 18.2469 19.3539 18.1821 19.3858C18.1173 19.4177 18.0479 19.4341 17.9778 19.434Z"
        fill="white"
      />
    </svg>
  );
};
