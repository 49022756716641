import React, { FC } from "react";
import { HtmlInput } from "../../../../Inputs/HtmlInput";
import { Input } from "../../../../Inputs/Input";
import "./CaseStudyCreateEditChallenge.css";

interface CaseStudyCreateEditChallengeProps {
  formik: any;
}

export const CaseStudyCreateEditChallenge: FC<CaseStudyCreateEditChallengeProps> = ({ formik }) => {
  return (
    <div className="CaseStudyCreateEditChallenge-container">
      <Input
        error={formik.errors ? (formik.errors.challenge ? formik.errors.challenge.title : "") : ""}
        value={formik.values.challenge.title}
        handleChange={formik.handleChange}
        label="Challenge Title"
        name="challenge.title"
        type="text"
        width="50%"
        height="2rem"
      />

      <div className="CaseStudyCreateEditChallenge-description">
        <HtmlInput
          error={
            formik.errors
              ? formik.errors.challenge
                ? formik.errors.challenge.description
                : ""
              : ""
          }
          value={formik.values.challenge.description}
          handleChange={formik.handleChange}
          label="Description"
          name="challenge.description"
        />
      </div>
    </div>
  );
};
