import * as yup from "yup";

export const vacationSchema = yup.object({
  startDate: yup.date().required("Date is required"),
  endDate: yup
    .date()
    .required("Date is required")
    .when(
      "startDate",
      (startDate, yup) => startDate && yup.min(startDate, "End date must come after start date")
    ),
  approvers: yup.array().min(1, "At least one approver is required"),
});
