import React, { FC, useEffect } from "react";
import { signup } from "../../stores/Authentication.store";
import { useTypedDispatch } from "../../stores/store";

export const GoogleSignInButton: FC = () => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    if (window && window.google) {
      window.google.accounts.id.initialize({
        client_id: "93807582536-9r26q056doehle4p10c1q9r962jrfnhq.apps.googleusercontent.com",
        width: 200,
        size: "large",
        text: "signup_with",
        theme: "filled_blue",
        type: "standard",
        shape: "pill",
        logo_alignment: "left",
        callback: onSignIn,
      });

      window.google.accounts.id.renderButton(document.getElementById("google-button"), {
        width: 200,
        size: "large",
        text: "signup_with",
        theme: "filled_blue",
        type: "standard",
        shape: "pill",
        logo_alignment: "left",
      });
    }
  }, [window.google]);

  const onSignIn = (googleUser: { [k: string]: string }) => {
    const token: string = googleUser.credential;
    dispatch(signup(token));
  };

  return <div id="google-button"></div>;
};
