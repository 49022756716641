import { Box } from "@mui/material";
import React, { FC } from "react";
import { VacancyCreateEditMainDetails } from "./VacancyCreateEditMainDetails/VacancyCreateEditMainDetails";
import { VacancyBenefitsCreate } from "./VacancyCreateEditItems/VacancyBenefitsCreate/VacancyBenefitsCreate";
import { VacancyResponsibilitiesCreate } from "./VacancyCreateEditItems/VacancyResponsibilitiesCreate/VacancyResponsibilitiesCreate";
import { VacancySkillsCreate } from "./VacancyCreateEditItems/VacancySkillsCreate/VacancySkillsCreate";

interface VacancyCreateEditBasicInformationProps {
  formik: any;
  locations: string[];
}

export const VacancyCreateEditBasicInformation: FC<VacancyCreateEditBasicInformationProps> = ({
  formik,
  locations,
}) => {
  return (
    <Box>
      <VacancyCreateEditMainDetails formik={formik} locations={locations} />
      <Box
        display="block"
        alignSelf="center"
        justifyContent="space-between"
        sx={{ paddingRight: "1em" }}
      >
        <VacancyResponsibilitiesCreate formik={formik} />
        <VacancySkillsCreate formik={formik} />
        <VacancyBenefitsCreate formik={formik} />
      </Box>
    </Box>
  );
};
