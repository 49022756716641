import { Box, FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import React, { FC } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { dateToDDMMYYYY, dateToYYYMMDD } from "../../utils/date";
import { WidgetsSharp } from "@mui/icons-material";

interface InputDateProps {
  disableWeekends?: (date: Date) => boolean;
  disabled?: boolean;
  width?: string;
  value: string;
  label: string;
  error: string | undefined;
  name: string;
  minDate?: Date;
  handleChange: (e: any) => void;
}
export const InputDate: FC<InputDateProps> = ({
  disableWeekends,
  value,
  label,
  error,
  name,
  minDate,
  handleChange,
  width,
  disabled,
}) => {
  const onChange = (e: Date | null) => {
    if (e) {
      handleChange({ target: { value: dateToYYYMMDD(e), name: name }, type: "change" });
    }
  };

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  return (
    <Box
      sx={{
        padding: "1em",
        width: width,
      }}
    >
      <Typography variant="body1" sx={{ color: error ? "#d32f2f" : "inherit", fontSize: "14px" }}>
        {label}
      </Typography>
      <FormControl fullWidth variant="standard" error={Boolean(error)}>
        <DesktopDatePicker
          shouldDisableDate={disableWeekends}
          disabled={disabled}
          inputFormat="dd/MM/yyyy"
          value={value == null ? null : new Date(value)}
          onChange={onChange}
          minDate={minDate ? minDate : undefined}
          renderInput={(params) => (
            <TextField
              onKeyDown={onKeyDown}
              {...params}
              inputProps={{ ...params.inputProps, readOnly: false }}
              sx={{
                width: "100%",
                backgroundColor: "#C9ECE4",
                padding: "0.5em",
                borderRadius: "24px",
                "& div": {
                  height: "32px",
                },
                "& fieldset": {
                  border: 0,
                },
              }}
            />
          )}
        />
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </Box>
  );
};
