import React, { FC } from "react";
import { Challenge } from "../../../../types/mkt/Challenge";
import { Solution } from "../../../../types/mkt/Solution";
import { BlogPostImage } from "../../BlogPost/BlogPostDetails/BlogPostDetailsHeader/BlogPostImage/BlogPostImage";
import { BlogPostDetailsHeaderContent } from "../../BlogPost/BlogPostDetails/BlogPostDetailsHeader/BlogPostDetailsHeaderContent/BlogPostDetailsHeaderContent";
import { CaseStudyDetailsChallenge } from "./CaseStudyDetailsChallenge/CaseStudyDetailsChallenge";
import { CaseStudyDetailsResults } from "./CaseStudyDetailsResults/CaseStudyDetailsResults";
import { CaseStudyDetailsSolution } from "./CaseStudyDetailsSolution/CaseStudyDetailsSolution";
import "./CaseStudyDetails.css";

interface CaseStudyDetailsProps {
  title: string;
  subtitle: string;
  date: string;
  readTime: string;
  image: string;
  challenge: Challenge;
  solution: Solution;
  results: string;
}

export const CaseStudyDetails: FC<CaseStudyDetailsProps> = ({
  title,
  subtitle,
  date,
  readTime,
  image,
  challenge,
  solution,
  results,
}) => {
  return (
    <div>
      <section className="CaseStudyDetails-header">
        <BlogPostImage image={image} />
        <BlogPostDetailsHeaderContent
          author={null}
          title={title}
          subtitle={subtitle}
          categories={null}
          readTime={readTime + " min."}
          date={date}
        />
      </section>
      <section className="CaseStudyDetails-info">
        <div>
          <CaseStudyDetailsChallenge challenge={challenge} />
        </div>
        <div>
          <CaseStudyDetailsSolution solution={solution} />
        </div>
        <div>
          <CaseStudyDetailsResults results={results} />
        </div>
      </section>
    </div>
  );
};
