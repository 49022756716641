import { Box } from "@mui/material";
import React, { FC } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { setVacancies } from "../../../../../stores/mkt/Career.store";

import { useTypedDispatch } from "../../../../../stores/store";
import { Vacancy } from "../../../../../types/mkt/Vacancy";
import { reorder } from "../../../../../utils/array";
import { VacancyDnDSingle } from "./VacancyDnDSingle/VacancyDnDSingle";

interface VacancyDndProps {
  vacancies: Vacancy[];
}

export const VacancyDnd: FC<VacancyDndProps> = ({ vacancies }) => {
  const dispatch = useTypedDispatch();

  // Function to update list on drop
  const handleDrop = (result: any) => {
    // Ignore drop outside droppable container
    if (!result.destination) {
      return;
    }

    const items = reorder(vacancies, result.source.index, result.destination.index);
    dispatch(setVacancies(items));
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId={"dndVacancies"}>
            {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
              <Box ref={dropProvided.innerRef}>
                {vacancies.map((vacancy, index: number) => (
                  <Draggable index={index} draggableId={vacancy.id} key={vacancy.id}>
                    {(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => (
                      <div
                        ref={dragProvided.innerRef}
                        key={vacancy.id}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                      >
                        <VacancyDnDSingle vacancy={vacancy} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {dropProvided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
};
