import { Typography } from "@mui/material";
import React, { FC } from "react";
import { Author } from "../../../../../../types/mkt/Author";
import { BlogPostCategories } from "./BlogPostCategories/BlogPostCategories";
import { BlogPostAuthor } from "./BlogPostAuthor/BlogPostAuthor";
import { BlogPostInfo } from "./BlogPostInfo/BlogPostInfo";

import "./BlogPostDetailsHeaderContent.css";
import { Category } from "../../../../../../types/mkt/Category";

interface BlogPostDetailsHeaderContentProps {
  author: Author | null;
  title: string;
  subtitle: string;
  categories: Category[] | null;
  date: string;
  readTime: string;
}

export const BlogPostDetailsHeaderContent: FC<BlogPostDetailsHeaderContentProps> = ({
  author,
  title,
  subtitle,
  categories,
  date,
  readTime,
}) => {
  return (
    <div className="BlogPostDetailsHeaderContent-content">
      <BlogPostCategories categories={categories} />
      <Typography variant="h6" className="BlogPostDetailsHeaderContent-title">
        {title}
      </Typography>
      <Typography variant="subtitle1" className="BlogPostDetailsHeaderContent-subtitle">
        {subtitle}
      </Typography>

      <div className="BlogPostDetailsHeaderContent-footer">
        <BlogPostInfo date={date} readTime={readTime} />
        {author && <BlogPostAuthor author={author} />}
      </div>
    </div>
  );
};
