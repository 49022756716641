import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TimeReportService from "../../services/internal/TimeReport.service";
import { TimeReportState } from "../../types/internal/timeReport/TimeReportState";
import { emptyTimeReport } from "../../data/internal/timeReport";
import { TimeReport } from "../../types/internal/timeReport/TimeReport";

const timeReportSlice = createSlice({
  name: "timeReport",
  initialState: {
    loading: false,
    error: null,
    timeReport: emptyTimeReport("", ""),
    created: false,
    edited: false,
  } as TimeReportState,
  reducers: {
    setCreated(state, action) {
      state.created = action.payload;
    },
    setEdited(state, action) {
      state.edited = action.payload;
    },
    setTimeReport(state, action) {
      state.timeReport = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTimeReport.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getTimeReport.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.timeReport = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getTimeReport.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(createTimeReport.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.created = false;
      }
    });
    builder.addCase(createTimeReport.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.created = true;
      }
    });
    builder.addCase(createTimeReport.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(editTimeReport.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.edited = false;
      }
    });
    builder.addCase(editTimeReport.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.edited = true;
      }
    });
    builder.addCase(editTimeReport.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

export const getTimeReport = createAsyncThunk(
  "timeReport/getByEmployee",
  async (timeReport: { employee: string; from: string; to: string; token: string }) => {
    const response = await TimeReportService.getTimeReport(
      timeReport.employee,
      timeReport.from,
      timeReport.to
    );
    return response.timeReport;
  }
);

export const createTimeReport = createAsyncThunk(
  "timeReport/create",
  async (data: { timeReport: TimeReport; token: string }) => {
    const response = await TimeReportService.createTimeReport(data.timeReport);
    return response.timeReport;
  }
);

export const editTimeReport = createAsyncThunk(
  "timeReport/edit",
  async (data: { timeReportId: string; timeReport: TimeReport; token: string }) => {
    const response = await TimeReportService.editTimeReport(data.timeReportId, data.timeReport);
    return response.timeReport;
  }
);

export default timeReportSlice.reducer;
export const { setCreated, setEdited, setTimeReport } = timeReportSlice.actions;
