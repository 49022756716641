import React, { FC, useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimeReportTable } from "./TimeReportTable/TimeReportTable";
import {
  getTimeReport,
  createTimeReport,
  editTimeReport,
} from "../../../../stores/internal/TimeReport.store";
import { dateToYYYMMDD } from "../../../../utils/date";
import { useFormik } from "formik";
import { timeReportValidationSchema } from "../../../../utils/internal/timeReport";
import "./TimeReportHours.css";
import {
  getSubmissionStatusFromString,
  getSubmissionStatusText,
  isEditOrDeleteEnabledAccordingToSubmissionStatus,
  SubmissionStatus,
} from "../../../../data/common/SubmissionStatus";
import { getAllWBS } from "../../../../stores/common/WBS.store";
import { getVacationForTimeReport } from "../../../../stores/internal/Vacation.store";
import { Vacation } from "../../../../types/internal/vacation/Vacation";
import { WORKING_HOURS } from "../../../../constants/dates.const";

interface TimeReportHoursProps {}

export const TimeReportHours: FC<TimeReportHoursProps> = ({}) => {
  const dispatch = useTypedDispatch();
  const { token, email } = useTypedSelector((state: RootState) => state.auth);
  const { timeReport } = useTypedSelector((state: RootState) => state.timeReport);
  const { vacationsForTimeReport } = useTypedSelector((state: RootState) => state.vacation);
  const [date, setDate] = useState<Date | null>(new Date());
  const [dates, setDates] = useState<Date[] | null>([]);

  const [fetchTimeReportFinished, setFetchTimeReportFinished] = useState<Boolean>(false);
  const [fetchVacationsFinished, setFetchVacationsFinished] = useState<Boolean>(false);
  const [fetchWBSFinished, setFetchWBSFinished] = useState<Boolean>(false);
  const [getAllDaysInMonthFinished, setGetAllDaysInMonthFinished] = useState<Boolean>(false);
  const [areThereRowsWithAllValuesZeroFlag, setAreThereRowsWithAllValuesZeroFlag] =
    useState<Boolean>(false);

  const formik = useFormik({
    initialValues: { ...timeReport },
    onSubmit: () => {},
    enableReinitialize: true,
    validationSchema: timeReportValidationSchema,
  });

  useEffect(() => {
    resetState();
    prepareData(date?.getFullYear(), date?.getMonth());
  }, [date]);

  const resetState = () => {
    setAreThereRowsWithAllValuesZeroFlag(false);
    setFetchTimeReportFinished(false);
    setFetchVacationsFinished(false);
    setFetchWBSFinished(false);
    setGetAllDaysInMonthFinished(false);
  };

  const prepareData = async (year: any, month: any) => {
    const firstDate = new Date(year, month, 1);
    const lastDate = new Date(year, month + 1, 1);
    clearReportedTime();
    await fetchTimeReport(dateToYYYMMDD(firstDate), dateToYYYMMDD(lastDate));
    await fetchVacations(dateToYYYMMDD(firstDate), dateToYYYMMDD(lastDate));
    await fetchWBS();
    await getAllDaysInMonth(firstDate, month);
  };

  const clearReportedTime = () => {
    formik.values.reportedTime = [];
  };

  const fetchTimeReport = async (from: string, to: string) => {
    await dispatch(
      getTimeReport({
        employee: email,
        from: from,
        to: to,
        token: token!!,
      })
    ).then(() => {
      setFetchTimeReportFinished(true);
    });
  };

  const fetchVacations = async (from: string, to: string) => {
    await dispatch(
      getVacationForTimeReport({
        employeeEmail: email,
        startDate: from,
        endDate: to,
        page: 0,
        size: 35,
      })
    ).then(() => {
      setFetchVacationsFinished(true);
    });
  };

  const fetchWBS = async () => {
    await dispatch(getAllWBS()).then(() => {
      setFetchWBSFinished(true);
    });
  };

  const getAllDaysInMonth = async (fromDate: any, month: number) => {
    const dates = [];
    const firstDate = fromDate;
    while (firstDate.getMonth() === month) {
      dates.push(new Date(firstDate));
      firstDate.setDate(firstDate.getDate() + 1);
    }
    setDates(dates);
    setGetAllDaysInMonthFinished(true);
  };

  const handleChange = async (value: any, name: string) => {
    const e = { target: { value: value, name: name } };
    formik.handleChange(e);
  };

  const handleChangeWbs = async (wbs: any, index: number) => {
    let aux = formik.values.reportedTime[index];
    aux = { ...aux, wbs: wbs };
    formik.setFieldValue(`reportedTime[${index}]`, aux);
    handleChange(wbs.description, `reportedTime[${index}].wbs.description`);
    handleChange(wbs.code, `reportedTime[${index}].wbs.code`);
  };

  const checkIfAllowedToSubmit = () => {
    return (
      !areThereActiveErrors() &&
      isAnyTimeReportRowAdded() &&
      checkIfAllowedToEdit() &&
      !areThereRowsWithAllValuesZeroFlag
    );
  };

  const checkIfAllowedToEdit = () => {
    return (
      isEditOrDeleteEnabledAccordingToSubmissionStatus(formik.values.status) &&
      checkIfAllowedToSubmitForThisMonth()
    );
  };

  const checkIfAllowedToSubmitForThisMonth = () => {
    if (date == null) {
      return true;
    }
    const lowerLimitDate = getLowerLimitDateToAllowEdit();
    const upperLimitDate = getUpperLimitDateToAllowEdit();
    const isInsideLowerLimit = date >= lowerLimitDate;
    const isInsideUpperLimit = date <= upperLimitDate;
    return isInsideLowerLimit && isInsideUpperLimit;
  };

  const getLowerLimitDateToAllowEdit = () => {
    const lowerLimitDate = new Date();
    lowerLimitDate.setMonth(lowerLimitDate.getMonth() - 1);
    lowerLimitDate.setDate(lowerLimitDate.getDay() - 2);
    return lowerLimitDate;
  };

  const getUpperLimitDateToAllowEdit = () => {
    const upperLimitDate = new Date();
    upperLimitDate.setMonth(upperLimitDate.getMonth() + 1);
    upperLimitDate.setDate(upperLimitDate.getDay() - 2);
    return upperLimitDate;
  };

  const isAnyTimeReportRowAdded = () => {
    if (formik.values.reportedTime != undefined) {
      return formik.values.reportedTime.length > 0;
    }
    return false;
  };

  const areThereActiveErrors = () => {
    if (formik.errors.reportedTime != undefined) {
      return formik.errors.reportedTime.length > 0;
    }
    return false;
  };

  const prepareCreateTimeReport = async () => {
    formik.errors = await formik.validateForm(formik.values);
    if (Object.keys(formik.errors).length > 0) {
      return;
    }
    await computeAndSetFromAndToDate();
    if (getSubmissionStatusFromString(formik.values.status) == SubmissionStatus.REJECTED) {
      await dispatch(
        editTimeReport({ timeReportId: timeReport.id, timeReport: formik.values, token: token!! })
      );
    } else {
      await dispatch(createTimeReport({ timeReport: formik.values, token: token!! }));
    }
    prepareData(date?.getFullYear(), date?.getMonth());
  };

  const computeAndSetFromAndToDate = async () => {
    var fromDate = getFirstMonthDate();
    var toDate = getLastMonthDate();
    formik.values.submitted = dateToYYYMMDD(new Date());
    formik.values.employee = email;
    formik.values.from = dateToYYYMMDD(fromDate);
    formik.values.to = dateToYYYMMDD(toDate);
    formik.values.to = dateToYYYMMDD(toDate);
  };

  const getFirstMonthDate = () => {
    var fromDate = new Date();
    if (dates != null && dates != undefined) {
      fromDate = dates[0];
    }
    return fromDate;
  };

  const getLastMonthDate = () => {
    var toDate = new Date();
    if (dates != null && dates != undefined) {
      toDate = dates[dates?.length - 1];
    }
    return toDate;
  };

  const getAllApprovedVacationsDates = () => {
    var vacationDates: Date[] = [];
    if (vacationsForTimeReport == undefined || vacationsForTimeReport.length == 0) {
      return vacationDates;
    }
    vacationsForTimeReport.map((vacation: Vacation) => {
      if (getSubmissionStatusFromString(vacation.status) == SubmissionStatus.APPROVED) {
        var vacationStartDate = new Date(vacation.startDate);
        var vacationEndDate = new Date(vacation.endDate);
        var currentDate = vacationStartDate;
        while (currentDate <= vacationEndDate) {
          vacationDates.push(currentDate);
          currentDate = addDay(currentDate);
        }
      }
    });
    return vacationDates;
  };

  const addDay = (currentDate: Date) => {
    let date = new Date(currentDate);
    date.setDate(date.getDate() + 1);
    return date;
  };

  const areThereRowsWithAllValuesZero = (existInvalidRows: boolean) => {
    setAreThereRowsWithAllValuesZeroFlag(existInvalidRows);
  };
  const showTable =
    fetchTimeReportFinished &&
    fetchVacationsFinished &&
    fetchWBSFinished &&
    getAllDaysInMonthFinished;

  return (
    <div>
      <div>
        <Box className="TimeReportHours-container">
          <Box className="TimeReportHours-submiteContainer">
            <Button
              className={
                checkIfAllowedToSubmit()
                  ? "TimeReportHours-submiteButton"
                  : "TimeReportHours-submiteButtonDisabled"
              }
              onClick={prepareCreateTimeReport}
              disabled={!checkIfAllowedToSubmit()}
            >
              Submit MyTe
            </Button>
          </Box>
          <Box className="TimeReportHours-calendarContainer">
            <Box className="TimeReportHours-submiteContainer" paddingRight={"1em"}>
              <Box display="flex" alignItems="center">
                <Box display="flex" paddingRight="8em">
                  <Typography className="TimeReportHours-status">Status:&nbsp;&nbsp;</Typography>
                  <Typography className="TimeReportHours-trStatus">
                    {getSubmissionStatusText(formik.values.status)}
                  </Typography>
                </Box>
                <DatePicker
                  views={["month", "year"]}
                  label="Time Period"
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} sx={{ svg: { fill: "#209973" } }} />
                  )}
                />
              </Box>
            </Box>
            {showTable && (
              <TimeReportTable
                dates={dates}
                reportedTime={formik.values.reportedTime}
                handleChange={handleChange}
                handleChangeWbs={handleChangeWbs}
                checkIfThereAreRows={isAnyTimeReportRowAdded}
                error={formik.errors}
                allowedToEdit={checkIfAllowedToEdit()}
                vacationDays={getAllApprovedVacationsDates()}
                monthFirstDate={getFirstMonthDate()}
                monthLastDate={getLastMonthDate()}
                areThereRowsWithAllValuesZero={areThereRowsWithAllValuesZero}
                status={formik.values.status}
              />
            )}
          </Box>
        </Box>
      </div>
    </div>
  );
};
