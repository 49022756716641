import React, { FC, useEffect } from "react";
import { PageCreate } from "../../../../components/mkt/Global/Pages/PageCreate";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { useFormik } from "formik";
import { teamMemberValidationSchema } from "../../../../utils/mkt/teamMember";
import { useNavigate } from "react-router-dom";
import { createTeamMember, setCreated } from "../../../../stores/mkt/TeamMember.store";
import { emptyTeamMember } from "../../../../data/mkt/teamMember";
import { TeamMemberCreateEdit } from "../../../../components/mkt/TeamMember/TeamMemberCreateEdit/TeamMemberCreateEdit";

export const TeamMemberCreatePage: FC = () => {
  const dispatch = useTypedDispatch();
  const { created } = useTypedSelector((state: RootState) => state.teamMembers);
  const navigate = useNavigate();

  useEffect(() => {
    if (created) {
      dispatch(setCreated(false));
      navigate("/team_members");
    }
  }, [created]);

  const createAction = async () => {
    formik.errors = await formik.validateForm(formik.values);

    if (Object.keys(formik.errors).length > 0) {
      return;
    }

    dispatch(createTeamMember({ teamMember: formik.values }));
  };

  const formik = useFormik({
    initialValues: { ...emptyTeamMember, order: 100 },
    onSubmit: (userInputData) => {},
    validationSchema: teamMemberValidationSchema,
  });

  return (
    <PageCreate
      pageContent={{
        pageTitle: "Create Team Member",
        previousPage: "/team_members",
        action: createAction,
        type: "Create",
        tabs: null,
        switchTabAction() {},
      }}
    >
      <TeamMemberCreateEdit formik={formik} />
    </PageCreate>
  );
};
