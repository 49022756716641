import React, { FC } from "react";

export const MyKodly: FC = () => {
  return (
    <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.462 0.0478516C7.871 0.206142 4.44701 1.94734 2.35921 4.63828C2.02517 4.95486 1.94165 5.35059 1.69112 5.58803C-0.563706 9.22871 -0.563706 13.74 1.69112 17.3015L6.95237 11.4448L1.69112 5.66717C1.94165 5.42974 2.02517 5.03401 2.35921 4.71743L8.5391 11.5239L2.35921 18.3304C4.53052 21.1797 7.871 22.8417 11.462 23V0.0478516Z"
        fill="white"
      />
      <path
        d="M22.4872 17.3807C23.6564 15.6395 24.1575 13.6608 24.1575 11.5239C24.1575 9.387 23.4894 7.48751 22.4872 5.58803C22.2367 5.1923 21.9862 4.87572 21.7356 4.55914C19.6478 1.86819 16.3073 0.206142 12.6328 0.0478516V23C16.3073 22.7626 19.5643 21.1005 21.7356 18.3304L15.5557 11.5239L21.7356 4.71743C21.9862 5.03401 22.2367 5.35059 22.4872 5.74632L17.1425 11.6031L22.4872 17.3807Z"
        fill="white"
      />
    </svg>
  );
};
