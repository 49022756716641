import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ExpenseService from "../../services/internal/Expense.service";
import { emptyExpense } from "../../data/common/expense";
import { ExpenseState } from "../../types/internal/expenses/ExpenseState";
import { Expense } from "../../types/internal/expenses/Expense";
import { emptyExpenseSummary } from "../../data/internal/cv/expenseSummary";
import { emptyExpensesPaginated } from "../../data/common/expensesPaginated";

const expenseSlice = createSlice({
  name: "expenses",
  initialState: {
    loading: false,
    error: null,
    expense: emptyExpense,
    expensesPaginated: emptyExpensesPaginated,
    created: false,
    edited: false,
    expensesSummary: emptyExpenseSummary,
    expensesTypes: [],
  } as ExpenseState,
  reducers: {
    setCreated(state, action) {
      state.created = action.payload;
    },
    setExpense(state, action) {
      state.expense = action.payload;
    },
    setExpensesSummary(state, action) {
      state.expensesSummary = action.payload;
    },
    setExpensesTypes(state, action) {
      state.expensesTypes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExpensesByEmployee.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getExpensesByEmployee.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.expensesPaginated = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getExpensesByEmployee.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(getExpensesSummaryByEmployee.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getExpensesSummaryByEmployee.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.expensesSummary = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getExpensesSummaryByEmployee.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(createExpense.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.created = false;
      }
    });
    builder.addCase(createExpense.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.created = true;
      }
    });
    builder.addCase(createExpense.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(getAllExpensesType.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getAllExpensesType.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.expensesTypes = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getAllExpensesType.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(getExpenseById.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getExpenseById.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.expense = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getExpenseById.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

export const getExpensesByEmployee = createAsyncThunk(
  "expense/getExpensesByEmployee",
  async (expense: {
    employee: string;
    from: string;
    to: string;
    token: string;
    page: number | null;
    size: number | null;
    sort: string | null;
    order: string | null;
  }) => {
    const response = await ExpenseService.getExpensesByEmployee(
      expense.token,
      expense.employee,
      expense.from,
      expense.to,
      expense.page,
      expense.size,
      expense.sort,
      expense.order
    );
    return response.expensesPaginated;
  }
);

export const createExpense = createAsyncThunk(
  "expense/create",
  async (data: { expense: Expense }) => {
    const response = await ExpenseService.createExpense(data.expense);
    return response.expense;
  }
);

export const getExpensesSummaryByEmployee = createAsyncThunk(
  "expense/getExpensesSummaryFromEmployee",
  async (expense: { employee: string }) => {
    const response = await ExpenseService.getExpensesSummaryByEmployee(expense.employee);
    return response.expensesSummary;
  }
);

export const getAllExpensesType = createAsyncThunk("expense/getAllExpensesType", async () => {
  const response = await ExpenseService.getAllExpensesType();
  return response.expensesTypes;
});

export const getExpenseById = createAsyncThunk(
  "expense/getExpenseById",
  async (data: { id: string }) => {
    const response = await ExpenseService.getExpenseById(data.id);
    return response.expense;
  }
);

export default expenseSlice.reducer;
export const { setCreated, setExpense, setExpensesSummary, setExpensesTypes } =
  expenseSlice.actions;
