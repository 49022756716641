import React, { FC, useEffect, useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { TimeReportedByProject } from "../../../../../types/internal/timeReport/TimeReportedByProject";
import { dateToYYYMMDD, doesDateBelongToWeekend } from "../../../../../utils/date";
import { emptyTimeReportedByProject } from "../../../../../data/internal/timeReportedByProject";
import { emptyWBS } from "../../../../../data/common/wbs";
import { AddCircleOutlineButton } from "../../../../common/buttons/AddCircleOutlineButton/AddCircleOutlineButton";
import { TimeReportRow } from "./TimeReportRow/TimeReportRow";
import "./TimeReportTable.css";
import { WORKING_HOURS } from "../../../../../constants/dates.const";
import {
  getSubmissionStatusFromString,
  SubmissionStatus,
} from "../../../../../data/common/SubmissionStatus";

interface TimeReportTableProps {
  dates: Date[] | null;
  vacationDays: Date[];
  reportedTime: TimeReportedByProject[];
  handleChange: (value: any, name: string) => void;
  handleChangeWbs: (wbs: any, index: number) => void;
  checkIfThereAreRows: () => boolean;
  error: any;
  allowedToEdit: boolean;
  monthFirstDate: Date;
  monthLastDate: Date;
  areThereRowsWithAllValuesZero: (existInvalidRows: boolean) => void;
  status: string;
}

export const TimeReportTable: FC<TimeReportTableProps> = ({
  dates,
  vacationDays,
  reportedTime,
  handleChange,
  handleChangeWbs,
  checkIfThereAreRows,
  error,
  allowedToEdit,
  monthFirstDate,
  monthLastDate,
  areThereRowsWithAllValuesZero,
  status,
}) => {
  let totalMonth = 0;
  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => {
    addEmptyRowIfNoneExists();
    showErrorsIfRejected();
  }, [dates]);

  const addEmptyRowIfNoneExists = () => {
    if (!checkIfThereAreRows()) {
      addRow();
    }
  };

  const showErrorsIfRejected = () => {
    if (getSubmissionStatusFromString(status) == SubmissionStatus.REJECTED) {
      setShowErrors(true);
    }
  };

  const interceptorHandleChange = (value: any, name: string) => {
    setShowErrors(true);
    handleChange(value, name);
  };

  const interceptorHandleChangeWbs = (wbs: any, index: number) => {
    setShowErrors(true);
    handleChangeWbs(wbs, index);
  };

  const getVacationRowIfExists = () => {
    var vacationsRow: TimeReportedByProject = {
      wbs: emptyWBS,
      timeReported: [],
    };
    var currentDate = monthFirstDate;
    while (currentDate <= monthLastDate) {
      vacationsRow.timeReported.push({
        date: dateToYYYMMDD(currentDate),
        reportedTime:
          findDateInArray(currentDate) != undefined
            ? WORKING_HOURS.FULL_DAY
            : WORKING_HOURS.DAY_OFF,
      });
      currentDate = addDay(currentDate);
    }
    return vacationsRow;
  };

  const findDateInArray = (dateToFind: Date) => {
    return vacationDays.find((date: Date) => date.getTime() === dateToFind.getTime());
  };

  const addDay = (currentDate: Date) => {
    let date = new Date(currentDate);
    date.setDate(date.getDate() + 1);
    return date;
  };

  const addRow = () => {
    if (reportedTime == null || reportedTime == undefined) {
      reportedTime = [];
    }
    let data: TimeReportedByProject = emptyTimeReportedByProject;
    data.wbs = emptyWBS;
    data.timeReported = [];
    dates?.forEach((date) => {
      data.timeReported.push({ date: dateToYYYMMDD(date), reportedTime: 0 });
    });
    handleChange([...reportedTime, data], "reportedTime");
  };

  const deleteRow = (index: number) => {
    let aux = [...reportedTime];
    aux.splice(index, 1);
    handleChange(aux, "reportedTime");
  };

  const computeDayTotalHours = (index: number) => {
    let totalDay: number = 0;
    if (Array.isArray(reportedTime) && reportedTime.length > 0) {
      reportedTime.forEach((reported) => {
        if (reported.timeReported[index] != undefined && reported.timeReported[index] != null) {
          if (
            reported.timeReported[index].reportedTime != undefined &&
            reported.timeReported[index].reportedTime != null
          ) {
            totalDay += Number.isNaN(reported.timeReported[index].reportedTime)
              ? 0
              : reported.timeReported[index].reportedTime;
            totalMonth += Number.isNaN(reported.timeReported[index].reportedTime)
              ? 0
              : reported.timeReported[index].reportedTime;
          }
        }
      });
    }
    if (totalDay) return totalDay;
    return 0;
  };

  const getErrors = () => {
    var errors: string[] = [];
    const errorWBSRequired = "WBS Code is required";
    const errorSelectWBS = "You must select a WBS code";
    const errorProjectUnique = "Project must be unique";
    const errorDuplicatedWbs = "You can't select the same WBS code";
    const errorEnterValidNumber = "You must enter a valid number";
    const errorAllZeros = "You can't submit a row with all values nil";

    if (!allowedToEdit) {
      return errors;
    }
    if (error && error.reportedTime) {
      error.reportedTime.map((singleError: any) => {
        if (singleError == null) {
          return errors;
        }
        if (
          singleError.wbs != undefined &&
          singleError.wbs.code == errorWBSRequired &&
          !errors.includes(errorSelectWBS)
        ) {
          errors.push(errorSelectWBS);
        }
        if (
          singleError.name == errorProjectUnique &&
          !errors.includes(errorDuplicatedWbs) &&
          !errors.includes(errorSelectWBS)
        ) {
          errors.push(errorDuplicatedWbs);
        }
        if (singleError.timeReported && !errors.includes(errorEnterValidNumber)) {
          errors.push(errorEnterValidNumber);
        }
      });
    }
    if (areThereRowsWithOnlyZeroValues()) {
      errors.push(errorAllZeros);
    }
    return errors;
  };

  const areThereRowsWithOnlyZeroValues = () => {
    var foundRowWithOnlyZero = false;
    reportedTime.forEach((report) => {
      const daysNotZero: any[] = report.timeReported.filter(
        (time) => time.reportedTime != WORKING_HOURS.DAY_OFF
      );
      if (daysNotZero.length == 0) {
        foundRowWithOnlyZero = true;
        return;
      }
    });
    areThereRowsWithAllValuesZero(foundRowWithOnlyZero);
    return foundRowWithOnlyZero;
  };

  return (
    <div className="TimeReportTable-tableContainer">
      <table className="TimeReportTable-table">
        <thead>
          <tr className="TimeReportTable-headRow">
            <th className="TimeReportTable-th">WBS Code</th>
            {dates &&
              dates.map((date: any, index: number) => {
                return <th key={index}>{date.getDate()}</th>;
              })}
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {vacationDays && vacationDays.length > 0 && (
            <TimeReportRow
              key={0}
              isVacationsRow={true}
              timeReportByProject={getVacationRowIfExists()}
              index={0}
              error={error}
              allowedToEdit={false}
              deleteRow={deleteRow}
              interceptorHandleChangeWbs={interceptorHandleChangeWbs}
              interceptorHandleChange={interceptorHandleChange}
            />
          )}
          {reportedTime &&
            reportedTime.map((singleReportedTime: TimeReportedByProject, index: number) => {
              return (
                <TimeReportRow
                  key={index}
                  isVacationsRow={false}
                  timeReportByProject={singleReportedTime}
                  vacationDays={vacationDays}
                  index={index}
                  error={error}
                  allowedToEdit={allowedToEdit}
                  deleteRow={deleteRow}
                  interceptorHandleChangeWbs={interceptorHandleChangeWbs}
                  interceptorHandleChange={interceptorHandleChange}
                />
              );
            })}
          <tr className="TimeReportTable-totalRowHead">
            <th className="TimeReportTable-totalRowHeadWBS"></th>
            {dates &&
              dates.map((date: any, index: number) => {
                return (
                  <th
                    className={
                      doesDateBelongToWeekend(date)
                        ? "TimeReportTable-weekendTotalRow"
                        : "TimeReportTable-totalRow"
                    }
                    key={index}
                  >
                    {computeDayTotalHours(index)}
                  </th>
                );
              })}
            <th className="TimeReportTable-totalRow">{totalMonth}</th>
            <td className="TimeReportTable-totalRowHeadDelete"></td>
          </tr>
        </tbody>
      </table>
      {showErrors && (
        <Box paddingTop="1em" sx={{ color: "#d32f2f", fontSize: "15px" }}>
          {getErrors().map((error, index) => {
            return <li key={index}>{error}</li>;
          })}
        </Box>
      )}
      {
        <Box display="flex" justifyContent="center" paddingTop="4em">
          <AddCircleOutlineButton
            width="213px"
            height="42px"
            text="Add a new row"
            enabled={allowedToEdit}
            onClickAction={addRow}
          />
        </Box>
      }
    </div>
  );
};
