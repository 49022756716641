import { Typography } from "@mui/material";
import React, { FC } from "react";
import { Input } from "../../../Inputs/Input";
import "./PageCreateEditSEO.css";

interface PageCreateEditSEOProps {
  formik: any;
}

export const PageCreateEditSEO: FC<PageCreateEditSEOProps> = ({ formik }) => {
  return (
    <div className="PageCreateEditSEO-container">
      <div className="PageCreateEditSEO-headersContainer">
        <Input
          error={formik.errors.pageTitle}
          value={formik.values.pageTitle}
          handleChange={formik.handleChange}
          label="Title"
          name="pageTitle"
          type="text"
          width="50%"
          height="2rem"
        />
        <Input
          error={formik.errors.url}
          value={formik.values.url}
          handleChange={formik.handleChange}
          label="URL"
          name="url"
          type="text"
          width="50%"
          height="2rem"
        />
      </div>
      <div className="PageCreateEditSEO-headers">
        <Typography variant="body1" className="PageCreateEditSEO-headersLabel">
          Headers
        </Typography>
        <div className="PageCreateEditSEO-headersContainer">
          <Input
            error={formik.errors.h1}
            value={formik.values.h1}
            handleChange={formik.handleChange}
            label="H1"
            name="h1"
            type="text"
            width="50%"
            height="2rem"
          />
          <Input
            error={formik.errors.h2}
            value={formik.values.h2}
            handleChange={formik.handleChange}
            label="H2"
            name="h2"
            type="text"
            width="50%"
            height="2rem"
          />
        </div>
      </div>
      <div className="PageCreateEditSEO-metaDescription">
        <Input
          error={formik.errors.metaInfo}
          value={formik.values.metaInfo}
          handleChange={formik.handleChange}
          label="Meta Information"
          name="metaInfo"
          type="text"
          multiline
          maxRows={4}
          width="97%"
          height="7rem"
        />
      </div>
    </div>
  );
};
