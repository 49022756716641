import { Box } from "@mui/material";
import React, { FC } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { setTeamMembers } from "../../../../../stores/mkt/TeamMember.store";
import { useTypedDispatch } from "../../../../../stores/store";
import { TeamMember } from "../../../../../types/mkt/TeamMember";
import { reorder } from "../../../../../utils/array";
import { TeamMemberDnDSingle } from "./TeamMemberDnDSingle/TeamMemberDnDSingle";
import "./TeamMemberDnD.css";

interface TeamMemberDnDProps {
  teamMembers: TeamMember[];
}

export const TeamMemberDnD: FC<TeamMemberDnDProps> = ({ teamMembers }) => {
  const dispatch = useTypedDispatch();

  // Function to update list on drop
  const handleDrop = (result: any) => {
    // Ignore drop outside droppable container
    if (!result.destination) {
      return;
    }

    const items = reorder(teamMembers, result.source.index, result.destination.index);
    dispatch(setTeamMembers(items));
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId={"dndVacancies"}>
            {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
              <Box ref={dropProvided.innerRef}>
                {teamMembers.map((teamMember, index: number) => (
                  <div className="TeamMemberDnD-card" key={teamMember.id}>
                    <Draggable index={index} draggableId={teamMember.id}>
                      {(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => (
                        <div
                          ref={dragProvided.innerRef}
                          key={teamMember.id}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                        >
                          <TeamMemberDnDSingle teamMember={teamMember} />
                        </div>
                      )}
                    </Draggable>
                  </div>
                ))}
                {dropProvided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
};
