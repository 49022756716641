import { Token } from "@mui/icons-material";
import { HTTP_CODES } from "../../constants/httpstatus.const";
import { emptyExpense } from "../../data/common/expense";
import { emptyExpenseSummary } from "../../data/internal/cv/expenseSummary";
import { Expense } from "../../types/internal/expenses/Expense";
import { ExpensePaginated } from "../../types/internal/expenses/ExpensePaginated";
import { ExpenseSummary } from "../../types/internal/expenses/ExpenseSummary";
import { ExpenseType } from "../../types/internal/expenses/ExpenseType";
import {
  apiClient,
  buildConfig,
  buildConfigEmployeeAndDatesAndPaginationAndSort,
  getAuthConfig,
} from "../HttpClient";

const EXPENSE_URL = "/expenses";
const EXPENSE_BY_ID_URL = (id: string) => "/expenses/" + id;
const EXPENSE_BY_EMPLOYEE_URL = (employee: string) => EXPENSE_URL + "/" + employee;
const EXPENSE_SUMMARY_BY_EMPLOYEE = (employee: string) =>
  EXPENSE_BY_EMPLOYEE_URL(employee) + "/summary";
const EXPENSE_TYPE_URL = EXPENSE_URL + "/types";

export default class ExpenseService {
  static getExpensesByEmployee = async (
    token: string,
    employee: string,
    from: string,
    to: string,
    page: number | null,
    size: number | null,
    sort: string | null,
    order: string | null
  ) => {
    try {
      const response = await apiClient.get(
        EXPENSE_URL,
        buildConfigEmployeeAndDatesAndPaginationAndSort(employee, from, to, page, size, sort, order)
      );
      const expenses: Expense[] = response.data.content;
      return {
        expensesPaginated: {
          totalElements: response.data.totalElements,
          totalPages: response.data.totalPages,
          size: response.data.totalSize,
          expenses: expenses,
        },
        code: response.status,
      };
    } catch (err: any) {
      return {
        expensesPaginated: { totalElements: 0, totalPages: 0, size: 0, expenses: [] },
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static getExpensesSummaryByEmployee = async (employee: string) => {
    try {
      const response = await apiClient.get(EXPENSE_SUMMARY_BY_EMPLOYEE(employee), getAuthConfig());
      const expensesSummary: ExpenseSummary = response.data;
      return {
        expensesSummary: expensesSummary,
        code: response.status,
      };
    } catch (err: any) {
      return {
        expensesSummary: emptyExpenseSummary,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static createExpense = async (expense: Expense) => {
    try {
      const response = await apiClient.post(EXPENSE_URL, expense, getAuthConfig());
      return {
        expense: response.data as Expense,
        code: response.status,
      };
    } catch (err: any) {
      return {
        expense: emptyExpense,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static getAllExpensesType = async () => {
    try {
      const response = await apiClient.get(EXPENSE_TYPE_URL, getAuthConfig());
      const expensesTypes: ExpenseType[] = response.data;
      return {
        expensesTypes: expensesTypes,
        code: response.status,
      };
    } catch (err: any) {
      return {
        expensesTypes: [],
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static getExpenseById = async (id: string) => {
    try {
      const response = await apiClient.get(EXPENSE_BY_ID_URL(id), getAuthConfig());
      const expense: Expense = response.data;
      return {
        expense: expense,
        code: response.status,
      };
    } catch (err: any) {
      return {
        expense: emptyExpense,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };
}
