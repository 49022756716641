import { HTTP_CODES } from "../../constants/httpstatus.const";
import { emptyBlogPost } from "../../data/mkt/blogpost";
import { BlogPost } from "../../types/mkt/BlogPost";
import { apiClient, getAuthConfig } from "../HttpClient";

const BLOG_POST_URL = "/blogPost";
const BLOG_POST_BY_ID = (id: string) => BLOG_POST_URL + "/" + id;
const BLOG_POST_PUBLISH = (id: string) => `${BLOG_POST_URL}/${id}/publish`;
const BLOG_POST_UNPUBLISH = (id: string) => `${BLOG_POST_URL}/${id}/unpublish`;

export default class BlogPostService {
  static getBlogPosts = async () => {
    try {
      const response = await apiClient.get(BLOG_POST_URL, getAuthConfig());
      const blogPosts: BlogPost[] = response.data;
      return {
        blogPosts,
        code: response.status,
      };
    } catch (err: any) {
      return {
        blogPosts: [],
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static getBlogPost = async (id: string) => {
    try {
      const response = await apiClient.get(BLOG_POST_BY_ID(id), getAuthConfig());
      const blogPost: BlogPost = response.data;
      return {
        blogPost,
        code: response.status,
      };
    } catch (err: any) {
      return {
        blogPost: emptyBlogPost,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static createBlogPost = async (blogPost: BlogPost) => {
    try {
      const response = await apiClient.post(BLOG_POST_URL, blogPost, getAuthConfig());
      return {
        blogPost: response.data as BlogPost,
        code: response.status,
      };
    } catch (err: any) {
      return {
        blogPost: emptyBlogPost,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static editBlogPost = async (blogPost: BlogPost) => {
    try {
      const response = await apiClient.put(BLOG_POST_URL, blogPost, getAuthConfig());
      return {
        blogPost: response.data as BlogPost,
        code: response.status,
      };
    } catch (err: any) {
      return {
        blogPost: emptyBlogPost,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static deleteBlogPost = async (id: string) => {
    try {
      const response = await apiClient.delete(BLOG_POST_BY_ID(id), getAuthConfig());
      return {
        code: response.status,
      };
    } catch (err: any) {
      return {
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static publishBlogPost = async (id: string) => {
    try {
      const response = await apiClient.put(BLOG_POST_PUBLISH(id), {}, getAuthConfig());
      return {
        blogPost: response.data as BlogPost,
        code: response.status,
      };
    } catch (err: any) {
      return {
        blogPost: emptyBlogPost,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static unpublishBlogPost = async (id: string) => {
    try {
      const response = await apiClient.put(BLOG_POST_UNPUBLISH(id), {}, getAuthConfig());
      return {
        blogPost: response.data as BlogPost,
        code: response.status,
      };
    } catch (err: any) {
      return {
        blogPost: emptyBlogPost,
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };
}
