import React, { FC } from "react";

import "./BlogPostImage.css";

interface BlogPostImageProps {
  image: string;
}

export const BlogPostImage: FC<BlogPostImageProps> = ({ image }) => {
  return (
    <div
      className="BlogPostImage-container"
      style={{
        backgroundImage: `url(${image})`,
      }}
    />
  );
};
