import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { emptyTestimonial } from "../../data/mkt/testimonial";
import TestimonialService from "../../services/mkt/Testimonial.service";
import { TestimonialState } from "../../types/mkt/TestimonialState";
import { Testimonial } from "../../types/mkt/Testimonial";

const testimonialSlice = createSlice({
  name: "testimonials",
  initialState: {
    loading: false,
    error: null,
    testimonials: [],
    testimonial: emptyTestimonial,
    created: false,
    edited: false,
    deleted: false,
  } as TestimonialState,
  reducers: {
    setCreated(state, action) {
      state.created = action.payload;
    },
    setEdited(state, action) {
      state.edited = action.payload;
    },
    setDeleted(state, action) {
      state.deleted = action.payload;
    },
    setTestimonials(state, action) {
      state.testimonials = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTestimonials.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getTestimonials.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.testimonials = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getTestimonials.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(getTestimonial.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getTestimonial.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.testimonial = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getTestimonial.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(createTestimonial.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.created = false;
      }
    });
    builder.addCase(createTestimonial.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.created = true;
      }
    });
    builder.addCase(createTestimonial.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(editTestimonial.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.edited = false;
      }
    });
    builder.addCase(editTestimonial.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.edited = true;
      }
    });
    builder.addCase(editTestimonial.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(deleteTestimonial.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.deleted = false;
      }
    });
    builder.addCase(deleteTestimonial.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.deleted = true;
      }
    });
    builder.addCase(deleteTestimonial.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(publishTestimonial.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.deleted = false;
      }
    });
    builder.addCase(publishTestimonial.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.testimonial = action.payload;
      }
    });
    builder.addCase(publishTestimonial.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

export const getTestimonials = createAsyncThunk("testimonials/getTeamMembers", async () => {
  const response = await TestimonialService.getTestimonials();
  return response.testimonials.sort((a, b) => a.order - b.order);
});

export const getTestimonial = createAsyncThunk(
  "testimonials/getById",
  async (testimonial: { id: string }) => {
    const response = await TestimonialService.getTestimonial(testimonial.id);
    return response.testimonial;
  }
);

export const createTestimonial = createAsyncThunk(
  "testimonials/create",
  async (data: { testimonial: Testimonial }) => {
    const response = await TestimonialService.createTestimonial(data.testimonial);
    return response.testimonial;
  }
);

export const editTestimonial = createAsyncThunk(
  "testimonials/edit",
  async (data: { testimonial: Testimonial }) => {
    const response = await TestimonialService.editTestimonial(data.testimonial);
    return response.testimonial;
  }
);

export const deleteTestimonial = createAsyncThunk(
  "testimonials/delete",
  async (data: { id: string }) => {
    await TestimonialService.deleteTestimonial(data.id);
    return;
  }
);

export const publishTestimonial = createAsyncThunk(
  "testimonials/publish",
  async (data: { id: string; publish: boolean }) => {
    const response = data.publish
      ? await TestimonialService.unpublishTestimonial(data.id)
      : await TestimonialService.publishTestimonial(data.id);
    return response.testimonial;
  }
);

export default testimonialSlice.reducer;
export const { setCreated, setEdited, setDeleted, setTestimonials } = testimonialSlice.actions;
