export const emptyTimeReport = (from: string, to: string) => {
  return {
    id: "",
    employee: "",
    reportedTime: [],
    submitted: "",
    from: from,
    to: to,
    status: "",
    approvers: [],
  };
};
