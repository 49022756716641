import React, { FC } from "react";
import { Service } from "../../../../../../types/mkt/Service";
import { ServiceCard } from "../../ServiceCard/ServiceCard";
import "./ServiceDndSingle.css";

interface ServiceDndSingleProps {
  service: Service;
}

export const ServiceDndSingle: FC<ServiceDndSingleProps> = ({ service }) => {
  return (
    <div className="ServiceDndSingle-singleServiceLink">
      <ServiceCard service={service} editAction={null} publishAction={null} deleteAction={null} />
      <br></br>
    </div>
  );
};
