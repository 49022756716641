import { Typography } from "@mui/material";
import React, { FC } from "react";
import { Author } from "../../../../../../types/mkt/Author";
import { renderHtml } from "../../../../../../utils/renderHtml";

import "./InfoCardSubBodySection.css";

interface InfoCardSubBodySectionProps {
  author: Author | null;
  title: string;
}

export const InfoCardSubBodySection: FC<InfoCardSubBodySectionProps> = ({ author, title }) => {
  return (
    <div className="InfoCardSubBodySection-cardBodyContainer">
      <div className="InfoCardSubBodySection-cardBodyContent">
        <h6
          className={`InfoCardSubBodySection-cardBodyTitle ${
            author ? "InfoCardSubBodySection-cardBodyTitleOneLine" : ""
          }`}
          dangerouslySetInnerHTML={renderHtml(title)}
        ></h6>
      </div>
      {author && (
        <div className="InfoCardSubBodySection-cardBodyItemFooter">
          <div className="InfoCardSubBodySection-cardBodyAuthorInfo">
            <img
              className="InfoCardSubBodySection-cardBodyAuthorImage"
              src={author.photo}
              alt="Author"
            />
            <div className="InfoCardSubBodySection-cardBodyAuthor">
              <Typography variant="body1" className="InfoCardSubBodySection-carBodyText">
                {author.name}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
