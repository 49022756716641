import { Box, FormControl, FormHelperText, MenuItem, Select, Typography } from "@mui/material";
import React, { FC } from "react";
import { Input } from "../../../../../Inputs/Input";

interface VacancyCreateEditMainDetailsProps {
  formik: any;
  locations: string[];
}

export const VacancyCreateEditMainDetails: FC<VacancyCreateEditMainDetailsProps> = ({
  formik,
  locations,
}) => {
  return (
    <Box
      display="flex"
      alignSelf="center"
      justifyContent="space-between"
      sx={{
        paddingRight: "1em",
      }}
    >
      <Input
        error={formik.errors.role}
        value={formik.values.role}
        handleChange={formik.handleChange}
        label="Role"
        name="role"
        type="text"
        width="25%"
        height="2rem"
        maxLength={50}
      />
      <Box sx={{ padding: "1em", width: "25%" }}>
        <Typography variant="body1" sx={{ color: formik.errors.type ? "#d32f2f" : "inherit" }}>
          Type
        </Typography>
        <FormControl fullWidth variant="standard" error={Boolean(formik.errors.type)}>
          <Select
            variant="standard"
            inputProps={{ disableUnderline: true }}
            onChange={formik.handleChange}
            value={formik.values.type}
            size="medium"
            name="type"
            sx={{
              backgroundColor: "#C9ECE4",
              padding: "0.5em",
              borderRadius: "24px",
              borderWidth: "0",
              width: "100%",
              "&::before": {
                borderBottom: 0,
              },
              "&::after": {
                borderBottom: 0,
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottom: 0,
              },
            }}
          >
            <MenuItem value="Full-time">Full-time</MenuItem>
            <MenuItem value="Part-time">Part-time</MenuItem>
          </Select>
          <FormHelperText>{formik.errors.type}</FormHelperText>
        </FormControl>
      </Box>
      <Box
        sx={{
          padding: "1em",
          width: "25%",
        }}
      >
        <Typography variant="body1" sx={{ color: formik.errors.location ? "#d32f2f" : "inherit" }}>
          Location
        </Typography>
        <FormControl fullWidth variant="standard" error={Boolean(formik.errors.location)}>
          <Select
            variant="standard"
            inputProps={{ disableUnderline: true }}
            onChange={formik.handleChange}
            value={formik.values.location}
            name="location"
            label="Location"
            sx={{
              backgroundColor: "#C9ECE4",
              padding: "0.5em",
              borderRadius: "24px",
              borderWidth: "0",
              width: "100%",
              "&::before": {
                borderBottom: 0,
              },
              "&::after": {
                borderBottom: 0,
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottom: 0,
              },
            }}
          >
            {locations &&
              locations.map((location, index) => (
                <MenuItem value={location} key={index}>
                  {location}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{formik.errors.location}</FormHelperText>
        </FormControl>
      </Box>
      <Box
        sx={{
          padding: "1em",
          width: "25%",
        }}
      >
        <Typography
          variant="body1"
          sx={{ color: formik.errors.officeMode ? "#d32f2f" : "inherit" }}
        >
          On site
        </Typography>
        <FormControl fullWidth variant="standard" error={Boolean(formik.errors.officeMode)}>
          <Select
            variant="standard"
            inputProps={{ disableUnderline: true }}
            onChange={formik.handleChange}
            value={formik.values.officeMode}
            name="officeMode"
            sx={{
              backgroundColor: "#C9ECE4",
              padding: "0.5em",
              borderRadius: "24px",
              borderWidth: "0",
              width: "100%",
              "&::before": {
                borderBottom: 0,
              },
              "&::after": {
                borderBottom: 0,
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottom: 0,
              },
            }}
          >
            <MenuItem value="On Site">On Site</MenuItem>
            <MenuItem value="Hybrid">Hybrid</MenuItem>
            <MenuItem value="Remote">Remote</MenuItem>
          </Select>
          <FormHelperText>{formik.errors.officeMode}</FormHelperText>
        </FormControl>
      </Box>
    </Box>
  );
};
