import * as yup from "yup";

export const teamMemberValidationSchema = yup.object({
  image: yup.string().required("Image is required"),
  firstName: yup
    .string()
    .strict()
    .trim("First name should not end in an empty space")
    .required("First name is required")
    .max(15, "First name must be at most 15 characters"),
  lastName: yup
    .string()
    .strict()
    .trim("Last name should not end in an empty space")
    .required("Last name is required")
    .max(15, "Last name must be at most 15 characters"),
  text: yup.string().strict().required("Description is required"),
});
