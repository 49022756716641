import React, { FC } from "react";
import { BlogPost } from "../../../../../../types/mkt/BlogPost";
import { BlogPostCard } from "../../BlogPostCard/BlogPostCard";

interface BlogPostDnDSingleProps {
  blogPost: BlogPost;
}

export const BlogPostDnDSingle: FC<BlogPostDnDSingleProps> = ({ blogPost }) => {
  return (
    <div>
      <BlogPostCard blogPost={blogPost} />
      <br />
    </div>
  );
};
