import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { emptyVacancy } from "../../data/mkt/vacancy";
import VacancyService from "../../services/mkt/Vacancy.service";
import { CareerState } from "../../types/mkt/CareerState";
import { Vacancy } from "../../types/mkt/Vacancy";

const careersSlice = createSlice({
  name: "careers",
  initialState: {
    loading: false,
    error: null,
    vacancies: [],
    vacancy: emptyVacancy,
    created: false,
    edited: false,
    deleted: false,
    publishChange: false,
    locations: [],
  } as CareerState,
  reducers: {
    setCreated(state, action) {
      state.created = action.payload;
    },
    setEdited(state, action) {
      state.edited = action.payload;
    },
    setDeleted(state, action) {
      state.deleted = action.payload;
    },
    setPublishState(state, action) {
      state.publishChange = action.payload;
    },
    setVacancies(state, action) {
      state.vacancies = action.payload;
    },
    setLocations(state, action) {
      state.locations = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVacancies.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getVacancies.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.vacancies = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getVacancies.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(getVacancy.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.vacancy = emptyVacancy;
      }
    });
    builder.addCase(getVacancy.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.vacancy = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getVacancy.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(createVacancy.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.created = false;
      }
    });
    builder.addCase(createVacancy.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.created = true;
      }
    });
    builder.addCase(createVacancy.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(editVacancy.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.edited = false;
      }
    });
    builder.addCase(editVacancy.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.edited = true;
      }
    });
    builder.addCase(editVacancy.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(deleteVacancy.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.deleted = false;
      }
    });
    builder.addCase(deleteVacancy.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.deleted = true;
      }
    });
    builder.addCase(deleteVacancy.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(publishVacancy.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.publishChange = false;
      }
    });
    builder.addCase(publishVacancy.fulfilled, (state, action) => {
      state.loading = false;
      state.publishChange = true;
      state.vacancy = action.payload;
    });
    builder.addCase(publishVacancy.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(getLocations.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getLocations.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.locations = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getLocations.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

export const getVacancies = createAsyncThunk("careers/vacancy/get", async () => {
  const response = await VacancyService.getVacancies();
  return response.vacancies.sort((a, b) => a.order - b.order);
});

export const getVacancy = createAsyncThunk(
  "careers/vacancy/getById",
  async (vacancy: { id: string }) => {
    const response = await VacancyService.getVacancy(vacancy.id);
    return response.vacancy;
  }
);

export const createVacancy = createAsyncThunk(
  "careers/vacancy/create",
  async (data: { vacancy: Vacancy }) => {
    const response = await VacancyService.createVacancy(data.vacancy);
    return response.vacancy;
  }
);

export const editVacancy = createAsyncThunk(
  "careers/vacancy/edit",
  async (data: { vacancy: Vacancy }) => {
    const response = await VacancyService.editVacancy(data.vacancy);
    return response.vacancy;
  }
);

export const deleteVacancy = createAsyncThunk(
  "careers/vacancy/delete",
  async (data: { id: string }) => {
    await VacancyService.deleteVacancy(data.id);
    return;
  }
);

export const publishVacancy = createAsyncThunk(
  "careers/vacancy/publish",
  async (data: { id: string; publish: boolean }) => {
    const response = data.publish
      ? await VacancyService.unpublishVacancy(data.id)
      : await VacancyService.publishVacancy(data.id);
    return response.vacancy;
  }
);

export const getLocations = createAsyncThunk("careers/vacancy/locations", async () => {
  const response = await VacancyService.getLocations();
  return response.locations;
});

export default careersSlice.reducer;
export const { setCreated, setEdited, setDeleted, setVacancies } = careersSlice.actions;
