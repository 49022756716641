import React, { FC } from "react";
import { HtmlInput } from "../../../../Inputs/HtmlInput";

interface ServiceCreateEditDescriptionProps {
  formik: any;
}

export const ServiceCreateEditDescription: FC<ServiceCreateEditDescriptionProps> = ({ formik }) => {
  return (
    <HtmlInput
      error={formik.errors.description}
      value={formik.values.description}
      handleChange={formik.handleChange}
      label="Description"
      name="description"
    />
  );
};
