import React, { FC } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { SearchWBS } from "./SearchWBS/SearchWBS";
import { TimeReportRowValues } from "./TimeReportRowValues/TimeReportRowValues";
import DeleteIcon from "@mui/icons-material/Delete";
import { TimeReportedByProject } from "../../../../../../types//internal/timeReport/TimeReportedByProject";
import "./TimeReportRow.css";
import { RootState, useTypedSelector } from "../../../../../../stores/store";
import { WBS } from "../../../../../../types/common/wbs/WBS";
import {
  VACATION_WBS_CODE_ALTERNATIVE,
  VACATION_WBS_ID,
} from "../../../../../../constants/internal/vacations.const";

interface TimeReportRowProps {
  isVacationsRow: boolean;
  timeReportByProject: TimeReportedByProject;
  vacationDays?: Date[];
  index: number;
  error: any;
  allowedToEdit: boolean;
  deleteRow?: (index: number) => void;
  interceptorHandleChangeWbs?: (wbs: any, index: number) => void;
  interceptorHandleChange?: (value: any, name: string) => void;
}

export const TimeReportRow: FC<TimeReportRowProps> = ({
  isVacationsRow,
  timeReportByProject,
  vacationDays,
  index,
  error,
  allowedToEdit,
  deleteRow,
  interceptorHandleChangeWbs,
  interceptorHandleChange,
}) => {
  const { wbsAll } = useTypedSelector((state: RootState) => state.wbs);

  const computeProjectTotalHours = (index: number) => {
    let totalDay: number = 0;
    if (Array.isArray(timeReportByProject)) {
      timeReportByProject.timeReported.forEach((timeReportByProject) => {
        totalDay += Number.isNaN(timeReportByProject.reportedTime)
          ? 0
          : timeReportByProject.reportedTime;
      });
    }
    return totalDay;
  };

  const getVacationWbsCode = () => {
    var vacationCode = VACATION_WBS_CODE_ALTERNATIVE;
    wbsAll.map((wbs: WBS, index: number) => {
      if (wbs.id === VACATION_WBS_ID) {
        vacationCode = wbs.code;
        return;
      }
    });
    return vacationCode;
  };

  return (
    <tr key={index} className="TimeReportRow-tr">
      <>
        <td className="TimeReportRow-code">
          <div
            className={
              error
                ? error.reportedTime
                  ? error.reportedTime[index]
                    ? error.reportedTime[index].name
                      ? "TimeReportRow-search TimeReportTable-error"
                      : "TimeReportRow-search"
                    : "TimeReportRow-search"
                  : "TimeReportRow-search"
                : "TimeReportRow-search"
            }
          >
            <div className="TimeReportRow-search">
              {isVacationsRow && (
                <div className="TimeReportRow-vacationsWBSTitle">{getVacationWbsCode()}</div>
              )}
              {!isVacationsRow && (
                <SearchWBS
                  index={index}
                  handleChangeWbs={interceptorHandleChangeWbs}
                  disabled={!allowedToEdit}
                  wbs={timeReportByProject.wbs}
                />
              )}
            </div>
          </div>
        </td>
        <TimeReportRowValues
          vacationDays={vacationDays}
          singleReportedTime={timeReportByProject}
          position={index}
          handleChange={interceptorHandleChange}
          allowedToEdit={allowedToEdit}
          error={
            error
              ? error.reportedTime
                ? error.reportedTime[index]
                  ? error.reportedTime[index]
                  : null
                : null
              : null
          }
        />
        <td className="TimeReportRow-td">
          <input
            type="text"
            name="name"
            value={computeProjectTotalHours(index).valueOf()}
            disabled
            className="TimeReportRow-input"
          />
        </td>
        <td className="TimeReportRow-deleteRow">
          {allowedToEdit && deleteRow != undefined && (
            <Tooltip title="Delete Row" onClick={() => deleteRow(index)}>
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </td>
      </>
    </tr>
  );
};
